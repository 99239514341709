<script setup lang="ts">
import Label from "@/components/input/Label.vue"
import Field from "@/components/input/Field.vue"
import Form from "@/components/input/Form.vue"
import { useUserStore } from "@/stores/User"
import { ref, watch, computed } from "vue"
import { storeToRefs } from "pinia"
import { validate } from "uuid"
import API from "@/api/api"
import * as zod from "zod"
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from "@headlessui/vue"

const User = useUserStore()
const { id, circles } = storeToRefs(User)

const props = defineProps<{
  open: boolean
  type: 'media' | 'writing' | 'status' | 'collection'
  content: any
  toggleModal: () => void
}>()

const emits = defineEmits(['changed'])

const schema = zod.object({
  audience: zod.string().min(1)
})

const initialValues = {
  audience: props.type === 'status' ? props.content.audience : props.content.visibility
}
const formSlot = ref<any>(null)

const visibilityLabel = computed(() => {
  const audience = props.type === 'status' ? props.content.audience : props.content.visibility
  switch (audience) {
    case 'public':
      return 'Everyone'
    case 'followers':
      return 'Followers'
    case 'relationships':
      return 'Relationships'
    default:
      if (validate(audience)) {
        const circle = circles.value.find((c: any) => c.code === audience)
        if (circle) return `the ${circle.name} circle`
      }
      return audience
  }
})

const visibilityMismatch = computed(() => {
  if (!formSlot.value) return false
  if (!props.content) return false
  if (!props.content.collection) return false
  const collection = props.content.collection
  if (collection.visibility === 'public') return false
  const audience = formSlot.value?.tools?.getFieldValue('audience')
  return audience !== collection.visibility
})

const isChanged = computed(() => {
  if (!formSlot.value) return false
  const audience = formSlot.value?.tools?.getFieldValue('audience')
  return audience !== (props.type === 'status' ? props.content.audience : props.content.visibility)
})

const shouldWarn = computed(() => {
  if (!formSlot.value) return false
  const audience = formSlot.value?.tools?.getFieldValue('audience')
  return audience !== (props.type === 'status' ? props.content.audience : props.content.visibility) && audience !== 'public'
})

const working = ref(false)

async function changeAudience(values: any) {
  if (working.value) return
  working.value = true

  let label
  switch(props.type) {
    case 'media':
      label = 'media'
      break
    case 'writing':
      label = 'writing'
      break
    case 'status':
      label = 'status'
      break
    case 'collection':
      label = 'collections'
      break
  }

  try {
    await API().patch(`/users/${id.value}/${label}/${props.content._id}/audience`, {
      audience: values.audience
    })
    emits('changed')
  } catch (err) {
    console.error(err)
    working.value = false
  }
}

watch(() => props.open, (newVal) => {
  if (newVal) working.value = false
})
</script>

<template>
  <TransitionRoot appear :show="open" as="template">
    <Dialog as="div" @close="toggleModal()" class="relative z-[100]">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-25" />
      </TransitionChild>
      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full max-w-md transform overflow-hidden rounded-2xl bg-neutral-125 dark:bg-submit-900 p-6 text-left align-middle shadow-xl transition-all"
            >
              <DialogTitle
                as="h3"
                class="text-lg font-bold leading-6 text-black dark:text-white mb-4"
              >
                Change Audience
              </DialogTitle>
              <div class="flex flex-col mt-2 space-y-4">
                <p class="text-sm">Change the audience for this content.</p>
                <Form
                  class="space-y-3"
                  :schema="schema"
                  :initial-values="initialValues"
                  @on-submit="changeAudience"
                  v-slot="slot"
                >
                  <template v-if="formSlot = slot">
                    <Label field="audience">Audience</Label>
                    <Field name="audience" type="select" small alt>
                      <option value="public">Everyone</option>
                      <option value="followers">Followers</option>
                      <option value="relationships">Relationships</option>
                      <optgroup label="Circles" v-if="circles.length > 0">
                        <option v-for="circle in circles" :key="circle._id" :value="circle.code">{{ circle.name }}</option>
                      </optgroup>
                    </Field>
                    <p class="text-xs text-red-500" v-if="shouldWarn"><strong>WARNING:</strong> This {{ type }} is currently visible to {{ visibilityLabel }}. Any users who are not in the new audience will no longer be able to view it and any reactions or comments currently on the content that are from users who are not in the new audience will be removed.</p>
                    <p class="text-xs text-red-500" v-if="visibilityMismatch"><strong>WARNING:</strong> Changing this {{ type }}'s audience will also change the audience of the collection it's in to be visible to everyone. This will not change the audience of content already in the collection.</p>
                    <div class="flex justify-end">
                      <button
                        type="button"
                        @click="toggleModal()"
                        class="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-gray-500 dark:text-gray-400 hover:text-black hover:bg-white dark:hover:bg-submit-500 dark:hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 mr-2"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        class="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-gray-500 dark:text-gray-400 hover:text-black hover:bg-white dark:hover:bg-submit-500 dark:hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                        :disabled="working || !isChanged"
                      >
                        {{ working ? "Processing..." : "Change Audience" }}
                      </button>
                    </div>
                  </template>
                </Form>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
