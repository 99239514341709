<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount, reactive, provide, nextTick, watch } from 'vue'
import type { StyleValue } from 'vue'
import V3Item from '@/components/feed/V3Item.vue'
import LoadIndicator from '@/components/misc/LoadIndicator.vue'
import API from '@/api/api'

interface VirtualProps {
  feedContext: string
  overscan?: number
  initialEstimatedHeight?: number
}

const props = withDefaults(defineProps<VirtualProps>(), {
  feedContext: 'following',
  overscan: 5,
  initialEstimatedHeight: 80
})

provide('feedContext', props.feedContext)

// Core state
const containerRef = ref<HTMLElement | null>(null)
const feed = ref<any[]>([])
const hydrated = ref(new Set<string>())
const inProgress = ref(new Set<string>())
const measureCache = ref(new Map<string, number>())

// Pagination state
const pageInfo = ref({
  hasMore: false,
  next: undefined as string | undefined,
  previous: undefined as string | undefined,
  loading: false
})

const wrapperRef = ref<HTMLElement | null>(null)

// Viewport state
const viewportState = reactive({
  scrollTop: 0,
  viewportHeight: 0,
  totalHeight: 0,
  startIndex: 0,
  endIndex: 0,
  visibleItems: new Map<number, {
    index: number,
    top: number,
    height: number,
    id: string
  }>(),
  initialized: false
})

const initialMeasurements = ref(new Set<string>())

const containerStyle = computed<StyleValue>(() => ({
  position: 'relative',
  minHeight: `${viewportState.totalHeight}px`,
  height: `${viewportState.totalHeight}px`,
  willChange: 'transform',
  contain: 'content',
  transition: 'height 0.2s ease-out'
}))

const observerStyle = computed<StyleValue>(() => ({
  position: 'absolute',
  left: 0,
  right: 0,
  height: '1px',
  top: `${viewportState.totalHeight - 2000}px`, // Position relative to content height
  pointerEvents: 'none',
  opacity: 0
}))

const itemPositions = computed(() => {
  const positions = new Map<string, number>()
  let currentTop = 0

  // Calculate all positions in one pass, including gaps
  for (let i = 0; i < feed.value.length; i++) {
    const id = feed.value[i]?.feedItem || feed.value[i]?._id
    if (id) {
      // Add gap before each item except the first one
      if (i > 0) {
        currentTop += GAP_SIZE.value
      }
      positions.set(id, currentTop)
      currentTop += measureCache.value.get(id) || props.initialEstimatedHeight
    }
  }

  return positions
})

const GAP_SIZE = computed(() => {
  // Recreate the clamp(1rem, 1.75vw, 1.5rem) in pixels
  const min = 16 // 1rem
  const max = 24 // 1.5rem
  const preferred = window.innerWidth * 0.0175 // 1.75vw
  return Math.min(Math.max(min, preferred), max)
})

// Methods
function getItemPosition(index: number) {
  let top = 0
  const id = feed.value[index]?.feedItem || feed.value[index]?._id

  for (let i = 0; i < index; i++) {
    const itemId = feed.value[i]?.feedItem || feed.value[i]?._id
    top += measureCache.value.get(itemId) || props.initialEstimatedHeight
  }

  return {
    top,
    height: measureCache.value.get(id) || props.initialEstimatedHeight
  }
}

function updateVisibleRange() {
  if (!wrapperRef.value || feed.value.length === 0) return

  const scrollTop = window.scrollY
  const viewportHeight = window.innerHeight

  // Find visible range using itemPositions
  const positions = Array.from(itemPositions.value.entries())

  // Binary search for start index
  let start = 0
  let end = positions.length - 1

  while (start <= end) {
    const mid = Math.floor((start + end) / 2)
    const [, top] = positions[mid]

    if (top < scrollTop - (viewportHeight * 0.5)) {
      start = mid + 1
    } else {
      end = mid - 1
    }
  }

  const startIndex = Math.max(0, start - props.overscan)

  // Find end index based on viewport height
  let endIndex = startIndex
  let lastTop = positions[startIndex]?.[1] || 0

  while (
    endIndex < feed.value.length &&
    lastTop < scrollTop + (viewportHeight * 1.5)
  ) {
    endIndex++
    lastTop = positions[endIndex]?.[1] || lastTop + props.initialEstimatedHeight
  }

  endIndex = Math.min(feed.value.length - 1, endIndex + props.overscan)

  // Update viewport state
  viewportState.scrollTop = scrollTop
  viewportState.viewportHeight = viewportHeight
  viewportState.startIndex = startIndex
  viewportState.endIndex = endIndex

  // Update visible items
  const newVisibleItems = new Map()
  for (let i = startIndex; i <= endIndex; i++) {
    if (!feed.value[i]) continue

    const id = feed.value[i]?.feedItem || feed.value[i]?._id
    if (!id) continue

    const top = itemPositions.value.get(id) || 0
    const height = measureCache.value.get(id) || props.initialEstimatedHeight

    newVisibleItems.set(i, {
      index: i,
      top,
      height,
      id
    })
  }

  viewportState.visibleItems = newVisibleItems
  requestHydration()
}

let updateTimeout: NodeJS.Timeout | null = null

function onItemMeasured(id: string, height: number) {
  const currentHeight = measureCache.value.get(id)
  if (currentHeight === height) return

  // Find the index of the measured item
  const index = feed.value.findIndex(item =>
    (item.feedItem || item._id) === id
  )

  // Calculate the height difference
  const heightDiff = height - (currentHeight || props.initialEstimatedHeight)

  // Update the cache
  measureCache.value.set(id, height)

  // Track initial measurements
  if (!initialMeasurements.value.has(id)) {
    initialMeasurements.value.add(id)
  }

  // Debounce updates to prevent too frequent recalculations
  if (updateTimeout) clearTimeout(updateTimeout)
  updateTimeout = setTimeout(() => {
    // Only update total height and visible range if:
    // 1. Item is in or above the viewport
    // 2. Height difference is significant
    if (index <= viewportState.endIndex && Math.abs(heightDiff) > 5) {
      updateTotalHeight()
      updateVisibleRange()
    }
  }, 16)
}

function updateTotalHeight() {
  let height = 0
  for (let i = 0; i < feed.value.length; i++) {
    // Add gap before each item except the first one
    if (i > 0) {
      height += GAP_SIZE.value
    }
    const id = feed.value[i]?.feedItem || feed.value[i]?._id
    height += measureCache.value.get(id) || props.initialEstimatedHeight
  }
  viewportState.totalHeight = height
}

// Scroll handling with RAF for performance
let scrollRAF: number | null = null

function handleScroll() {
  if (scrollRAF) return

  scrollRAF = requestAnimationFrame(() => {
    updateVisibleRange()
    scrollRAF = null
  })
}

// Hydration handling
function requestHydration() {
  const visibleIds = new Set(
    Array.from(viewportState.visibleItems.values())
      .map(item => item.id)
  )

  const itemsToHydrate: string[] = []

  for (const id of visibleIds) {
    if (!hydrated.value.has(id) && !inProgress.value.has(id)) {
      itemsToHydrate.push(id)
    }
  }

  if (itemsToHydrate.length > 0) {
    hydrateItems(itemsToHydrate)
  }
}

async function hydrateItems(itemIds: string[]) {
  if (itemIds.length === 0) return

  try {
    // Mark items as in progress to prevent duplicate hydration
    itemIds.forEach(id => inProgress.value.add(id))

    const activityIds = itemIds
      .map(id => {
        const item = feed.value.find(item =>
          (item.feedItem || item._id) === id
        )
        return item?.activity
      })
      .filter(Boolean)

    if (activityIds.length === 0) {
      itemIds.forEach(id => inProgress.value.delete(id))
      return
    }

    const { data } = await API().post(`/hydrate/${props.feedContext}`, {
      items: activityIds
    })

    // First identify all items that need to be removed
    const indicesToRemove = new Set<number>()
    data.forEach((item: any) => {
      if (!item || !item._id) return
      if (item.remove === true || item.error === true) {
        const index = feed.value.findIndex(skeleton => skeleton.activity === item._id)
        if (index !== -1) {
          indicesToRemove.add(index)
        }
      }
    })

    // If we have items to remove, remove them all at once
    if (indicesToRemove.size > 0) {
      // Clean up associated data first
      Array.from(indicesToRemove).forEach(index => {
        const item = feed.value[index]
        const itemId = item.feedItem || item._id
        hydrated.value.delete(itemId)
        inProgress.value.delete(itemId)
        measureCache.value.delete(itemId)
        initialMeasurements.value.delete(itemId)
      })

      // Remove items from highest index to lowest to avoid shifting problems
      const sortedIndices = Array.from(indicesToRemove).sort((a, b) => b - a)
      sortedIndices.forEach(index => {
        feed.value.splice(index, 1)
      })

      // Force a complete visible range update
      await nextTick()
      updateTotalHeight()
      updateVisibleRange()

      // Early return if all items were removed
      if (sortedIndices.length === data.length) {
        return
      }
    }

    // Now handle the updates for remaining items
    data.forEach((item: any) => {
      if (!item || !item._id || item.remove || item.error) return

      const index = feed.value.findIndex(skeleton => skeleton.activity === item._id)
      if (index === -1) return

      const itemId = feed.value[index].feedItem || feed.value[index]._id

      feed.value[index] = {
        ...item,
        feedItem: itemId,
        hydrated: true
      }
      hydrated.value.add(itemId)
      inProgress.value.delete(itemId)
    })

    await nextTick()
    updateTotalHeight()
    updateVisibleRange()

  } catch (error) {
    console.error('Error hydrating items:', error)
    itemIds.forEach(id => inProgress.value.delete(id))
  }
}

const observerRef = ref<HTMLElement | null>(null)

async function fetchFeed() {
  try {
    if (pageInfo.value.loading) return
    pageInfo.value.loading = true

    let path = `/skeleton/${props.feedContext}`
    const queryParams: Record<string, string> = {}

    if (pageInfo.value.hasMore && pageInfo.value.next) {
      queryParams.after = pageInfo.value.next
    }

    if (Object.keys(queryParams).length > 0) {
      path += `?${new URLSearchParams(queryParams)}`
    }

    const response = await API().get(path)

    pageInfo.value.hasMore = response.data.hasMore
    pageInfo.value.next = response.data.cursors.next
    pageInfo.value.previous = response.data.cursors.previous

    const newItems = response.data.items
    feed.value.push(...newItems)

    // Update measurements and visible range
    await nextTick()
    updateTotalHeight()
    updateVisibleRange()

    if (observerRef.value) {
      observer.disconnect()
      observer.observe(observerRef.value)
    }
  } catch (error) {
    console.error('Error fetching feed:', error)
  } finally {
    pageInfo.value.loading = false
  }
}

// Intersection Observer for infinite loading
const observer = new IntersectionObserver((entries) => {
  entries.forEach(entry => {
    if (entry.isIntersecting && pageInfo.value.hasMore && !pageInfo.value.loading) {
      fetchFeed()
    }
  })
}, {
  rootMargin: '1000px',
  threshold: 0.1
})

// Lifecycle
onMounted(() => {
  window.addEventListener('scroll', handleScroll, { passive: true })
  window.addEventListener('resize', updateVisibleRange)

  fetchFeed()
})

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll)
  window.removeEventListener('resize', updateVisibleRange)
  observer.disconnect()

  if (updateTimeout) {
    clearTimeout(updateTimeout)
  }

  if (scrollRAF) {
    cancelAnimationFrame(scrollRAF)
  }
})

watch(() => viewportState.totalHeight, () => {
  if (observerRef.value && pageInfo.value.hasMore) {
    observer.disconnect()
    observer.observe(observerRef.value)
  }
})

// Provide methods for child components
provide('hideItem', async (itemId: string) => {
  if (props.feedContext !== 'everyone') return
  try {
    await API().post(`/feed/everyone/hide`, { item: itemId })
    const index = feed.value.findIndex((item: any) => item.feedItem === itemId)
    if (index !== -1) {
      feed.value.splice(index, 1)
      hydrated.value.delete(itemId)
      inProgress.value.delete(itemId)
      measureCache.value.delete(itemId)
      updateTotalHeight()
      updateVisibleRange()
    }
  } catch (err) {
    console.error(err)
  }
})

provide('muteUser', async (userId: string) => {
  if (props.feedContext !== 'everyone') return
  try {
    await API().post(`/feed/everyone/mute`, { mute: userId })
    const itemsToRemove = feed.value.filter((item: any) => item.author._id === userId)
    itemsToRemove.forEach((item: any) => {
      const itemId = item.feedItem || item._id
      hydrated.value.delete(itemId)
      inProgress.value.delete(itemId)
      measureCache.value.delete(itemId)
    })
    feed.value = feed.value.filter((item: any) => item.author._id !== userId)
    updateTotalHeight()
    updateVisibleRange()
  } catch (err) {
    console.error(err)
  }
})

provide('deleteStatusUpdate', async (status: string, feedItem: string) => {
  try {
    await API().delete(`/status/${status}`)
    const index = feed.value.findIndex((item: any) => item.feedItem === feedItem)
    if (index !== -1) {
      feed.value.splice(index, 1)
      hydrated.value.delete(feedItem)
      inProgress.value.delete(feedItem)
      measureCache.value.delete(feedItem)
      updateTotalHeight()
      updateVisibleRange()
    }
  } catch (err) {
    console.error(err)
    alert("There was an error deleting this status update.")
  }
})

provide('removeItem', async (itemId: string) => {
  if (props.feedContext !== 'everyone') return
  try {
    await API().delete(`/feed/everyone/remove`, {
      data: { item: itemId }
    })
    const index = feed.value.findIndex((item: any) => item.feedItem === itemId)
    if (index !== -1) {
      feed.value.splice(index, 1)
      hydrated.value.delete(itemId)
      inProgress.value.delete(itemId)
      measureCache.value.delete(itemId)
      updateTotalHeight()
      updateVisibleRange()
    }
  } catch (err) {
    console.error(err)
  }
})
</script>

<template>
  <section ref="wrapperRef" class="feed-relative">
    <div
      ref="containerRef"
      :style="[
        containerStyle,
        { paddingTop: `${GAP_SIZE}px` }
      ]"
    >
      <div
        v-for="item in [...viewportState.visibleItems.values()]"
        :key="item.id"
        class="virtual-item"
        :style="{
          position: 'absolute',
          top: `${itemPositions.get(item.id)}px`, // Use top instead of transform
          left: 0,
          right: 0,
          height: `${item.height}px`,
          contain: 'size'
        }"
      >
        <V3Item
          v-if="feed[item.index]"
          :item="feed[item.index]"
          :hydrated="hydrated.has(item.id)"
          @measured="(height) => onItemMeasured(item.id, height)"
        />
      </div>

      <div
        v-if="pageInfo.hasMore && !pageInfo.loading"
        ref="observerRef"
        :style="observerStyle"
      />
    </div>

    <div
      class="flex justify-center py-4 sticky bottom-0 bg-white dark:bg-submit-950"
      v-if="pageInfo.loading"
    >
      <LoadIndicator />
    </div>
  </section>
</template>

<style scoped>
.feed-relative {
  contain: content;
  min-height: 100vh;
  position: relative;
}

.virtual-item {
  position: absolute;
  overflow: visible;
}
</style>
