<script setup lang="ts">
import LoadIndicator from "@/components/misc/LoadIndicator.vue"
import Moderation from "@/components/actions/Moderation.vue"
import SubmitHeader from "@/components/core/Header.vue"
import SubmitFooter from "@/components/core/Footer.vue"
import Report from "@/components/actions/Report.vue"
import { usePlatformStore } from "@/stores/Platform"
import Search from "@/components/actions/Search.vue"
import Block from "@/components/actions/Block.vue"
import Update from "@/components/core/Update.vue"
import Toasts from "@/components/core/Toasts.vue"
import { useUserStore } from "@/stores/User"
import { useRoute } from "vue-router"
import { storeToRefs } from 'pinia'
import { computed } from "vue"

const route = useRoute()
const Platform = usePlatformStore()
const { loading } = storeToRefs(Platform)
const { roles } = storeToRefs(useUserStore())

const shouldShowHeader = computed(() => {
  return !["/signin", "/signup", "/reset", "/signout", "/verify", "/signup/early", "/test"].includes(route.path)
})

const shouldShowFooter = computed(() => {
  return !["/signin", "/signup", "/reset", "/signout", "/verify", "/signup/early", "/test"].includes(route.path)
})

const isAdmin = computed(() => roles.value.includes("admin"))
</script>

<template>
  <div class="Submit">
    <SubmitHeader v-if="shouldShowHeader" />
    <div class="row-start-2 row-end-2 flex w-full h-full justify-center items-center dark:bg-submit-950 bg-neutral-125 z-50" v-if="loading">
      <LoadIndicator />
    </div>
    <slot></slot>
    <Moderation v-if="isAdmin && shouldShowHeader" />
    <SubmitFooter v-if="shouldShowFooter" />
    <Search v-if="shouldShowHeader" />
    <Toasts v-if="shouldShowHeader" />
    <Report v-if="shouldShowHeader" />
    <Block v-if="shouldShowHeader" />
    <Update v-if="shouldShowHeader" />
  </div>
</template>
