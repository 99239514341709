<script setup lang="ts">
import Checkbox from "@/components/input/Checkbox.vue"
import { inject, ref, computed, onMounted, type Ref } from "vue"
import { getSignals } from "@/composables/Sessions"
import Field from "@/components/input/Field.vue"
import Label from "@/components/input/Label.vue"
import Form from "@/components/input/Form.vue"
import NumberFlow from '@number-flow/vue'
import API from "@/api/api"
import * as zod from "zod"

const verifyValues = {
  email: "",
  age: false,
  terms: false
}

const verifySchema = zod.object({
  email: zod.string()
    .email({ message: "Must be a valid email address." })
    .min(1, { message: "This is required." }),
  age: zod.boolean()
    .refine(value => value === true, { message: "You must be at least 18 years of age." }),
  terms: zod.boolean()
    .refine(value => value === true, { message: "You must agree to the Terms of Service." })
})

const sessionId = inject('sessionId') as Ref<string | undefined>

const stage = ref<string>('email')

const working = ref<boolean>(false)
const debug = ref<boolean>(false)

const email = ref<string | null>(null)

const error = ref(false)
const errorMessage = ref("There was an error performing this action, please try again.")
const errorCode = ref("NEC")

const resendTime = ref(30)
const sendCounter = ref(0)

const statusCode = ref<number | null>(null)

const formStartTime = ref<number | null>(null)
const lastKeyPressTime = ref<number | null>(null)
const keyPressIntervals = ref<number[]>([])
const mouseMovements = ref<{ x: number, y: number, timestamp: number }[]>([])

const errorMsgDisplay = computed(() => {
  if (error.value && errorMessage.value && errorMessage.value === 'Email is throwaway') {
    return 'Submit does not support throwaway email addresses. Please use a valid email address.'
  }

  return errorMessage.value
})

const resetError = () => {
  error.value = false
  errorMessage.value = "There was an error performing this action, please try again."
  errorCode.value = "NEC"
}

onMounted(() => {
  trackFormBehavior('form_start')
})

const trackFormBehavior = (event: string, data?: any) => {
  const timestamp = Date.now()

  switch (event) {
    case 'form_start':
      formStartTime.value = timestamp
      break
    case 'key_press':
      if (lastKeyPressTime.value) {
        keyPressIntervals.value.push(timestamp - lastKeyPressTime.value)
      }
      lastKeyPressTime.value = timestamp
      break
    case 'mouse_move':
      mouseMovements.value.push({
        x: data.x,
        y: data.y,
        timestamp
      })
      break
  }
}

async function startVerification(values: any) {
  if (working.value) return
  working.value = true
  if (error.value) resetError()

  try {
    const response = await API().post(`/validation/rec`, {
      email: values.email,
      session: sessionId.value,
      ua: navigator.userAgent,
      signals: getSignals(),
      b: {
        fct: formStartTime.value ? Date.now() - formStartTime.value : null,
        kpi: keyPressIntervals.value,
        mmt: mouseMovements.value,
        akpi: keyPressIntervals.value.length > 0
          ? keyPressIntervals.value.reduce((a, b) => a + b) / keyPressIntervals.value.length
          : null
      }
    })

    statusCode.value = response.status

    sendCounter.value++
    if (statusCode.value === 201) {
      const { delay, count } = response.data.data
      resendTime.value = delay || 30
      sendCounter.value = count || 1

      // countdown from the resendTime to 0
      setInterval(() => {
        if (resendTime.value > 0) {
          resendTime.value--
        }
      }, 1000)

      email.value = values.email.toLowerCase()
    }
    stage.value = 'verify'
    working.value = false
  } catch (err: any) {
    if (err.response && err.response.status === 400) {
      const response = err.response
      if (response.data && response.data.message && response.data.code) {
        errorMessage.value = response.data.message
        errorCode.value = response.data.code
      }
    } else {
      errorMessage.value = "There was an error performing this action, please try again."
      errorCode.value = "NEC"
      console.error(err)
    }
    error.value = true
    working.value = false
  }
}

async function resendVerification() {
  if (working.value) return
  working.value = true
  if (error.value) resetError()

  if (!email.value) return
  if (sendCounter.value >= 4) {
    errorMessage.value = "You have reached the maximum number of verification emails. Please contact support if you don't receive an email.",
    errorCode.value = "VFY_MAX_001"
    error.value = true
    working.value = false
    return
  }
  if (resendTime.value > 0) return

  try {
    const response = await API().post('/verify/resend', {
      email: email.value
    })

    sendCounter.value++
    const { delay } = response.data.data
    resendTime.value = delay
  } catch (err: any) {
    if (err.response && err.response.status === 400) {
      const response = err.response
      if (response.data && response.data.message && response.data.code) {
        errorMessage.value = response.data.message
        errorCode.value = response.data.code
      }
    } else {
      errorMessage.value = "There was an error performing this action, please try again."
      errorCode.value = "NEC"
      console.error(err)
    }
    error.value = true
    working.value = false
  } finally {
    working.value = false
  }
}
</script>

<template>
  <section v-if="stage === 'email'">
    <h1 class="font-display text-2xl dark:text-white font-bold mb-6">{{ $t("signup") }}</h1>
    <Form
      :schema="verifySchema"
      :initial-values="verifyValues"
      @on-submit="startVerification"
      @focus="trackFormBehavior('form_focus', { startTime: Date.now() })"
      @mousemove="trackFormBehavior('mouse_move', { x: $event.clientX, y: $event.clientY })"
      v-slot="{ fields, errors }"
      class="space-y-3"
    >
      <div>
        <Label field="email">{{ $t("email") }}</Label>
        <Field
          type="input"
          subtype="email"
          name="email"
          placeholder="name@domain.com"
          @keypress="trackFormBehavior('key_press')"
        />
      </div>
      <div>
        <Checkbox name="age">
          I confirm I am at least 18 years of age.
        </Checkbox>
      </div>
      <div>
        <Checkbox name="terms">
          I agree to the <a class="underline hover:decoration-2 dark:hover:decoration-gold-500" target="_blank" href="https://docs.submit.gg/terms">Terms of Service</a>.
        </Checkbox>
      </div>
      <div>
        <button class="mt-6 font-display font-bold rounded-md bg-gold-700/90 hover:bg-gold-700 dark:bg-gold-500/90 hover:dark:bg-gold-500 text-black py-4 w-full capitalize" type="submit" :disabled="working">
          {{ working ? $t("processing") : $t("getstarted") }}
        </button>
      </div>
      <div class="mt-4 bg-red-800 p-2 rounded-md text-xs font-bold text-red-200" v-if="error">
        <p>{{ errorMsgDisplay }} <small>({{ errorCode }})</small></p>
      </div>
      <div v-if="debug">
        <pre>
{{ fields }}
        </pre>
        <pre>
{{ errors }}
        </pre>
      </div>
    </Form>
  </section>
  <section v-if="stage === 'verify'">
    <h1 class="font-display text-2xl dark:text-white font-bold mb-6">{{ $t("checkinbox") }}</h1>
    <p class="text-sm mb-8 leading-6">Verify your email address by clicking the link in the confirmation email we've just sent you (it expires in 24 hours!).</p>
    <button
      v-if="statusCode === 201 && sendCounter < 4"
      @click="resendVerification"
      class="text-sm"
      :class="[
        resendTime > 0 ? 'dark:text-gray-500 text-neutral-400 cursor-not-allowed' : ''
      ]"
      :disabled="resendTime > 0"
    >
      Re-send verification email<span v-if="resendTime > 0"> in <NumberFlow :value="resendTime" /> seconds</span>
    </button>
    <div v-if="statusCode === 201 && sendCounter >= 4" class="mt-4 text-sm">
      Maximum number of verification emails reached. Please contact support if you don't receive an email.
    </div>
    <div class="mt-4 bg-red-800 p-2 rounded-md text-xs font-bold text-red-200" v-if="error">
      <p>{{ errorMsgDisplay }} <small>({{ errorCode }})</small></p>
    </div>
  </section>
</template>
