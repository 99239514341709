<script setup lang="ts">
import { reactive, onMounted, inject, computed } from "vue"
import Label from "@/components/input/Label.vue"

interface Props {
  initialData: any
  count: number
}

const props = defineProps<Props>()
const emits = defineEmits(['focus', 'blur'])
const addDataToArray = inject('addDataToArray') as (arrayObject: any, id: string) => void
const removeObjectFromArray = inject('removeObjectFromArray') as (id: string) => void
const countDisplay = computed(() => props.count + 1)

const value = reactive({
  id: props.initialData.id,
  name: props.initialData.name ? props.initialData.name : '',
  description: props.initialData.description ? props.initialData.description : '',
  slug: props.initialData.slug ? props.initialData.slug : '',
  icon: props.initialData.icon ? props.initialData.icon : '',
  isDefault: props.initialData.isDefault ? props.initialData.isDefault : false,
  canPost: props.initialData.canPost ? props.initialData.canPost : [],
  postQueue: props.initialData.postQueue ? props.initialData.postQueue : [],
})

onMounted(() => {
  addDataToArray(value, props.initialData.id)
})

function handleFocus() {
  emits('focus')
}

function handleBlur() {
  emits('blur')
}
</script>

<template>
  <div class="bg-white dark:bg-submit-925 rounded-md p-4 space-y-3">
    <h3 class="font-bold">Board {{ countDisplay }}</h3>
    <div class="flex items-center space-x-2">
      <input @focus="handleFocus" @blur="handleBlur" :name="`isDefault_${value.id}`" class="text-sm rounded-md bg-neutral-125 dark:bg-submit-950 outline-none border-0 focus:ring-0 focus:border-0 focus:outline-indigo-325/30 placeholder:text-indigo-250/50" v-model="value.isDefault" type="checkbox" />
      <Label :field="`isDefault_${value.id}`">Default Board</Label>
    </div>
    <div>
      <Label :field="`name_${value.id}`">Board Name</Label>
      <input @focus="handleFocus" @blur="handleBlur" :name="`name_${value.id}`" class="w-full text-sm rounded-md bg-neutral-125 dark:bg-submit-950 outline-none border-0 focus:ring-0 focus:border-0 focus:outline-indigo-325/30 placeholder:text-indigo-250/50" v-model="value.name" type="text" placeholder="Board Name" />
    </div>
    <div>
      <Label :field="`icon_${value.id}`">Board Icon</Label>
      <select @focus="handleFocus" @blur="handleBlur" :name="`icon_${value.id}`" class="w-full text-sm rounded-md bg-neutral-125 dark:bg-submit-950 outline-none border-0 focus:ring-0 focus:border-0 focus:outline-indigo-325/30 placeholder:text-indigo-250/50" v-model="value.icon">
        <option value="default">Default</option>
        <option value="help">Help</option>
        <option value="bug">Bug</option>
        <option value="code">Code</option>
      </select>
    </div>
    <div>
      <Label :field="`description_${value.id}`">Board Description</Label>
      <textarea @focus="handleFocus" @blur="handleBlur" :name="`description_${value.id}`" class="w-full text-sm rounded-md bg-neutral-125 dark:bg-submit-950 outline-none border-0 focus:ring-0 focus:border-0 focus:outline-indigo-325/30 placeholder:text-indigo-250/50" v-model="value.description" type="text" placeholder="Board Description" />
    </div>
    <div>
      <Label :field="`slug_${value.id}`">Board Slug</Label>
      <input @focus="handleFocus" @blur="handleBlur" :name="`slug_${value.id}`" class="w-full text-sm rounded-md bg-neutral-125 dark:bg-submit-950 outline-none border-0 focus:ring-0 focus:border-0 focus:outline-indigo-325/30 placeholder:text-indigo-250/50" v-model="value.slug" type="text" placeholder="Board Slug" />
    </div>
    <div class="grid grid-cols-2 gap-2">
      <fieldset>
        <Label :field="`canPost_${value.id}`">Who Can Post</Label>
        <div class="space-y-2">
          <div class="flex items-center space-x-2">
            <input
              @focus="handleFocus"
              @blur="handleBlur"
              :id="`canPost_owners_${value.id}`"
              :name="`canPost_${value.id}`"
              type="checkbox"
              value="owners"
              v-model="value.canPost"
              class="text-sm rounded-md bg-neutral-125 dark:bg-submit-950 outline-none border-0 focus:ring-0 focus:border-0 focus:outline-indigo-325/30"
            />
            <label :for="`canPost_owners_${value.id}`" class="text-sm">Owners</label>
          </div>
          <div class="flex items-center space-x-2">
            <input
              @focus="handleFocus"
              @blur="handleBlur"
              :id="`canPost_moderators_${value.id}`"
              :name="`canPost_${value.id}`"
              type="checkbox"
              value="moderators"
              v-model="value.canPost"
              class="text-sm rounded-md bg-neutral-125 dark:bg-submit-950 outline-none border-0 focus:ring-0 focus:border-0 focus:outline-indigo-325/30"
            />
            <label :for="`canPost_moderators_${value.id}`" class="text-sm">Moderators</label>
          </div>
          <div class="flex items-center space-x-2">
            <input
              @focus="handleFocus"
              @blur="handleBlur"
              :id="`canPost_members_${value.id}`"
              :name="`canPost_${value.id}`"
              type="checkbox"
              value="members"
              v-model="value.canPost"
              class="text-sm rounded-md bg-neutral-125 dark:bg-submit-950 outline-none border-0 focus:ring-0 focus:border-0 focus:outline-indigo-325/30"
            />
            <label :for="`canPost_members_${value.id}`" class="text-sm">Members</label>
          </div>
        </div>
      </fieldset>
      <fieldset>
        <Label :field="`postQueue_${value.id}`">Post Queue</Label>
        <div class="space-y-2">
          <div class="flex items-center space-x-2">
            <input
              @focus="handleFocus"
              @blur="handleBlur"
              :id="`postQueue_owners_${value.id}`"
              :name="`postQueue_${value.id}`"
              type="checkbox"
              value="owners"
              v-model="value.postQueue"
              class="text-sm rounded-md bg-neutral-125 dark:bg-submit-950 outline-none border-0 focus:ring-0 focus:border-0 focus:outline-indigo-325/30"
            />
            <label :for="`postQueue_owners_${value.id}`" class="text-sm">Owners</label>
          </div>
          <div class="flex items-center space-x-2">
            <input
              @focus="handleFocus"
              @blur="handleBlur"
              :id="`postQueue_moderators_${value.id}`"
              :name="`postQueue_${value.id}`"
              type="checkbox"
              value="moderators"
              v-model="value.postQueue"
              class="text-sm rounded-md bg-neutral-125 dark:bg-submit-950 outline-none border-0 focus:ring-0 focus:border-0 focus:outline-indigo-325/30"
            />
            <label :for="`postQueue_moderators_${value.id}`" class="text-sm">Moderators</label>
          </div>
          <div class="flex items-center space-x-2">
            <input
              @focus="handleFocus"
              @blur="handleBlur"
              :id="`postQueue_members_${value.id}`"
              :name="`postQueue_${value.id}`"
              type="checkbox"
              value="members"
              v-model="value.postQueue"
              class="text-sm rounded-md bg-neutral-125 dark:bg-submit-950 outline-none border-0 focus:ring-0 focus:border-0 focus:outline-indigo-325/30"
            />
            <label :for="`postQueue_members_${value.id}`" class="text-sm">Members</label>
          </div>
        </div>
      </fieldset>
    </div>
    <button class="text-sm font-bold text-gray-400 dark:text-gray-500 hover:dark:text-gray-300 hover:text-black" @click="removeObjectFromArray(value.id)" type="button">Remove Board</button>
  </div>
</template>
