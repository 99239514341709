<script setup lang="ts">
import BackerBadge from "@/components/core/BackerBadge.vue"
import VerifyBadge from "@/components/core/VerifyBadge.vue"
import SubmitBadge from "@/components/core/SubmitBadge.vue"
import { usePlatformStore } from "@/stores/Platform"
import { useUserStore } from "@/stores/User"
import { ref, inject, computed, type Ref } from "vue"
import D from "@/composables/TimeDisplay"
import { storeToRefs } from "pinia"
import {
  Menu,
  MenuButton,
  MenuItems,
  MenuItem,
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from "@headlessui/vue"

interface Props {
  item: any
  edit?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  edit: false
})

const Platform = usePlatformStore()
const User = useUserStore()

const { isAdmin, id } = storeToRefs(User)

const feedContext = inject('feedContext') as Ref<string>
const hideItem = inject('hideItem') as (itemId: string) => Promise<void>
const muteUser = inject('muteUser') as (userId: string) => Promise<void>
const deleteStatusUpdate = inject('deleteStatusUpdate') as (status: string, feedItem: string) => Promise<void>
const removeItem = inject('removeItem') as (itemId: string) => Promise<void>

const working = ref(false)
const confirmMuteModal = ref(false)
const confirmDeleteModal = ref(false)

function toggleMuteModal() {
  confirmMuteModal.value = !confirmMuteModal.value
}

function toggleDeleteModal() {
  confirmDeleteModal.value = !confirmDeleteModal.value
}

function openReportDialog() {
  const { content, activityType, activity, author } = props.item

  const getContentId = () => {
    if (activityType === "media") {
      return props.item.content.length === 1
        ? props.item.content[0]._id
        : activity
    }
    return content._id
  }

  const getContentType = () => {
    if (activityType === "media" && props.item.content.length > 1) {
      return "activity"
    }
    return activityType
  }

  Platform.toggleReportOpen()
  Platform.setReportData({
    userId: author._id,
    contentId: getContentId(),
    contentType: getContentType()
  })
}

const sameActionAuthor = inject('sameActionAuthor') as Ref<boolean>

const followType = computed(() => {
  let hasOrganization = false
  let hasUser = false

  if (props.item.activityType !== 'action') return 'none'

  for (const action of props.item.actions) {
    if (action.actionTakenOnType === 'organization') {
      hasOrganization = true
    } else if (action.actionTakenOnType === 'user') {
      hasUser = true
    }

    // If both types are found, return 'mixed' immediately
    if (hasOrganization && hasUser) {
      return 'mixed'
    }
  }

  if (hasOrganization) return 'organization'
  if (hasUser) return 'user'
  return 'none'
})

const actionString = computed(() => {
  if (props.item.activityType !== 'action') return ''
  if (!props.item?.activitySubType) return ''

  let typeSingle = ""
  let typeMultiple = ""
  let contentType = ""
  let string = ""

  const subType = props.item.activitySubType
  const actionsLength = props.item.actions?.length || 0

  switch (subType) {
    case "use_starterpack":
      typeSingle = "used a"
      typeMultiple = "used"
      break
    case "reaction":
      typeSingle = props.item.contentType === 'event' || props.item.contentType === 'announcement' ? "reacted to an" : "reacted to a"
      typeMultiple = "reacted to"
      break
    case "comment":
      typeSingle = props.item.contentType === 'event' || props.item.contentType === 'announcement' ? "commented on an" : "commented on a"
      typeMultiple = "commented on"
      break
    case "follow":
      if (followType.value === 'user') {
        typeSingle = "followed a"
        typeMultiple = "followed"
        break
      }
      if (followType.value === 'organization') {
        typeSingle = "followed an"
        typeMultiple = "followed"
        break
      }
      typeSingle = "followed a"
      typeMultiple = "followed"
      break
    case "rsvp":
      typeSingle = "RSVP'd to an"
      typeMultiple = "RSVP'd to"
      break
    case "considering":
      typeSingle = "is considering an"
      typeMultiple = "is considering"
      break
  }

  if (subType === 'follow') {
    if (followType.value === 'user') {
      props.item.actions.length === 1 ?
      string = ` ${ typeSingle } human` :
      string = ` ${ typeMultiple } ${ props.item.actions.length } humans`
    }
    if (followType.value === 'organization') {
      props.item.actions.length === 1 ?
      string = ` ${ typeSingle } organization` :
      string = ` ${ typeMultiple } ${ props.item.actions.length } organizations`
    }
    if (followType.value === 'mixed') {
      props.item.actions.length === 1 ?
      string = ` ${ typeSingle } human or organization` :
      string = ` ${ typeMultiple } ${ props.item.actions.length } humans and organizations`
    }
  } else {
    switch (props.item.contentType) {
      case "writing":
        contentType = "writing"
        break
      case "media":
        contentType = "photo"
        break
      case "status":
        contentType = "status update"
        break
      case "post":
        contentType = "group post"
        break
      case "announcement":
        contentType = "announcement"
        break
      case "event":
        contentType = "event"
        break
      case "starterpack":
        contentType = "starter pack"
        break
    }

    actionsLength === 1 ?
      string = ` ${ typeSingle } ${ contentType }` :
      string = ` ${ typeMultiple } ${ actionsLength } ${ contentType }s`
  }

  return string
})

const itemLink = computed(() => {
  if (!props.item?.activityType) return ''

  const type = props.item.activityType
  const username = props.item.author?.username

  if (!username) return ''

  if (type === 'media') {
    return `/${username}/media/${props.item.activity}`
  }

  if (type === 'writing') {
    return `/${username}/writing/${props.item.content?.slug}`
  }

  if (type === 'status') {
    return `/${username}/status/${props.item.content?._id}`
  }

  return ''
})
</script>

<template>
  <template as="template" v-if="((item.activityType !== 'action' && item.activityType !== 'announcement') || (item.activityType === 'action' && item.version === 1) && !item.system)">
    <header class="inline-wrap">
      <div class="flex items-center text-[15px] sm:text-base gap-x-1.5">
        <router-link :to="`/${item.author.username}`" class="truncate font-semibold">
          {{ item.author.username }}
        </router-link>
        <VerifyBadge v-if="item.author.verified" />
        <BackerBadge v-if="item.author.backer" :color="item.author.backerBadge" />
        <SubmitBadge v-if="(item.author.staff || item.author.volunteer)" />
      </div>
      <div class="flex relative items-center gap-x-4 dark:text-gray-400">
        <time class="text-sm" :title="D(item.createdAt).format('LLLL')">{{ D(item.createdAt).locale('submit').fromNow() }}</time>
        <Menu as="template" v-if="item.activityType !== 'action'">
          <MenuButton>
            <svg class="flex-none h-4 dark:fill-gray-400" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
              <title>Menu</title>
              <path d="M432 256a48 48 0 1 1 -96 0 48 48 0 1 1 96 0zm-160 0a48 48 0 1 1 -96 0 48 48 0 1 1 96 0zM64 304a48 48 0 1 1 0-96 48 48 0 1 1 0 96z"/>
            </svg>
          </MenuButton>
          <transition
            enter-active-class="transition duration-100 ease-out"
            enter-from-class="transform scale-95 opacity-0"
            enter-to-class="transform scale-100 opacity-100"
            leave-active-class="transition duration-75 ease-in"
            leave-from-class="transform scale-100 opacity-100"
            leave-to-class="transform scale-95 opacity-0"
          >
            <MenuItems class="origin-top-right divide-y divide-indigo-350 absolute top-6 -right-1 w-36 rounded-md shadow-lg bg-neutral-125 dark:bg-submit-950 ring-1 ring-transparent ring-opacity-5 focus:outline-none z-50">
              <div class="p-1">
                <MenuItem v-slot="{ active }" v-if="!item.self && feedContext === 'everyone'">
                  <button
                    type="button"
                    @click="hideItem(item._id)"
                    :class="[
                      active ? 'bg-yellow-400/10' : '',
                      'flex items-center rounded-md w-full px-4 py-2 text-sm text-neutral-500 dark:text-neutral-300'
                    ]"
                  >
                  <svg class="w-3 mr-3 dark:fill-gray-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zM223.1 149.5C248.6 126.2 282.7 112 320 112c79.5 0 144 64.5 144 144c0 24.9-6.3 48.3-17.4 68.7L408 294.5c8.4-19.3 10.6-41.4 4.8-63.3c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3c0 10.2-2.4 19.8-6.6 28.3l-90.3-70.8zM373 389.9c-16.4 6.5-34.3 10.1-53 10.1c-79.5 0-144-64.5-144-144c0-6.9 .5-13.6 1.4-20.2L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5L373 389.9z"/></svg>
                    Hide
                  </button>
                </MenuItem>
                <MenuItem v-slot="{ active }" v-if="!item.self && feedContext === 'everyone'">
                  <button
                    type="button"
                    @click="toggleMuteModal"
                    :class="[
                      active ? 'bg-yellow-400/10' : '',
                      'flex items-center rounded-md w-full px-4 py-2 text-sm text-neutral-500 dark:text-neutral-300'
                    ]"
                  >
                  <svg class="w-3 mr-3 dark:fill-gray-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L353.3 251.6C407.9 237 448 187.2 448 128C448 57.3 390.7 0 320 0C250.2 0 193.5 55.8 192 125.2L38.8 5.1zM264.3 304.3C170.5 309.4 96 387.2 96 482.3c0 16.4 13.3 29.7 29.7 29.7l388.6 0c3.9 0 7.6-.7 11-2.1l-261-205.6z"/></svg>
                    Mute User
                  </button>
                </MenuItem>
                <MenuItem v-slot="{ active }" v-if="item.self && item.activityType === 'status' && item.content.type === 'text'">
                  <button
                  type="button"
                  @click=""
                  :class="[
                    active ? 'bg-yellow-400/10' : '',
                    'flex items-center rounded-md w-full px-4 py-2 text-sm text-neutral-500 dark:text-neutral-300'
                  ]"
                  >
                  <svg class="w-3 mr-3 dark:fill-gray-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"/></svg>
                    Edit
                  </button>
                </MenuItem>
                <MenuItem v-slot="{ active }" v-if="item.self && item.activityType === 'status'">
                  <button
                  type="button"
                  @click="toggleDeleteModal"
                  :class="[
                    active ? 'bg-yellow-400/10' : '',
                    'flex items-center rounded-md w-full px-4 py-2 text-sm text-neutral-500 dark:text-neutral-300'
                  ]"
                  >
                  <svg class="w-3 mr-3 dark:fill-gray-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"/></svg>
                    Delete
                  </button>
                </MenuItem>
                <MenuItem v-slot="{ active }" v-if="!item.self">
                  <button
                    type="button"
                    @click="openReportDialog"
                    :class="[
                      active ? 'bg-yellow-400/10' : '',
                      'flex items-center rounded-md w-full px-4 py-2 text-sm text-neutral-500 dark:text-neutral-300'
                    ]"
                  >
                  <svg class="w-3 mr-3 dark:fill-gray-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/></svg>
                    Report
                  </button>
                </MenuItem>
                <!-- <MenuItem v-slot="{ active }" v-if="isAdmin && imageErrorHandler">
                  <button
                    type="button"
                    @click="imageErrorHandler(item._id)"
                    :class="[
                      active ? 'bg-yellow-400/10' : '',
                      'flex items-center rounded-md w-full px-4 py-2 text-sm text-neutral-500 dark:text-neutral-300'
                    ]"
                  >
                  <svg class="w-3 mr-3 dark:fill-gray-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160 352 160c-17.7 0-32 14.3-32 32s14.3 32 32 32l111.5 0c0 0 0 0 0 0l.4 0c17.7 0 32-14.3 32-32l0-112c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 35.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1L16 432c0 17.7 14.3 32 32 32s32-14.3 32-32l0-35.1 17.6 17.5c0 0 0 0 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.8c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352l34.4 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L48.4 288c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z"/></svg>
                    Refresh
                  </button>
                </MenuItem> -->
                <MenuItem v-slot="{ active }" v-if="(item.self && item.activityType !== 'status' && !isAdmin)">
                  <button type="button" class="text-xs px-4 py-2 w-full text-center">
                    No Actions
                  </button>
                </MenuItem>
              </div>
            </MenuItems>
          </transition>
        </Menu>
        <TransitionRoot appear :show="confirmMuteModal" as="template">
          <Dialog as="div" @close="toggleMuteModal" class="relative z-[100]">
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <div class="fixed inset-0 bg-black bg-opacity-25" />
            </TransitionChild>
            <div class="fixed inset-0 overflow-y-auto">
              <div
                class="flex min-h-full items-center justify-center p-4 text-center"
              >
                <TransitionChild
                  as="template"
                  enter="duration-300 ease-out"
                  enter-from="opacity-0 scale-95"
                  enter-to="opacity-100 scale-100"
                  leave="duration-200 ease-in"
                  leave-from="opacity-100 scale-100"
                  leave-to="opacity-0 scale-95"
                >
                  <DialogPanel
                    class="w-full max-w-md transform overflow-hidden rounded-2xl bg-neutral-125 dark:bg-submit-900 p-6 text-left align-middle shadow-xl transition-all"
                  >
                    <DialogTitle
                      as="h3"
                      class="text-lg font-bold leading-6 text-black dark:text-white mb-4"
                    >
                      Mute {{ item.author.username }}?
                    </DialogTitle>
                    <div class="flex flex-col mt-2 space-y-4">
                      <p class="text-sm">Are you sure you want to mute {{ item.author.username }}? Once muted, all of their current and future content will be hidden from the Everyone feed for you. You can unmute them at any time.</p>
                      <div class="flex self-end">
                        <button
                          type="button"
                          @click="toggleMuteModal"
                          class="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-gray-500 dark:text-gray-400 hover:text-black hover:bg-white dark:hover:bg-submit-500 dark:hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 mr-2"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          @click="muteUser(item.author._id)"
                          class="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-gray-500 dark:text-gray-400 hover:text-black hover:bg-white dark:hover:bg-submit-500 dark:hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                          :disabled="working"
                        >
                          {{ working ? "Muting..." : "Mute" }}
                        </button>
                      </div>
                    </div>
                  </DialogPanel>
                </TransitionChild>
              </div>
            </div>
          </Dialog>
        </TransitionRoot>
        <TransitionRoot appear :show="confirmDeleteModal" as="template">
          <Dialog as="div" @close="toggleDeleteModal" class="relative z-[100]">
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <div class="fixed inset-0 bg-black bg-opacity-25" />
            </TransitionChild>
            <div class="fixed inset-0 overflow-y-auto">
              <div
                class="flex min-h-full items-center justify-center p-4 text-center"
              >
                <TransitionChild
                  as="template"
                  enter="duration-300 ease-out"
                  enter-from="opacity-0 scale-95"
                  enter-to="opacity-100 scale-100"
                  leave="duration-200 ease-in"
                  leave-from="opacity-100 scale-100"
                  leave-to="opacity-0 scale-95"
                >
                  <DialogPanel
                    class="w-full max-w-md transform overflow-hidden rounded-2xl bg-neutral-125 dark:bg-submit-900 p-6 text-left align-middle shadow-xl transition-all"
                  >
                    <DialogTitle
                      as="h3"
                      class="text-lg font-bold leading-6 text-black dark:text-white mb-4"
                    >
                      Delete Status?
                    </DialogTitle>
                    <div class="flex flex-col mt-2 space-y-4">
                      <p class="text-sm">Are you sure you want to delete this status update? This action cannot be undone.</p>
                      <div class="flex self-end">
                        <button
                          type="button"
                          @click="toggleDeleteModal"
                          class="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-gray-500 dark:text-gray-400 hover:text-black hover:bg-white dark:hover:bg-submit-500 dark:hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 mr-2"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          @click="deleteStatusUpdate(item.content._id, item._id)"
                          class="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-gray-500 dark:text-gray-400 hover:text-black hover:bg-white dark:hover:bg-submit-500 dark:hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                          :disabled="working"
                        >
                          {{ working ? "Deleting..." : "Delete" }}
                        </button>
                      </div>
                    </div>
                  </DialogPanel>
                </TransitionChild>
              </div>
            </div>
          </Dialog>
        </TransitionRoot>
      </div>
    </header>
  </template>
  <template v-if="(item.activityType === 'announcement' || item.system)">
    <header class="inline-wrap">
      <div class="flex items-center text-[15px] sm:text-base gap-x-1.5">
        <span class="font-semibold">Submit</span>
        <span class="inline">
          <svg class="fill-gold-500" height="14" viewBox="0 0 118 143" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.82812 110.309L90.5094 22.6273C94.4146 18.7221 100.746 18.7221 104.652 22.6273L114.551 32.5268L26.8698 120.208C22.9645 124.113 16.6329 124.113 12.7276 120.208L2.82812 110.309Z" />
            <path d="M7.07107 74.9533C3.16583 71.048 3.16582 64.7164 7.07107 60.8111L60.8112 7.07102C64.7164 3.16577 71.0481 3.16577 74.9533 7.07101L77.7817 9.89944C81.687 13.8047 81.687 20.1363 77.7817 24.0416L16.9706 84.8528L7.07107 74.9533Z" />
            <path d="M39.5979 132.936C35.6927 129.031 35.6927 122.699 39.5979 118.794L100.409 57.9827L110.309 67.8822C114.214 71.7875 114.214 78.1191 110.309 82.0244L56.5685 135.764C52.6632 139.67 46.3316 139.67 42.4264 135.764L39.5979 132.936Z" />
          </svg>
        </span>
      </div>
      <div class="flex relative items-center gap-x-4 dark:text-gray-400">
        <time class="text-sm" :title="D(item.createdAt).format('LLLL')">{{ D(item.createdAt).locale('submit').fromNow() }}</time>
      </div>
    </header>
  </template>
  <template v-if="item.activityType === 'action' && item.version === 2">
    <header class="V2">
      <template v-if="['reaction', 'comment', 'follow', 'rsvp', 'considering', 'use_starterpack'].some(word => item.activitySubType.includes(word))">
        <div class="text-[15px] sm:text-base">
          <router-link :to="`/${item.author.username}`" class="truncate font-semibold">
            {{ item.author.username }}
          </router-link>
          <span>{{ actionString }}</span>
          <span v-if="sameActionAuthor && item.activitySubType !== 'follow' && item.activitySubType !== 'rsvp' && item.activitySubType !== 'considering'"> from <router-link class="underline decoration-dotted hover:decoration-solid" :to="`/${item.actions[0].actionTakenOnAuthor.username}`">{{ item.actions[0].actionTakenOnAuthor.username }}</router-link></span>
          <span v-if="sameActionAuthor && (item.activitySubType === 'rsvp' || item.activitySubType === 'considering')"> organized by <router-link class="underline decoration-dotted hover:decoration-solid" :to="`/${item.actions[0].actionTakenOnAuthor.username}`">{{ item.actions[0].actionTakenOnAuthor.username }}</router-link></span>
        </div>
      </template>
      <time class="text-sm dark:text-gray-400 break-keep" :title="D(item.createdAt).format('LLLL')">{{ D(item.createdAt).locale('submit').fromNow() }}</time>
    </header>
  </template>
  <div class="text-xs dark:text-gray-400" v-if="(item.activityType !== 'action' && item.activityType !== 'post')">
    <span class="capitalize">
      <router-link v-if="itemLink" :to="itemLink">
        {{ item.activityType }}
      </router-link>
      <span v-else>{{ item.activityType }}</span>
      <span v-if="!item.collection" class="dark:text-submit-899 text-[#FEFEFF] select-none pointer-events-none overflow-hidden">{{ id }}</span>
    </span>
    <span v-if="item.collection"> in <a class="underline" :href="`/${item.author.username}/${item.activityType}/collections/${item.collection.slug}`">{{ item.collection.title }}</a></span>
  </div>
  <div class="text-xs dark:text-gray-400" v-if="item.activityType === 'post'">
    <span>
      {{ item.content.type !== 'personal' ? 'Post in ' : 'Personals Post in ' }}{{ item.content.groupName }}
    </span>
  </div>
  <div class="text-xs dark:text-gray-400" v-if="item.activityType === 'action' && item.version === 1">
    <div v-if="item.activitySubType === 'comment'">
      <span>Commented on some stuff</span>
    </div>
    <div v-if="item.activitySubType === 'reaction'">
      <span>Reacted to some stuff</span>
    </div>
    <div v-if="item.activitySubType === 'follow'">
      <span v-if="item.actions.length === 1">Followed a human</span>
      <span v-else>Followed some humans</span>
    </div>
  </div>
</template>
