<script setup lang="ts">
import Reactions from "@/components/reactions/Reactions.vue"
import ReactV2 from "@/components/reactions/ReactV2.vue"
import { ref, inject, computed, type Ref } from "vue"

interface Props {
  item: any
  edit?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  edit: false
})

const contentText = ref<string>(props.item.content ? props.item.content.text : "")

const reacted = computed(() =>
  props.item.activityType === 'media'
    ? props.item.content[0].hasReacted
    : (props.item.content ? props.item.content.hasReacted : null)
)

const reactionsCount = computed(() =>
  props.item.activityType === 'media'
    ? props.item.content[0].reactionsCount
    : (props.item.content ? props.item.content.reactionsCount : null)
)

const reactionData = computed(() =>
  props.item.activityType === 'media'
    ? props.item.content[0].reactionData || undefined
    : (props.item.content ? props.item.content.reactionData : undefined) || undefined
)

function audienceLabel(audience: string) {
  switch (audience) {
    case "public":
      return "Public"
    case "followers":
      return "Followers"
    case "relationships":
      return "Relationships"
    default:
      return "Circle"
  }
}

const content = computed(() => {
  return {
    contentAuthor: props.item.author._id,
    contentId: props.item.activityType === 'media' ? props.item.content[0]._id : props.item.content._id,
    contentType: props.item.activityType,
    contentAudience: props.item.audience
  }
})
</script>

<template>
  <footer v-if="!edit && item.activityType !== 'action'" :class="item.activityType === 'status' && item.content.type === 'image' && contentText === '<p></p>' ? 'pt-4' : ''">
    <ul class="Reactions text-xs dark:text-gray-400 text-gray-500 w-full" v-if="!edit">
      <li v-if="item.activityType === 'post' && item.content.type !== 'personal' ">
        <Reactions :path="`/groups/${item.content.groupSlug}/${item.content.short}`" :reactions="reactionsCount" :reacted="reacted" :reactionData="reactionData" small />
      </li>
      <li
        v-if="(item.activityType === 'status' || item.activityType === 'writing' || item.activityType === 'announcement') || (item.activityType === 'media' && item.content.length === 1) || item.activityType === 'event'">
        <ReactV2 :reacted="reacted" :reactionData="reactionData" :self="item.self" :reactions="reactionsCount" :content="content" :disabled="item.activityType === 'event' && item.content.status === 'cancelled'" small :lm="true" />
      </li>
      <li class="dark:text-gray-400 text-gray-500" v-if="item.activityType === 'status' || (item.activityType === 'media' && item.content.length === 1) || item.activityType === 'writing' || item.activityType === 'event'">
        <router-link class="group flex gap-x-2 items-center" v-if="item.activityType !== 'event'" :to="`/${item.author.username}/${item.activityType}/${item.activityType === 'media' ? item.content[0]._id : (item.activityType === 'writing' ? item.content.slug : (props.item.activityType === 'media' ? props.item.content[0]._id : props.item.content._id))}`">
          <svg class="flex-none w-4 h-4 dark:fill-gray-400 group-hover:dark:fill-white" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M123.6 391.3c12.9-9.4 29.6-11.8 44.6-6.4c26.5 9.6 56.2 15.1 87.8 15.1c124.7 0 208-80.5 208-160s-83.3-160-208-160S48 160.5 48 240c0 32 12.4 62.8 35.7 89.2c8.6 9.7 12.8 22.5 11.8 35.5c-1.4 18.1-5.7 34.7-11.3 49.4c17-7.9 31.1-16.7 39.4-22.7zM21.2 431.9c1.8-2.7 3.5-5.4 5.1-8.1c10-16.6 19.5-38.4 21.4-62.9C17.7 326.8 0 285.1 0 240C0 125.1 114.6 32 256 32s256 93.1 256 208s-114.6 208-256 208c-37.1 0-72.3-6.4-104.1-17.9c-11.9 8.7-31.3 20.6-54.3 30.6c-15.1 6.6-32.3 12.6-50.1 16.1c-.8 .2-1.6 .3-2.4 .5c-4.4 .8-8.7 1.5-13.2 1.9c-.2 0-.5 .1-.7 .1c-5.1 .5-10.2 .8-15.3 .8c-6.5 0-12.3-3.9-14.8-9.9c-2.5-6-1.1-12.8 3.4-17.4c4.1-4.2 7.8-8.7 11.3-13.5c1.7-2.3 3.3-4.6 4.8-6.9c.1-.2 .2-.3 .3-.5z"/></svg>
          <span class="group-hover:dark:text-white">{{ item.activityType === 'media' ? item.content[0].commentCount : item.content.commentCount }} comment{{ (item.activityType === 'media' ? item.content[0].commentCount : item.content.commentCount) === 1 ? '' : 's' }}</span>
        </router-link>
        <router-link class="group flex gap-x-2 items-center" v-else :to="`/e/${item.content.short}`">
          <svg class="flex-none w-4 h-4 dark:fill-gray-400 group-hover:dark:fill-white" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M123.6 391.3c12.9-9.4 29.6-11.8 44.6-6.4c26.5 9.6 56.2 15.1 87.8 15.1c124.7 0 208-80.5 208-160s-83.3-160-208-160S48 160.5 48 240c0 32 12.4 62.8 35.7 89.2c8.6 9.7 12.8 22.5 11.8 35.5c-1.4 18.1-5.7 34.7-11.3 49.4c17-7.9 31.1-16.7 39.4-22.7zM21.2 431.9c1.8-2.7 3.5-5.4 5.1-8.1c10-16.6 19.5-38.4 21.4-62.9C17.7 326.8 0 285.1 0 240C0 125.1 114.6 32 256 32s256 93.1 256 208s-114.6 208-256 208c-37.1 0-72.3-6.4-104.1-17.9c-11.9 8.7-31.3 20.6-54.3 30.6c-15.1 6.6-32.3 12.6-50.1 16.1c-.8 .2-1.6 .3-2.4 .5c-4.4 .8-8.7 1.5-13.2 1.9c-.2 0-.5 .1-.7 .1c-5.1 .5-10.2 .8-15.3 .8c-6.5 0-12.3-3.9-14.8-9.9c-2.5-6-1.1-12.8 3.4-17.4c4.1-4.2 7.8-8.7 11.3-13.5c1.7-2.3 3.3-4.6 4.8-6.9c.1-.2 .2-.3 .3-.5z"/></svg>
          <span class="group-hover:dark:text-white">{{ item.activityType === 'media' ? item.content[0].commentCount : item.content.commentCount }} comment{{ (item.activityType === 'media' ? item.content[0].commentCount : item.content.commentCount) === 1 ? '' : 's' }}</span>
        </router-link>
      </li>
      <li v-if="item.activityType === 'announcement'">
        <router-link class="group flex gap-x-2 items-center" :to="`/announcements/${item.content.slug}`">
          <svg class="flex-none w-4 h-4 dark:fill-gray-400 group-hover:dark:fill-white" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M123.6 391.3c12.9-9.4 29.6-11.8 44.6-6.4c26.5 9.6 56.2 15.1 87.8 15.1c124.7 0 208-80.5 208-160s-83.3-160-208-160S48 160.5 48 240c0 32 12.4 62.8 35.7 89.2c8.6 9.7 12.8 22.5 11.8 35.5c-1.4 18.1-5.7 34.7-11.3 49.4c17-7.9 31.1-16.7 39.4-22.7zM21.2 431.9c1.8-2.7 3.5-5.4 5.1-8.1c10-16.6 19.5-38.4 21.4-62.9C17.7 326.8 0 285.1 0 240C0 125.1 114.6 32 256 32s256 93.1 256 208s-114.6 208-256 208c-37.1 0-72.3-6.4-104.1-17.9c-11.9 8.7-31.3 20.6-54.3 30.6c-15.1 6.6-32.3 12.6-50.1 16.1c-.8 .2-1.6 .3-2.4 .5c-4.4 .8-8.7 1.5-13.2 1.9c-.2 0-.5 .1-.7 .1c-5.1 .5-10.2 .8-15.3 .8c-6.5 0-12.3-3.9-14.8-9.9c-2.5-6-1.1-12.8 3.4-17.4c4.1-4.2 7.8-8.7 11.3-13.5c1.7-2.3 3.3-4.6 4.8-6.9c.1-.2 .2-.3 .3-.5z"/></svg>
          <span class="group-hover:dark:text-white">{{ item.content.commentCount }} comment{{ item.content.commentCount === 1 ? '' : 's' }}</span>
        </router-link>
      </li>
      <li v-if="item.activityType === 'post' && item.content.type !== 'personal'">
        <router-link class="group flex gap-x-2 items-center" :to="`/groups/${item.content.groupSlug}/${item.content.short}`">
          <svg class="flex-none w-4 h-4 dark:fill-gray-400 group-hover:dark:fill-white" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M123.6 391.3c12.9-9.4 29.6-11.8 44.6-6.4c26.5 9.6 56.2 15.1 87.8 15.1c124.7 0 208-80.5 208-160s-83.3-160-208-160S48 160.5 48 240c0 32 12.4 62.8 35.7 89.2c8.6 9.7 12.8 22.5 11.8 35.5c-1.4 18.1-5.7 34.7-11.3 49.4c17-7.9 31.1-16.7 39.4-22.7zM21.2 431.9c1.8-2.7 3.5-5.4 5.1-8.1c10-16.6 19.5-38.4 21.4-62.9C17.7 326.8 0 285.1 0 240C0 125.1 114.6 32 256 32s256 93.1 256 208s-114.6 208-256 208c-37.1 0-72.3-6.4-104.1-17.9c-11.9 8.7-31.3 20.6-54.3 30.6c-15.1 6.6-32.3 12.6-50.1 16.1c-.8 .2-1.6 .3-2.4 .5c-4.4 .8-8.7 1.5-13.2 1.9c-.2 0-.5 .1-.7 .1c-5.1 .5-10.2 .8-15.3 .8c-6.5 0-12.3-3.9-14.8-9.9c-2.5-6-1.1-12.8 3.4-17.4c4.1-4.2 7.8-8.7 11.3-13.5c1.7-2.3 3.3-4.6 4.8-6.9c.1-.2 .2-.3 .3-.5z"/></svg>
          <span class="group-hover:dark:text-white">{{ item.content.commentCount }} comment{{ item.content.commentCount === 1 ? '' : 's' }}</span>
        </router-link>
      </li>
      <!-- <li class="group flex gap-x-2 items-center cursor-not-allowed" v-if="item.activityType !== 'post'">
        <svg class="flex-none w-4 h-4 dark:fill-gray-400 group-hover:dark:fill-white" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM135.1 217.4c-4.5 4.2-7.1 10.1-7.1 16.3c0 12.3 10 22.3 22.3 22.3H208v96c0 17.7 14.3 32 32 32h32c17.7 0 32-14.3 32-32V256h57.7c12.3 0 22.3-10 22.3-22.3c0-6.2-2.6-12.1-7.1-16.3L269.8 117.5c-3.8-3.5-8.7-5.5-13.8-5.5s-10.1 2-13.8 5.5L135.1 217.4z"/></svg>
        <span class="group-hover:dark:text-white">{{ item.content.boostCount ? item.content.boostCount : 0 }} Boosts</span>
      </li> -->
      <li class="text-xs flex items-center gap-x-2 capitalize" v-if="item.audience !== 'public'">
        <svg class="flex-none w-f h-4 dark:fill-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"/></svg>
        <span>{{audienceLabel(item.audience)}}</span>
      </li>
    </ul>
  </footer>
</template>
