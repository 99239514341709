<script setup lang="ts">
import { vEffect as vEffectDirective } from "@/directives/effect"
import { computed, onMounted, onBeforeUnmount } from "vue"
import { usePlatformStore } from "@/stores/Platform"
import Avatar from "@/components/core/Avatar.vue"
import { useRoute, useRouter } from "vue-router"
import { useUserStore } from "@/stores/User"
import { feedDB } from "@/stores/Feed"
import { storeToRefs } from "pinia"
import {
  Menu,
  MenuButton,
  MenuItems
} from "@headlessui/vue"

const vEffect = {
  mounted: vEffectDirective,
  updated: vEffectDirective
}

const route = useRoute()
const Router = useRouter()
const userStore = useUserStore()
const { messages, unread, username, pfp, firstLetter, id, signedIn } = storeToRefs(userStore)
const Platform = usePlatformStore()
const { outOfDate } = storeToRefs(Platform)

const shouldShowPlatformHeader = computed(() => {
  return route.meta.noAuth ? false : true
})

function toggleSearchAndClose(close: () => void) {
  Platform.toggleSearchOpen()
  close()
}

// Add function to check if we're on mobile
function isMobileView() {
  return window.matchMedia('(max-width: 639px)').matches // sm breakpoint is 640px
}

// Update function to only disable scroll on mobile
function toggleBodyScroll(disable: boolean) {
  if (isMobileView()) {
    document.body.style.overflow = disable ? 'hidden' : ''
  }
}

onMounted(() => {
  let nav = document.querySelector('.Nav')
  let lastScrollTop = 0
  let menuOpen = false // Add a variable to track menu state

  const handleScroll = () => {
    // Don't hide header if menu is open on desktop
    if (menuOpen && !isMobileView()) {
      return
    }

    let st = window.scrollY || document.documentElement.scrollTop
    let direction = st > lastScrollTop ? 'down' : 'up'

    // Apply changes if scrolled more than 5 pixels or at the top of the page
    if (Math.abs(st - lastScrollTop) > 5 || st === 0) {
      nav!.setAttribute('scroll-direction', direction)
    }

    lastScrollTop = st <= 0 ? 0 : st
  }

  document.addEventListener('scroll', handleScroll, { passive: true })

  // Watch for menu state changes
  const menuButton = document.querySelector('.menu-button')
  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      if (mutation.target instanceof HTMLElement) {
        menuOpen = mutation.target.getAttribute('aria-expanded') === 'true'
      }
    })
  })

  if (menuButton) {
    observer.observe(menuButton, {
      attributes: true,
      attributeFilter: ['aria-expanded']
    })
  }

  onBeforeUnmount(() => {
    document.removeEventListener('scroll', handleScroll)
    observer.disconnect()
    toggleBodyScroll(false)
  })
})

async function goHome() {
  const currentPath = route.path

  // Clear feed data from IndexedDB
  try {
    await feedDB.clearFeedData(id.value, 'all')
    for (let i = 0; i < sessionStorage.length; i++) {
      const key = sessionStorage.key(i)
      if (key?.startsWith('feedScrollPosition_')) {
        sessionStorage.removeItem(key)
      }
    }
  } catch (error) {
    console.error('Failed to clear feed data:', error)
  }

  if (currentPath === '/home') {
    reloadPage()
  } else {
    Router.push('/home')
  }
}

function reloadPage() {
  // @ts-ignore
  window.location.reload(true)
}
</script>

<template>
  <header class="flex justify-between items-center px-2 sm:px-6 pt-6" v-if="!shouldShowPlatformHeader">
    <router-link to="/" class="inline-flex gap-2 items-center">
      <svg role="img" aria-labelledby="headerLogoTitle" aria-describedby="headerLogoDesc" class="dark:fill-gold-500 fill-gold-700" width="30" viewBox="0 0 118 143" fill="none" xmlns="http://www.w3.org/2000/svg" aria-label="Home Icon">
        <title id="headerLogoTitle">Home</title>
        <desc id="headerLogoDesc">Three parallel diagonal bars in gold.</desc>
        <path d="M2.82812 110.309L90.5094 22.6273C94.4146 18.7221 100.746 18.7221 104.652 22.6273L114.551 32.5268L26.8698 120.208C22.9645 124.113 16.6329 124.113 12.7276 120.208L2.82812 110.309Z" />
        <path d="M7.07107 74.9533C3.16583 71.048 3.16582 64.7164 7.07107 60.8111L60.8112 7.07102C64.7164 3.16577 71.0481 3.16577 74.9533 7.07101L77.7817 9.89944C81.687 13.8047 81.687 20.1363 77.7817 24.0416L16.9706 84.8528L7.07107 74.9533Z" />
        <path d="M39.5979 132.936C35.6927 129.031 35.6927 122.699 39.5979 118.794L100.409 57.9827L110.309 67.8822C114.214 71.7875 114.214 78.1191 110.309 82.0244L56.5685 135.764C52.6632 139.67 46.3316 139.67 42.4264 135.764L39.5979 132.936Z" />
      </svg>
      <h1 class="hidden sm:inline text-submit-950 dark:text-white font-bold font-display text-lg">Submit</h1>
    </router-link>
    <ul class="flex text-sm justify-end items-center space-x-6 list-none">
      <li class="hidden sm:block">
        <router-link class="font-semibold font-display text-base hover:underline hover:decoration-2 capitalize" to="/about">{{ $t("about") }}</router-link>
      </li>
      <li class="hidden sm:block">
        <a class="font-semibold font-display text-base hover:underline hover:decoration-2 capitalize" href="https://blog.submit.gg">{{ $t("blog") }}</a>
      </li>
      <li v-if="!signedIn">
        <router-link class="font-semibold font-display text-base hover:underline hover:decoration-2 whitespace-nowrap" to="/signin">{{ $t("signin") }}</router-link>
      </li>
      <li v-if="!signedIn" class="group flex rounded-md bg-gradient-to-r from-[#8B6CDF] to-[#E176A3] p-0.5">
        <router-link class="font-semibold rounded-md bg-white dark:bg-submit-950 group-hover:bg-transparent text-xs px-2 py-1.5" to="/signup">
          <span class="text-transparent bg-clip-text bg-gradient-to-r from-[#8B6CDF] to-[#E176A3] group-hover:text-white">{{ $t("joinnow") }}</span>
        </router-link>
      </li>
      <li v-if="signedIn">
        <router-link :to="`/${username}`">
          <Avatar :pfp="pfp" :first-letter="firstLetter" size="sm" />
        </router-link>
      </li>
      <li v-if="signedIn">
        <router-link to="/signout">
          <svg role="img" aria-labelledby="signOutIcon" aria-describedby="signOutDesc" class="dark:fill-gray-300 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <title id="signOutIcon">Sign Out</title>
            <desc id="signOutDesc">Half of a door frame with an arrow pointing outwards.</desc>
            <path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"/>
          </svg>
        </router-link>
      </li>
    </ul>
  </header>
  <nav class="Nav" v-else>
    <div class="NavWrapper bg-submit-925">
      <button @click="goHome" title="Go Home" type="button">
        <svg role="img" aria-labelledby="headerLogoTitle" aria-describedby="headerLogoDesc" class="fill-gold-500 cursor-pointer" width="24" height="28" viewBox="0 0 118 143" fill="none" xmlns="http://www.w3.org/2000/svg">
          <title id="headerLogoTitle">Home</title>
          <desc id="headerLogoDesc">Three parallel diagonal bars in gold.</desc>
          <path d="M2.82812 110.309L90.5094 22.6273C94.4146 18.7221 100.746 18.7221 104.652 22.6273L114.551 32.5268L26.8698 120.208C22.9645 124.113 16.6329 124.113 12.7276 120.208L2.82812 110.309Z" />
          <path d="M7.07107 74.9533C3.16583 71.048 3.16582 64.7164 7.07107 60.8111L60.8112 7.07102C64.7164 3.16577 71.0481 3.16577 74.9533 7.07101L77.7817 9.89944C81.687 13.8047 81.687 20.1363 77.7817 24.0416L16.9706 84.8528L7.07107 74.9533Z" />
          <path d="M39.5979 132.936C35.6927 129.031 35.6927 122.699 39.5979 118.794L100.409 57.9827L110.309 67.8822C114.214 71.7875 114.214 78.1191 110.309 82.0244L56.5685 135.764C52.6632 139.67 46.3316 139.67 42.4264 135.764L39.5979 132.936Z" />
        </svg>
      </button>
      <ul class="hidden md:flex text-sm gap-8 text-gray-300 items-center">
        <li>
          <button @click="goHome" type="button" title="Go Home" class="hover:text-white">{{ $t('home') }}</button>
        </li>
        <li>
          <router-link title="Explore" to="/explore">{{ $t('explore') }}</router-link>
        </li>
        <li class="hidden lg:block">
          <router-link title="Kinks" to="/kinks">
            Kinks &amp; Interests
          </router-link>
        </li>
        <li>
          <router-link title="Groups" class="hover:text-white" to="/groups">{{ $t('groups') }}</router-link>
        </li>
        <li>
          <router-link title="Events" class="hover:text-white" to="/events">{{ $t('events') }}</router-link>
        </li>
        <Menu as="li" v-slot="{ close }">
          <div class="transition-colors outline-none flex justify-center items-center">
            <MenuButton>
              <span title="Discover New Content" class="hover:text-white">Submit</span>
            </MenuButton>
          </div>
          <MenuItems class="text-gray-400 max-w-md outline-none sm:translate-x-[324px] lg:translate-x-[464px] translate-y-[60px] shadow-lg flex flex-col absolute basis-auto bg-submit-900 left-0 top-0 z-50 rounded-2xl overflow-hidden">
            <div class="p-2 w-fit text-sm">

              <div class="group relative flex">
                <div class="group relative gap-x-6 flex flex-grow rounded-lg py-3 px-4 hover:bg-submit-950">
                  <div>
                    <router-link @click="close" :to="`/announcements`" class="font-semibold">
                      Announcements
                      <span class="absolute inset-0"></span>
                    </router-link>
                  </div>
                </div>
              </div>

              <div class="group relative flex">
                <div class="group relative gap-x-6 flex flex-grow rounded-lg py-3 px-4 hover:bg-submit-950">
                  <div>
                    <router-link @click="close" :to="`/changelog`" class="font-semibold">
                      Changelog
                      <span class="absolute inset-0"></span>
                    </router-link>
                  </div>
                </div>
              </div>

              <div class="group relative flex">
                <div class="group relative gap-x-6 flex flex-grow rounded-lg py-3 px-4 hover:bg-submit-950">
                  <div>
                    <router-link @click="close" :to="`/roadmap`" class="font-semibold">
                      Roadmap
                      <span class="absolute inset-0"></span>
                    </router-link>
                  </div>
                </div>
              </div>

              <div class="group relative flex">
                <div class="group relative gap-x-6 flex flex-grow rounded-lg py-3 px-4 hover:bg-submit-950">
                  <div>
                    <router-link @click="close" :to="`/goals`" class="font-semibold">
                      Goals
                      <span class="absolute inset-0"></span>
                    </router-link>
                  </div>
                </div>
              </div>

              <div class="group relative flex">
                <div class="group relative gap-x-6 flex flex-grow rounded-lg py-3 px-4 hover:bg-submit-950">
                  <div>
                    <router-link @click="close" :to="`/council`" class="font-semibold">
                      Council
                      <span class="absolute inset-0"></span>
                    </router-link>
                  </div>
                </div>
              </div>

              <div class="group relative flex">
                <div class="group relative gap-x-6 flex flex-grow rounded-lg py-3 px-4 hover:bg-submit-950">
                  <div>
                    <router-link @click="close" :to="`/about`" class="font-semibold">
                      About
                      <span class="absolute inset-0"></span>
                    </router-link>
                  </div>
                </div>
              </div>

              <div class="group relative flex">
                <div class="group relative gap-x-6 flex flex-grow rounded-lg py-3 px-4 hover:bg-submit-950">
                  <div>
                    <router-link @click="close" :to="`/backer`" class="font-semibold">
                      Backer
                      <span class="absolute inset-0"></span>
                    </router-link>
                  </div>
                </div>
              </div>

              <div class="group relative flex">
                <div class="group relative gap-x-6 flex flex-grow rounded-lg py-3 px-4 hover:bg-submit-950">
                  <div>
                    <router-link @click="close" :to="`/transparency`" class="font-semibold">
                      Transparency
                      <span class="absolute inset-0"></span>
                    </router-link>
                  </div>
                </div>
              </div>

            </div>
          </MenuItems>
        </Menu>
      </ul>
      <ul class="flex gap-8 md:gap-6 items-center">
        <li @click="reloadPage" v-if="outOfDate">
          <svg aria-labelledby="searchID searchDescID" role="img" class="cursor-pointer animate-pulse fill-[#E177A4] hover:fill-white h-[16px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <title id="updateID">Update Submit</title>
            <desc id="updateDescID">Update Submit to the latest version.</desc>
            <path d="M327.5 85.2c-4.5 1.7-7.5 6-7.5 10.8s3 9.1 7.5 10.8L384 128l21.2 56.5c1.7 4.5 6 7.5 10.8 7.5s9.1-3 10.8-7.5L448 128l56.5-21.2c4.5-1.7 7.5-6 7.5-10.8s-3-9.1-7.5-10.8L448 64 426.8 7.5C425.1 3 420.8 0 416 0s-9.1 3-10.8 7.5L384 64 327.5 85.2zM9.3 240C3.6 242.6 0 248.3 0 254.6s3.6 11.9 9.3 14.5L26.3 277l8.1 3.7 .6 .3 88.3 40.8L164.1 410l.3 .6 3.7 8.1 7.9 17.1c2.6 5.7 8.3 9.3 14.5 9.3s11.9-3.6 14.5-9.3l7.9-17.1 3.7-8.1 .3-.6 40.8-88.3L346 281l.6-.3 8.1-3.7 17.1-7.9c5.7-2.6 9.3-8.3 9.3-14.5s-3.6-11.9-9.3-14.5l-17.1-7.9-8.1-3.7-.6-.3-88.3-40.8L217 99.1l-.3-.6L213 90.3l-7.9-17.1c-2.6-5.7-8.3-9.3-14.5-9.3s-11.9 3.6-14.5 9.3l-7.9 17.1-3.7 8.1-.3 .6-40.8 88.3L35.1 228.1l-.6 .3-8.1 3.7L9.3 240zm83 14.5l51.2-23.6c10.4-4.8 18.7-13.1 23.5-23.5l23.6-51.2 23.6 51.2c4.8 10.4 13.1 18.7 23.5 23.5l51.2 23.6-51.2 23.6c-10.4 4.8-18.7 13.1-23.5 23.5l-23.6 51.2-23.6-51.2c-4.8-10.4-13.1-18.7-23.5-23.5L92.3 254.6zM384 384l-56.5 21.2c-4.5 1.7-7.5 6-7.5 10.8s3 9.1 7.5 10.8L384 448l21.2 56.5c1.7 4.5 6 7.5 10.8 7.5s9.1-3 10.8-7.5L448 448l56.5-21.2c4.5-1.7 7.5-6 7.5-10.8s-3-9.1-7.5-10.8L448 384l-21.2-56.5c-1.7-4.5-6-7.5-10.8-7.5s-9.1 3-10.8 7.5L384 384z"/>
          </svg>
        </li>
        <li tabindex="0" class="hidden md:flex" @click="Platform.toggleSearchOpen">
          <svg aria-labelledby="searchID searchDescID" role="img" class="cursor-pointer fill-gray-400 hover:fill-white h-[16px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <title id="searchID">Search</title>
            <desc id="searchDescID">Search Submit.</desc>
            <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/>
          </svg>
        </li>
        <Menu as="li" class="flex items-center" v-slot="{ open, close }">
          <div class="transition-colors outline-none flex justify-center items-center h-[28px] w-[24px]">
            <MenuButton>
              <svg aria-labelledby="addID addDescID" role="img" class="cursor-pointer fill-gray-400 hover:fill-white h-[17px]" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                <title id="addID">Add Content</title>
                <desc id="addDescID">Add Content to Submit.</desc>
                <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM232 344V280H168c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V168c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H280v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z"/>
              </svg>
            </MenuButton>
          </div>
          <MenuItems class="text-gray-400 max-w-md outline-none -translate-x-[140px] sm:-translate-x-[125px] translate-y-[60px] shadow-lg flex flex-col absolute basis-auto bg-submit-900 right-0 top-0 z-50 rounded-2xl overflow-hidden">
            <div class="p-2 w-fit text-sm">

              <div class="group relative flex">
                <div class="group relative gap-x-6 flex flex-grow rounded-lg py-3 px-4 hover:bg-submit-950">
                  <div>
                    <router-link @click="close" :to="`/home?new=status`" class="font-semibold">
                      Add Status Update
                      <span class="absolute inset-0"></span>
                    </router-link>
                  </div>
                </div>
              </div>

              <div class="group relative flex">
                <div class="group relative gap-x-6 flex flex-grow rounded-lg py-3 px-4 hover:bg-submit-950">
                  <div>
                    <router-link @click="close" :to="`/home?new=status&type=media`" class="font-semibold">
                      Add Media Update
                      <span class="absolute inset-0"></span>
                    </router-link>
                  </div>
                </div>
              </div>

              <div class="group relative flex">
                <div class="group relative gap-x-6 flex flex-grow rounded-lg py-3 px-4 hover:bg-submit-950">
                  <div>
                    <router-link @click="close" :to="`/${username}/media?new=media`" class="font-semibold">
                      Add Media
                      <span class="absolute inset-0"></span>
                    </router-link>
                  </div>
                </div>
              </div>

              <div class="group relative flex">
                <div class="group relative gap-x-6 flex flex-grow rounded-lg py-3 px-4 hover:bg-submit-950">
                  <div>
                    <router-link @click="close" :to="`/${username}/writing/new`" class="font-semibold">
                      Add Writing
                      <span class="absolute inset-0"></span>
                    </router-link>
                  </div>
                </div>
              </div>

            </div>
          </MenuItems>
        </Menu>
        <li>
          <router-link class="flex relative" title="Messages" to="/messages">
            <span class="absolute -top-1 -right-1 rounded-full w-2 h-2 bg-red-500" v-if="messages > 0"></span>
            <svg aria-labelledby="inboxID inboxDescID" role="img" class="fill-gray-400 hover:fill-white h-[18px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <title id="inboxID">Messages</title>
              <desc id="inboxDescID">View and reply to your messages.</desc>
              <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"/>
            </svg>
          </router-link>
        </li>
        <li>
          <router-link class="flex relative" title="Notifications" to="/notifications">
            <span class="absolute -top-1 -right-1 rounded-full w-2 h-2 bg-red-500" v-if="unread > 0"></span>
            <svg aria-labelledby="notificationID notificationDescID" role="img" class="fill-gray-400 hover:fill-white h-[18px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <title id="notificationID">Notifications</title>
              <desc id="notificationDescID">View your notifications.</desc>
              <path d="M224 0c-17.7 0-32 14.3-32 32V51.2C119 66 64 130.6 64 208v18.8c0 47-17.3 92.4-48.5 127.6l-7.4 8.3c-8.4 9.4-10.4 22.9-5.3 34.4S19.4 416 32 416H416c12.6 0 24-7.4 29.2-18.9s3.1-25-5.3-34.4l-7.4-8.3C401.3 319.2 384 273.9 384 226.8V208c0-77.4-55-142-128-156.8V32c0-17.7-14.3-32-32-32zm45.3 493.3c12-12 18.7-28.3 18.7-45.3H224 160c0 17 6.7 33.3 18.7 45.3s28.3 18.7 45.3 18.7s33.3-6.7 45.3-18.7z"/>
            </svg>
          </router-link>
        </li>
        <li>
          <router-link :to="`/${username}`">
            <figure class="flex w-7 h-7 bg-submit-950 text-gray-500 rounded-full items-center justify-center font-semibold bg-cover" :style="pfp ? `background-image: url('${pfp}')` : ''">
              {{ pfp ? '' : firstLetter }}
            </figure>
          </router-link>
        </li>
        <Menu as="li" class="flex items-center" v-slot="{ open, close }">
          <div
            class="transition-colors outline-none flex justify-center items-center h-[28px] w-[24px]"
            v-effect="toggleBodyScroll(open)"
          >
            <MenuButton
              class="menu-button flex flex-col h-full w-full justify-center items-center"
            ></MenuButton>
          </div>
          <MenuItems
            class="text-gray-400 w-full sm:max-w-xs md:max-w-md h-[calc(100vh-52px)] outline-none translate-y-[52px] shadow-lg flex flex-col fixed basis-auto bg-submit-925 right-0 top-0 z-50 overflow-hidden"
          >
            <div class="w-full overflow-y-scroll">

              <div class="flex items-center flex-wrap">
                <router-link @click="close" :to="`/${username}`" class="flex flex-1 gap-4 items-center p-4">
                  <figure class="flex w-12 h-12 bg-submit-950 text-gray-500 rounded-full items-center justify-center font-semibold bg-cover" :style="pfp ? `background-image: url('${pfp}')` : ''">
                    {{ pfp ? '' : firstLetter }}
                  </figure>
                  <div class="flex flex-col text-left">
                    <div class="font-semibold">{{ username }}</div>
                    <div class="text-sm text-gray-500">Goto your Profile</div>
                  </div>
                </router-link>
                <router-link @click="close" class="px-8" to="/vault">
                  <svg aria-labelledby="vaultID vaultDescID" role="img" class="h-6 fill-gray-400 group-hover:fill-gold-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                    <title id="vaultID">Vault</title>
                    <desc id="vaultDescID">Manage your content.</desc>
                    <path d="M64 0C28.7 0 0 28.7 0 64V416c0 35.3 28.7 64 64 64H80l16 32h64l16-32H400l16 32h64l16-32h16c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H64zM224 320a80 80 0 1 0 0-160 80 80 0 1 0 0 160zm0-240a160 160 0 1 1 0 320 160 160 0 1 1 0-320zM480 221.3V336c0 8.8-7.2 16-16 16s-16-7.2-16-16V221.3c-18.6-6.6-32-24.4-32-45.3c0-26.5 21.5-48 48-48s48 21.5 48 48c0 20.9-13.4 38.7-32 45.3z"/>
                  </svg>
                </router-link>
              </div>
              <div class="grid grid-cols-2 border-y divide-x divide-gray-700 border-gray-700 text-sm mb-4">
                <router-link @click="close" class="w-full text-center py-2 hover:bg-submit-950" to="/settings">{{ $t('settings') }}</router-link>
                <router-link @click="close" class="w-full text-center py-2 hover:bg-submit-950" to="/signout">{{ $t('signout') }}</router-link>
              </div>

              <div class="group relative flex md:hidden">
                <div class="group relative gap-x-6 flex flex-grow rounded-lg p-4 hover:bg-submit-950">
                  <div>
                    <div tabindex="0" @click="toggleSearchAndClose(close)" class="font-semibold cursor-pointer">
                      Search
                      <span class="absolute inset-0"></span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="group relative flex md:hidden">
                <div class="group relative gap-x-6 flex flex-grow rounded-lg p-4 hover:bg-submit-950">
                  <div>
                    <router-link @click="close" :to="`/explore`" class="font-semibold">
                      Explore
                      <span class="absolute inset-0"></span>
                    </router-link>
                  </div>
                </div>
              </div>

              <div class="group relative flex lg:hidden">
                <div class="group relative gap-x-6 flex flex-grow rounded-lg p-4 hover:bg-submit-950">
                  <div class="hidden md:flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-submit-950">
                    <svg class="h-6 fill-gray-400 group-hover:fill-gold-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M240 32a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zM192 48a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm-32 80c17.7 0 32 14.3 32 32l8 0c13.3 0 24 10.7 24 24l0 16c0 1.7-.2 3.4-.5 5.1C280.3 229.6 320 286.2 320 352c0 88.4-71.6 160-160 160S0 440.4 0 352c0-65.8 39.7-122.4 96.5-146.9c-.4-1.6-.5-3.3-.5-5.1l0-16c0-13.3 10.7-24 24-24l8 0c0-17.7 14.3-32 32-32zm0 320a96 96 0 1 0 0-192 96 96 0 1 0 0 192zm192-96c0-25.9-5.1-50.5-14.4-73.1c16.9-32.9 44.8-59.1 78.9-73.9c-.4-1.6-.5-3.3-.5-5.1l0-16c0-13.3 10.7-24 24-24l8 0c0-17.7 14.3-32 32-32s32 14.3 32 32l8 0c13.3 0 24 10.7 24 24l0 16c0 1.7-.2 3.4-.5 5.1C600.3 229.6 640 286.2 640 352c0 88.4-71.6 160-160 160c-62 0-115.8-35.3-142.4-86.9c9.3-22.5 14.4-47.2 14.4-73.1zm224 0a96 96 0 1 0 -192 0 96 96 0 1 0 192 0zM368 0a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm80 48a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/></svg>
                  </div>
                  <div>
                    <router-link @click="close" to="/kinks" class="font-semibold">
                      Kinks &amp; Interests
                      <span class="absolute inset-0"></span>
                    </router-link>
                    <p class="hidden md:flex mt-1 text-sm">Discover Kinks &amp; Interests</p>
                  </div>
                </div>
              </div>

              <div class="group relative flex">
                <div class="group relative gap-x-6 flex flex-grow rounded-lg p-4 hover:bg-submit-950">
                  <div class="hidden md:flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-submit-950">
                    <svg class="h-6 fill-gray-400 group-hover:fill-gold-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M51.8 160.4c-3.7 2.1-4.9 6.8-2.8 10.5l167 289.3c2.1 3.7 6.8 4.9 10.5 2.8L419.4 351.6c3.7-2.1 4.9-6.8 2.8-10.5L255.2 51.8c-2.1-3.7-6.8-4.9-10.5-2.8L51.8 160.4zM7.5 194.9c-15.4-26.6-6.3-60.7 20.4-76.1L220.7 7.5c26.6-15.4 60.7-6.3 76.1 20.4l167 289.3c15.4 26.6 6.2 60.7-20.4 76.1L250.5 504.5c-26.6 15.4-60.7 6.2-76.1-20.4L7.5 194.9zm451.9 226c41.9-24.2 56.3-77.8 32.1-119.8L354.7 64.2c1.7-.2 3.5-.2 5.3-.2l224 0c30.9 0 56 25.1 56 56l0 336c0 30.9-25.1 56-56 56l-224 0c-13.7 0-26.2-4.9-35.9-13l135.3-78.1z"/></svg>
                  </div>
                  <div>
                    <router-link @click="close" to="/starter-packs" class="font-semibold">
                      Starter Packs
                      <span class="absolute inset-0"></span>
                    </router-link>
                    <p class="hidden md:flex mt-1 text-sm">Jump-start your Submit experience</p>
                  </div>
                </div>
              </div>

              <div class="group relative flex md:hidden">
                <div class="group relative gap-x-6 flex flex-grow rounded-lg p-4 hover:bg-submit-950">
                  <div>
                    <router-link @click="close" :to="`/groups`" class="font-semibold">
                      {{ $t('groups') }}
                      <span class="absolute inset-0"></span>
                    </router-link>
                  </div>
                </div>
              </div>

              <div class="group relative flex md:hidden">
                <div class="group relative gap-x-6 flex flex-grow rounded-lg p-4 hover:bg-submit-950">
                  <div>
                    <router-link @click="close" :to="`/events`" class="font-semibold">
                      {{ $t('events') }}
                      <span class="absolute inset-0"></span>
                    </router-link>
                  </div>
                </div>
              </div>

              <div class="group relative flex md:hidden">
                <div class="group relative gap-x-6 flex flex-grow rounded-lg p-4 hover:bg-submit-950">
                  <div>
                    <router-link @click="close" :to="`/platform`" class="font-semibold">
                      Submit
                      <span class="absolute inset-0"></span>
                    </router-link>
                  </div>
                </div>
              </div>

              <div class="group relative flex">
                <div class="group relative gap-x-6 flex flex-grow rounded-lg p-4 hover:bg-submit-950">
                  <div class="hidden md:flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-submit-950">
                    <svg class="h-6 fill-gray-400 group-hover:fill-gold-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M48 0C21.5 0 0 21.5 0 48V464c0 26.5 21.5 48 48 48h96V432c0-26.5 21.5-48 48-48s48 21.5 48 48v80h96c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48H48zM64 240c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V240zm112-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16V240c0-8.8 7.2-16 16-16zm80 16c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H272c-8.8 0-16-7.2-16-16V240zM80 96h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16zm80 16c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16V112zM272 96h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H272c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16z"/></svg>
                  </div>
                  <div>
                    <router-link @click="close" to="/organizations" class="font-semibold">
                      {{ $t('organizations') }}
                      <span class="absolute inset-0"></span>
                    </router-link>
                    <p class="hidden md:flex mt-1 text-sm">Set up or manage your organizations</p>
                  </div>
                </div>
              </div>

              <div class="group relative flex">
                <div class="group relative gap-x-6 flex flex-grow rounded-lg p-4 hover:bg-submit-950">
                  <div class="hidden md:flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-submit-950">
                    <svg class="h-6 fill-indigo-325 group-hover:fill-gold-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/></svg>
                  </div>
                  <div>
                    <router-link @click="close" to="/reporting" class="font-semibold">
                      {{ $t('reporting') }}
                      <span class="absolute inset-0"></span>
                    </router-link>
                    <p class="hidden md:flex mt-1 text-sm">Go to your Reporting Portal</p>
                  </div>
                </div>
              </div>

              <div class="group relative flex">
                <div class="group relative gap-x-6 flex flex-grow rounded-lg p-4 hover:bg-submit-950">
                  <div class="hidden md:flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-submit-950">
                    <svg class="h-6 fill-indigo-325 group-hover:fill-gold-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M64 64C28.7 64 0 92.7 0 128v64c0 8.8 7.4 15.7 15.7 18.6C34.5 217.1 48 235 48 256s-13.5 38.9-32.3 45.4C7.4 304.3 0 311.2 0 320v64c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V320c0-8.8-7.4-15.7-15.7-18.6C541.5 294.9 528 277 528 256s13.5-38.9 32.3-45.4c8.3-2.9 15.7-9.8 15.7-18.6V128c0-35.3-28.7-64-64-64H64zm64 112l0 160c0 8.8 7.2 16 16 16H432c8.8 0 16-7.2 16-16V176c0-8.8-7.2-16-16-16H144c-8.8 0-16 7.2-16 16zM96 160c0-17.7 14.3-32 32-32H448c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32H128c-17.7 0-32-14.3-32-32V160z"/></svg>
                  </div>
                  <div>
                    <router-link @click="close" to="/reservations" class="font-semibold">
                      Reservations
                      <span class="absolute inset-0"></span>
                    </router-link>
                    <p class="hidden md:flex mt-1 text-sm">Manage your Reservations</p>
                  </div>
                </div>
              </div>

            </div>
          </MenuItems>
        </Menu>
      </ul>
    </div>
  </nav>
</template>
