<script setup lang="ts">
import NewComment from "@/components/comments/NewComment.vue"
import ReactionList from "@/components/reactions/List.vue"
import Comment from "@/components/comments/Comment.vue"
import { usePlatformStore } from "@/stores/Platform"
import { useUserStore } from "@/stores/User"
import D from "@/composables/TimeDisplay"
import { useRouter } from "vue-router"
import { storeToRefs } from "pinia"
import { ref, computed } from "vue"
import API from "@/api/api"
import {
  Menu,
  MenuButton,
  MenuItems,
  MenuItem,
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle
} from "@headlessui/vue"

interface Props {
  forAudience: string
  comment: any
  child?: boolean
  locked?: boolean
  group?: any
  event?: any
  short?: any
  canComment?: boolean
}

const props = defineProps<Props>()
const emit = defineEmits(["reply", "child"])

const Router = useRouter()

const UserStore = useUserStore()
const Platform = usePlatformStore()
const { id: UserID, roles } = storeToRefs(UserStore)

const count = ref(1 + (props.comment.children ? props.comment.childrenCount : 0))
const isParent = ref(props.comment.children ? true : false)
const children = ref(props.comment.children ? props.comment.children : [])
const isTempParent = ref(false)
const isChild = ref(props.comment.child ? props.comment.child : false)
const isDeleted = ref(props.comment.deleted ? props.comment.deleted : false)
const isBlocked = ref(props.comment.blocked ? props.comment.blocked : false)
const isFiltered = ref(props.comment.filtered ? props.comment.filtered : false)
const isLocked = ref(props.locked || props.comment.locked ? props.locked || props.comment.locked : false)
const canShare = ref(false)
const replying = ref(false)
const collapsed = ref(false)
const editMode = ref(false)
const html = ref(props.comment.html)
const reacted = ref(props.comment.reacted)
const reactionCount = ref(props.comment.reactionCount)
const reactionData = ref(props.comment.reactionData || undefined)
const reacting = ref(false)
const cooldown = ref(false)
const self = ref(props.comment.self)
const reactionsOpen = ref(false)
const isFetching = ref(false)
const direction = ref("below")
const threadPageInfo = ref<any>(props.comment.threadPageInfo || {
  hasPreviousPage: false,
  hasNextPage: false,
  startCursor: undefined,
  endCursor: undefined
})

const processedContent = computed(() => {
  if (!html.value) return ""

  const tempDiv = document.createElement('div')
  tempDiv.innerHTML = html.value

  // Find all mention spans
  const mentions = tempDiv.querySelectorAll('span[data-type="mention"]')

  // Replace each mention span with a special anchor tag
  mentions.forEach(mention => {
    const username = mention.getAttribute('data-label')
    if (username) {
      const anchor = document.createElement('a')
      // Set actual URL in href
      anchor.setAttribute('href', `/${username}`)
      anchor.setAttribute('class', 'mention')
      // Add click prevention
      anchor.setAttribute('onclick', 'event.preventDefault()')
      anchor.setAttribute('data-username', username)
      anchor.textContent = `@${username}`
      mention.parentNode?.replaceChild(anchor, mention)
    }
  })

  return tempDiv.innerHTML
})

// Add click handler function
function handleMentionClick(event: MouseEvent) {
  const target = event.target as HTMLElement
  if (target.classList.contains('mention')) {
    const username = target.getAttribute('data-username')
    if (username) {
      Router.push(`/${username}`)
    }
  }
}

const shareModalOpen = ref(false)

function toggleShareModal() {
  shareModalOpen.value = !shareModalOpen.value
}

const commentLink = computed(() => {
  const windowLocation = window.location.href
  const commentId = props.comment._id
  // check if windowLocation already has a hash
  if (windowLocation.includes("#")) {
    return `${windowLocation.split("#")[0]}#${commentId}`
  } else {
    return `${windowLocation}#${commentId}`
  }
})

const deletedLabel = computed(() => {
  if (isDeleted.value && !props.comment.deletedByType) {
    return "[ Deleted ]"
  }
  if (isDeleted.value && props.comment.deletedByType === "admin") {
    return "[ Deleted by Submit Moderation ]"
  }
  if (isDeleted.value && props.comment.deletedByType === "contentAuthor" && (props.comment.forType !== "event" || props.comment.forType !== "group")) {
    return "[ Deleted by Content Author ]"
  }
  if (isDeleted.value && props.comment.deletedByType === "eventOrganizer" && props.comment.forType === "event") {
    return "[ Deleted by Event Organizers ]"
  }
  if (isDeleted.value && props.comment.deletedByType === "groupModerator" && props.comment.forType === "group") {
    return "[ Deleted by Group Moderators ]"
  }
  if (isDeleted.value && props.comment.deletedByType === "commentAuthor") {
    return "[ Deleted by Comment Author ]"
  }
})

const Locked = computed(() => {
  if (isLocked.value) {
    return true
  } else if (props.group) {
    if (isLocked.value) return true
    return !props.group.moderation.commentingEnabled ? !props.group.moderation.commentingEnabled : false
  } else {
    return false
  }
})

const canLockThreads = computed(() => {
  if (isLocked.value) {
    return false
  }
  if (isChild.value) {
    return false
  }
  if (UserID.value === props.comment.forAuthor && !props.group) {
    return true
  }
  if (roles.value.includes("admin")) {
    return true
  }
  if (props.group && (props.group.isOwner || props.group.isModerator)) {
    return true
  }
  return false
})

const canDeleteComment = computed(() => {
  if (isDeleted.value) {
    return false
  }
  if (self.value) {
    return true
  }
  if (UserID.value === props.comment.forAuthor  && !props.group) {
    return true
  }
  if (roles.value.includes("admin")) {
    return true
  }
  if (props.group && (props.group.isOwner || props.group.isModerator)) {
    return true
  }
  return false
})

const toggleReactionsOpen = async () => {
  reactionsOpen.value = !reactionsOpen.value
}

function toggleReply() {
  if (props.child) {
    emit("reply")
  }
  replying.value = !replying.value
  if (replying.value && !isChild.value && !props.child) {
    isTempParent.value = true
  } else {
    isTempParent.value = false
  }
}

function handlePublish(comment: any) {
  count.value++
  children.value.push(comment)
  if (!isParent.value) {
    isParent.value = true
  }
  toggleReply()
  emit("child")
}

async function react() {
  if (reacting.value || cooldown.value) return
  if (props.group && (!props.group.isMember && props.group.type === 'closed')) return
  if (reacted.value) {
    const path = `/reactions/comments/${reactionData.value._id}`
    try {
      reacting.value = true
      await API().delete(path)
      reacted.value = false
      reactionCount.value--
      reacting.value = false
      cooldown.value = true
      setTimeout(() => {
        cooldown.value = false
      }, 3000)
    } catch (err) {
      console.error(err)
      reacting.value = false
      return
    }
  } else {
    reacting.value = true
    const path = `/reactions`
    let url = `/${props.comment.forAuthor}/${props.comment.forType}/${props.comment.for}#${props.comment._id}`
    if (props.group && props.short) {
      url = `/groups/${props.group.slug}/${props.short}#${props.comment._id}`
    }
    if (props.event && props.short) {
      url = `/e/${props.short}#${props.comment._id}`
    }
    const reaction = {
      type: "love",
      contentAuthor: props.comment.author._id,
      contentId: props.comment._id,
      contentType: "comments",
      url: url
    }
    try {
      const response = await API().post(path, reaction)
      reactionData.value = response.data.data
      reacted.value = true
      reactionCount.value++
      reacting.value = false
      cooldown.value = true
      setTimeout(() => {
        cooldown.value = false
      }, 3000)
    } catch (err) {
      console.error(err)
      reacting.value = false
      return
    }
  }
}

async function fetchThreadComments(dir: string) {
  if (isFetching.value) return
  direction.value = dir
  isFetching.value = true
  const cursor = dir === "above" ? threadPageInfo.value.startCursor : threadPageInfo.value.endCursor
  const response = await fetch(`${import.meta.env.PUBLIC_API}/comments/v2/thread/${props.comment.forType}/${props.comment._id}?cursor=${cursor}&direction=${direction.value}`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json"
    }
  })
  try {
    const response = await API().get(`/comments/v2/thread/${props.comment.forType}/${props.comment._id}?cursor=${cursor}&direction=${direction.value}`)
    isFetching.value = false
    const data = response.data
    if (direction.value === "above") {
      data.data.reverse()
      children.value.unshift(...data.data)
      threadPageInfo.value.startCursor = data.threadPageInfo.endCursor
      threadPageInfo.value.hasPreviousPage = data.threadPageInfo.hasPreviousPage
    } else {
      children.value.push(...data.data)
      threadPageInfo.value.endCursor = data.threadPageInfo.endCursor
      threadPageInfo.value.hasNextPage = data.threadPageInfo.hasNextPage
    }
  } catch (err) {
    console.error(err)
    isFetching.value = false
  }
}

function updateComment(content: string) {
  html.value = content
  editMode.value = false
}

async function lockComment() {
  try {
    await API().get(`/comments/${props.comment.forType}/${props.comment._id}/lock`)
    isLocked.value = true
  } catch (err) {
    console.error(err)
  }
}

async function deleteComment() {
  if (editMode.value) {
    editMode.value = false
  }
  try {
    await API().delete(`/comments/${props.comment.forType}/${props.comment._id}`)
    isDeleted.value = true
  } catch (err) {
    console.error(err)
  }
}

async function clickToCopy(text: string, toggleModal: boolean = true) {
  await navigator.clipboard.writeText(text)
  if (toggleModal) toggleShareModal()
}

function openReportDialog(author: string, item: string) {
  Platform.toggleReportOpen()
  const reportData: any = {
    userId: author,
    contentId: item,
    contentType: "comment",
    url: commentLink.value
  }
  if (props.group) {
    reportData.contentType = "group:comment"
    reportData.group = props.group._id
  }
  Platform.setReportData(reportData)
}
</script>

<template>
  <li :id="comment._id" :style="`--nested: ${isParent};`">
    <div class="comment" :class="{ 'comment--parent': isParent || isTempParent, 'comment--child': isChild, 'collapsed': collapsed }">
      <a :href="isBlocked ? '#' : `/${comment.author.username}`" class="pfp-container">
        <figure class="flex items-center justify-center bg-white dark:bg-submit-900 rounded-full bg-cover" :class="isChild ? 'w-9 h-9' : 'w-10 h-10'" :style="comment.author.pfp ? `background-image: url('${comment.author.pfp}')` : ''">
          <div v-if="!comment.author.pfp || !isBlocked" class="flex items-center justify-center w-full h-full font-semibold text-black dark:text-gray-400 dark:hover:text-white" :class="isChild ? 'text-xl' : 'text-2xl'">
            <span v-if="comment.author.username !== 'Deleted' && !comment.author.pfp">{{ comment.author.firstLetter }}</span>
            <svg v-if="comment.author.username === 'Deleted' && isDeleted" class="h-5 dark:fill-gray-400 transition-all" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>
          </div>
          <svg v-else class="h-5 dark:fill-gray-400 transition-all" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M367.2 412.5L99.5 144.8C77.1 176.1 64 214.5 64 256c0 106 86 192 192 192c41.5 0 79.9-13.1 111.2-35.5zm45.3-45.3C434.9 335.9 448 297.5 448 256c0-106-86-192-192-192c-41.5 0-79.9 13.1-111.2 35.5L412.5 367.2zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z"/></svg>
        </figure>
      </a>
      <div class="header-container">
        <div class="self-center">
          <span class="flex items-center flex-row gap-2" :class="comment.selected ? 'text-gold-700 dark:text-gold-500' : ''">
            <span v-if="isDeleted" class="break-word font-semibold">{{ comment.author.username }}</span>
            <a v-else class="break-word font-semibold dark:hover:text-white" :href="isBlocked ? '#' : `/${comment.author.username}`">{{ isBlocked ? "Blocked" : comment.author.username }}</a>
            <svg v-if="comment.author.staff" class="dark:fill-gold-500 fill-gold-700" height="14" viewBox="0 0 118 143" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.82812 110.309L90.5094 22.6273C94.4146 18.7221 100.746 18.7221 104.652 22.6273L114.551 32.5268L26.8698 120.208C22.9645 124.113 16.6329 124.113 12.7276 120.208L2.82812 110.309Z" />
              <path d="M7.07107 74.9533C3.16583 71.048 3.16582 64.7164 7.07107 60.8111L60.8112 7.07102C64.7164 3.16577 71.0481 3.16577 74.9533 7.07101L77.7817 9.89944C81.687 13.8047 81.687 20.1363 77.7817 24.0416L16.9706 84.8528L7.07107 74.9533Z" />
              <path d="M39.5979 132.936C35.6927 129.031 35.6927 122.699 39.5979 118.794L100.409 57.9827L110.309 67.8822C114.214 71.7875 114.214 78.1191 110.309 82.0244L56.5685 135.764C52.6632 139.67 46.3316 139.67 42.4264 135.764L39.5979 132.936Z" />
            </svg>
            <svg v-if="isBlocked && !isDeleted" class="h-3 dark:fill-red-500 transition-all" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M367.2 412.5L99.5 144.8C77.1 176.1 64 214.5 64 256c0 106 86 192 192 192c41.5 0 79.9-13.1 111.2-35.5zm45.3-45.3C434.9 335.9 448 297.5 448 256c0-106-86-192-192-192c-41.5 0-79.9 13.1-111.2 35.5L412.5 367.2zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z"/></svg>
            <svg v-if="isDeleted" class="h-3 dark:fill-red-500 transition-all" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>
            <svg v-if="isFiltered" class="h-3 dark:fill-yellow-500 transition-all" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/></svg>
            <svg v-if="!isChild && isLocked" class="h-3 dark:fill-gold-500 fill-gold-700 transition-all" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z"/></svg>
            <span class="cursor-pointer" @click="collapsed = !collapsed" v-if="!comment.child">
              <svg class="h-3 dark:fill-gray-500 transition-all" :class="{ '-rotate-180': collapsed }" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M239 111c9.4-9.4 24.6-9.4 33.9 0L465 303c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-175-175L81 337c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9L239 111z"/></svg>
            </span>
            <span class="text-xs text-gray-500" v-if="!comment.child && collapsed">({{ count }})</span>
          </span>
        </div>
        <div class="flex relative flex-row items-center self-center gap-x-3 dark:text-gray-400">
          <span :title="D(comment.createdAt).format('LLLL')">{{ D(comment.createdAt).locale('submit').fromNow() }}</span>
          <Menu v-if="(!isDeleted && !isBlocked) || roles.includes('admin')">
            <MenuButton>
              <div class="group hover:cursor-pointer">
                <svg class="w-4 dark:fill-gray-400 dark:group-hover:fill-white" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M432 256a48 48 0 1 1 -96 0 48 48 0 1 1 96 0zm-160 0a48 48 0 1 1 -96 0 48 48 0 1 1 96 0zM64 304a48 48 0 1 1 0-96 48 48 0 1 1 0 96z"/></svg>
              </div>
            </MenuButton>
            <transition
              enter-active-class="transition duration-100 ease-out"
              enter-from-class="transform scale-95 opacity-0"
              enter-to-class="transform scale-100 opacity-100"
              leave-active-class="transition duration-75 ease-in"
              leave-from-class="transform scale-100 opacity-100"
              leave-to-class="transform scale-95 opacity-0"
            >
              <MenuItems class="origin-top-right divide-y divide-indigo-350 absolute top-6 right-0 w-36 rounded-md shadow-lg bg-neutral-125 dark:bg-submit-900 ring-1 ring-transparent ring-opacity-5 focus:outline-none z-50">
                <div class="p-1">
                  <MenuItem v-slot="{ active, close }" v-if="self">
                    <button
                    type="button"
                    @click="clickToCopy(commentLink, false), close()"
                    :class="[
                      active ? 'bg-yellow-400/10' : '',
                      'flex items-center rounded-md w-full px-4 py-2 text-sm text-neutral-500 dark:text-neutral-300'
                    ]"
                    >
                    <svg class="w-3 mr-3 dark:fill-gray-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M498.1 5.6c10.1 7 15.4 19.1 13.5 31.2l-64 416c-1.5 9.7-7.4 18.2-16 23s-18.9 5.4-28 1.6L284 427.7l-68.5 74.1c-8.9 9.7-22.9 12.9-35.2 8.1S160 493.2 160 480V396.4c0-4 1.5-7.8 4.2-10.7L331.8 202.8c5.8-6.3 5.6-16-.4-22s-15.7-6.4-22-.7L106 360.8 17.7 316.6C7.1 311.3 .3 300.7 0 288.9s5.9-22.8 16.1-28.7l448-256c10.7-6.1 23.9-5.5 34 1.4z"/></svg>
                      Copy Link
                    </button>
                  </MenuItem>
                  <MenuItem v-slot="{ active }" v-if="self">
                    <button
                    type="button"
                    @click="editMode = !editMode"
                    :class="[
                      active ? 'bg-yellow-400/10' : '',
                      'flex items-center rounded-md w-full px-4 py-2 text-sm text-neutral-500 dark:text-neutral-300'
                    ]"
                    >
                    <svg class="w-3 mr-3 dark:fill-gray-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"/></svg>
                      Edit
                    </button>
                  </MenuItem>
                  <MenuItem v-slot="{ active }" v-if="canLockThreads">
                    <button
                    type="button"
                    @click="lockComment()"
                    :class="[
                      active ? 'bg-yellow-400/10' : '',
                      'flex items-center rounded-md w-full px-4 py-2 text-sm text-neutral-500 dark:text-neutral-300'
                    ]"
                    >
                    <svg class="w-3 mr-3 dark:fill-gray-300" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z"/></svg>
                      Lock
                    </button>
                  </MenuItem>
                  <MenuItem v-slot="{ active }" v-if="canDeleteComment">
                    <button
                    type="button"
                    @click="deleteComment()"
                    :class="[
                      active ? 'bg-yellow-400/10' : '',
                      'flex items-center rounded-md w-full px-4 py-2 text-sm text-neutral-500 dark:text-neutral-300'
                    ]"
                    >
                    <svg class="w-3 mr-3 dark:fill-gray-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"/></svg>
                      Delete
                    </button>
                  </MenuItem>
                  <MenuItem v-slot="{ active }" v-if="!self">
                    <button
                      type="button"
                      @click="openReportDialog(comment.author._id, comment._id)"
                      :class="[
                        active ? 'bg-yellow-400/10' : '',
                        'flex items-center rounded-md w-full px-4 py-2 text-sm text-neutral-500 dark:text-neutral-300'
                      ]"
                    >
                    <svg class="w-3 mr-3 dark:fill-gray-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/></svg>
                      Report
                    </button>
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </div>
      <div class="line-container" v-if="isParent || isTempParent"></div>
      <div class="mt-1 body-container break-words CommonBody" v-if="!editMode && !isDeleted && !isBlocked" v-html="processedContent" @click="handleMentionClick"></div>
      <div class="mt-1 body-container" v-if="isBlocked && !isDeleted">[ Blocked ]</div>
      <div class="mt-1 body-container" v-if="isDeleted">{{ deletedLabel }}</div>
      <div class="mt-1 body-container" v-if="editMode">
        <NewComment :forGroup="group ? group._id : undefined" :forAuthor="comment.forAuthor" :locked="Locked" :canComment="canComment" :forAudience="forAudience" @cancel="editMode = false" @update="updateComment" :comment="{...comment, html: html}" :edit="true" />
      </div>
      <div class="mt-2 footer-container" v-if="!editMode">
        <div class="flex gap-x-4">
          <div title="Love Comment" v-if="!self">
            <svg @click="isBlocked || isDeleted ? null : react()" v-if="reacted" class="h-5 fill-red-500 cursor-pointer" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M225.8 468.2l-2.5-2.3L48.1 303.2C17.4 274.7 0 234.7 0 192.8v-3.3c0-70.4 50-130.8 119.2-144C158.6 37.9 198.9 47 231 69.6c9 6.4 17.4 13.8 25 22.3c4.2-4.8 8.7-9.2 13.5-13.3c3.7-3.2 7.5-6.2 11.5-9c0 0 0 0 0 0C313.1 47 353.4 37.9 392.8 45.4C462 58.6 512 119.1 512 189.5v3.3c0 41.9-17.4 81.9-48.1 110.4L288.7 465.9l-2.5 2.3c-8.2 7.6-19 11.9-30.2 11.9s-22-4.2-30.2-11.9zM239.1 145c-.4-.3-.7-.7-1-1.1l-17.8-20c0 0-.1-.1-.1-.1c0 0 0 0 0 0c-23.1-25.9-58-37.7-92-31.2C81.6 101.5 48 142.1 48 189.5v3.3c0 28.5 11.9 55.8 32.8 75.2L256 430.7 431.2 268c20.9-19.4 32.8-46.7 32.8-75.2v-3.3c0-47.3-33.6-88-80.1-96.9c-34-6.5-69 5.4-92 31.2c0 0 0 0-.1 .1s0 0-.1 .1l-17.8 20c-.3 .4-.7 .7-1 1.1c-4.5 4.5-10.6 7-16.9 7s-12.4-2.5-16.9-7z"/></svg>
            <svg @click="isBlocked || isDeleted ? null : react()" v-else class="h-5" :class="isBlocked || isDeleted ? 'dark:fill-gray-700 fill-gray-300 cursor-not-allowed' : 'dark:fill-gray-400 fill-gray-500 cursor-pointer dark:hover:fill-white hover:fill-black'" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M225.8 468.2l-2.5-2.3L48.1 303.2C17.4 274.7 0 234.7 0 192.8v-3.3c0-70.4 50-130.8 119.2-144C158.6 37.9 198.9 47 231 69.6c9 6.4 17.4 13.8 25 22.3c4.2-4.8 8.7-9.2 13.5-13.3c3.7-3.2 7.5-6.2 11.5-9c0 0 0 0 0 0C313.1 47 353.4 37.9 392.8 45.4C462 58.6 512 119.1 512 189.5v3.3c0 41.9-17.4 81.9-48.1 110.4L288.7 465.9l-2.5 2.3c-8.2 7.6-19 11.9-30.2 11.9s-22-4.2-30.2-11.9zM239.1 145c-.4-.3-.7-.7-1-1.1l-17.8-20c0 0-.1-.1-.1-.1c0 0 0 0 0 0c-23.1-25.9-58-37.7-92-31.2C81.6 101.5 48 142.1 48 189.5v3.3c0 28.5 11.9 55.8 32.8 75.2L256 430.7 431.2 268c20.9-19.4 32.8-46.7 32.8-75.2v-3.3c0-47.3-33.6-88-80.1-96.9c-34-6.5-69 5.4-92 31.2c0 0 0 0-.1 .1s0 0-.1 .1l-17.8 20c-.3 .4-.7 .7-1 1.1c-4.5 4.5-10.6 7-16.9 7s-12.4-2.5-16.9-7z"/></svg>
          </div>
          <div @click="isBlocked || isDeleted || Locked ? null : toggleReply()" title="Reply to Comment">
            <svg class="h-5" :class="isBlocked || isDeleted || Locked ? 'dark:fill-gray-700 fill-gray-300 cursor-not-allowed' : 'dark:fill-gray-400 fill-gray-500 cursor-pointer dark:hover:fill-white hover:fill-black'" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M224 240h96c66.2 0 122 44.7 138.8 105.5c3.3-12.4 5.2-26.2 5.2-41.5c0-70.7-57.3-128-128-128H224 200c-13.3 0-24-10.7-24-24V128 99.9L55.9 208 176 316.1V288 264c0-13.3 10.7-24 24-24h24zm0 48v48 16c0 12.6-7.4 24.1-19 29.2s-25 3-34.4-5.4l-160-144C3.9 225.7 0 217.1 0 208s3.9-17.7 10.6-23.8l160-144c9.4-8.5 22.9-10.6 34.4-5.4s19 16.6 19 29.2V80v48h48 64c97.2 0 176 78.8 176 176c0 78-38.6 126.2-68.7 152.1c-4.1 3.5-8.1 6.6-11.7 9.3c-3.2 2.4-6.2 4.4-8.9 6.2c-4.5 3-8.3 5.1-10.8 6.5c-2.5 1.4-5.3 1.9-8.1 1.9c-10.9 0-19.7-8.9-19.7-19.7c0-6.8 3.6-13.2 8.3-18.1c.5-.5 .9-.9 1.4-1.4c2.4-2.3 5.1-5.1 7.7-8.6c1.7-2.3 3.4-5 5-7.9c5.3-9.7 9.5-22.9 9.5-40.2c0-53-43-96-96-96H272 224z"/></svg>
          </div>
          <button @click="toggleShareModal" type="button" title="Share Comment" :disabled="isBlocked || isDeleted || Locked">
            <svg class="h-5" :class="isBlocked || isDeleted || Locked ? 'dark:fill-gray-700 fill-gray-300 cursor-not-allowed' : 'dark:fill-gray-400 fill-gray-500 cursor-pointer dark:hover:fill-white hover:fill-black'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M498.1 5.6c10.1 7 15.4 19.1 13.5 31.2l-64 416c-1.5 9.7-7.4 18.2-16 23s-18.9 5.4-28 1.6L284 427.7l-68.5 74.1c-8.9 9.7-22.9 12.9-35.2 8.1S160 493.2 160 480V396.4c0-4 1.5-7.8 4.2-10.7L331.8 202.8c5.8-6.3 5.6-16-.4-22s-15.7-6.4-22-.7L106 360.8 17.7 316.6C7.1 311.3 .3 300.7 0 288.9s5.9-22.8 16.1-28.7l448-256c10.7-6.1 23.9-5.5 34 1.4z"/></svg>
          </button>
          <div title="Repost Comment" v-if="canShare">
            <svg class="h-5" :class="isBlocked || isDeleted ? 'dark:fill-gray-700 fill-gray-300 cursor-not-allowed' : 'dark:fill-gray-400 cursor-pointer dark:hover:fill-white hover:fill-black'" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M120 208h31c22.6 0 41-18.3 41-41c0-10.9-4.3-21.3-12-29L113 71c-4.5-4.5-10.6-7-17-7s-12.5 2.5-17 7l-67 67c-7.7 7.7-12 18.1-12 29c0 22.6 18.3 41 41 41H72V328c0 48.6 39.4 88 88 88H296c13.3 0 24-10.7 24-24s-10.7-24-24-24H160c-22.1 0-40-17.9-40-40V208zM96 121.9L134.1 160l-76.1 0L96 121.9zM504 304V184c0-48.6-39.4-88-88-88H280c-13.3 0-24 10.7-24 24s10.7 24 24 24H416c22.1 0 40 17.9 40 40V304H425c-22.6 0-41 18.3-41 41c0 10.9 4.3 21.3 12 29l67 67c4.5 4.5 10.6 7 17 7s12.5-2.5 17-7l67-67c7.7-7.7 12-18.1 12-29c0-22.6-18.3-41-41-41H504zm-24 86.1L441.9 352h76.1L480 390.1z"/></svg>
          </div>
        </div>
        <div @click="toggleReactionsOpen" class="mt-2 text-sm text-gray-500 hover:underline hover:cursor-pointer" v-if="reactionCount > 0">{{ reactionCount }} {{ reactionCount === 1 ? "Love" : "Loves" }}</div>
        <ReactionList :content="{ contentType: 'comments', contentId: comment._id }" :toggle="toggleReactionsOpen" :open="reactionsOpen" v-if="reactionsOpen" />
      </div>
      <ul class="mt-4 children-container space-y-4" v-if="isParent || isTempParent">
        <li tabindex="0" class="cursor-pointer py-2" @click="fetchThreadComments('above')" v-if="threadPageInfo.hasPreviousPage && !isFetching">Load more comments</li>
        <li class="animate-pulse py-2" v-if="isFetching && direction === 'above'">Loading more...</li>
        <Comment @reply="toggleReply" :group="group" :event="event" :short="short" :canComment="canComment" :forAudience="forAudience" v-if="children.length > 0" :locked="Locked" :comment="child" v-for="child in children" :key="child._id" :child="true" />
        <li tabindex="0" class="cursor-pointer py-2" @click="fetchThreadComments('below')" v-if="threadPageInfo.hasNextPage && !isFetching">Load more comments</li>
        <li class="animate-pulse py-2" v-if="isFetching && direction === 'below'">Loading more...</li>
        <NewComment :forGroup="group ? group._id : undefined" :forAuthor="comment.forAuthor" :locked="Locked" :canComment="canComment" :forAudience="forAudience" :content="comment.for" :type="comment.forType" @keyup.esc="toggleReply" @publish="handlePublish" :user="comment.author" :parent="comment._id" :reply="true" @cancel="toggleReply" v-if="replying" />
      </ul>
    </div>
    <TransitionRoot appear :show="shareModalOpen" as="template">
      <Dialog as="div" @close="toggleShareModal" class="relative z-[100]">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>
        <div class="fixed inset-0 overflow-y-auto">
          <div
            class="flex min-h-full items-center justify-center p-2 sm:p-4 text-center"
          >
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel
                class="w-full max-w-lg transform overflow-hidden rounded-2xl bg-neutral-125 dark:bg-submit-925 px-2 xs:px-4 py-6 sm:px-6 sm:py-6 text-left align-middle shadow-xl transition-all"
              >
                <DialogTitle
                  as="h3"
                  class="text-lg font-bold leading-6 text-black dark:text-white mb-4"
                >
                  Share Comment
                </DialogTitle>
                <div class="flex flex-col">
                  <div @click="clickToCopy(commentLink)" class="text-sm p-4 rounded-md bg-white dark:bg-submit-950 break-modern cursor-pointer">
                    {{ commentLink }}
                  </div>
                  <p class="text-xs pl-4 mt-1">Click/tap to copy and close this window.</p>
                  <div class="flex self-end">
                    <button
                      type="button"
                      @click="toggleShareModal"
                      class="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-gray-500 dark:text-gray-400 hover:text-black hover:bg-white dark:hover:bg-submit-500 dark:hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 mr-2"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </li>
</template>
