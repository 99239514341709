<script setup lang="ts">
import { ref, reactive, inject, onMounted, type Ref, type Reactive } from "vue"
import DatePicker from "@/components/input/Date.vue"
import { getSignals } from "@/composables/Sessions"
import Field from "@/components/input/Field.vue"
import Label from "@/components/input/Label.vue"
import Combo from "@/components/input/Combo.vue"
import Form from "@/components/input/Form.vue"
import PronounOptions from "@/data/Pronouns"
import D from "@/composables/TimeDisplay"
import RoleOptions from "@/data/Roles"
import API from "@/api/api"
import * as zod from "zod"

const initialValuesData = inject<Reactive<any>>("initialValues")
const initialValues = reactive({
  email: initialValuesData.email,
  username: initialValuesData.username || "",
  password: undefined,
  dob: null,
  roles: undefined,
  pronoun: undefined,
  terms: false,
  age: false
})

const challenge = inject<Ref<string>>("challenge")
const challengeExpiry = inject<Ref<Date>>("challengeExpiry")
const challengeHasExpired = inject<Ref<boolean>>("challengeHasExpired")
const code = inject<Ref<string>>("code")

const setIsLoading = inject('setIsLoading') as (value: boolean) => void
const sessionId = inject('sessionId') as Ref<string | undefined>

const schema = zod.object({
  email: zod.string()
    .min(1, { message: "This is required." }),
  username: zod.string()
    .min(4, { message: "Can be between 3 and 24 characters." } )
    .max(24, { message: "Can be between 3 and 24 characters." } )
    .regex(/^[a-zA-Z0-9_-]+$/, { message: "Can only contain letters, numbers, underscores, and dashes." }),
  password: zod.string()
    .min(8, { message: "Must be at least 8 characters." })
    .max(64, { message: "Must be less than 64 characters." }),
  dob: zod.date({
    invalid_type_error: "Date of Birth is required.",
  })
    .min(new Date(D().subtract(100, 'year').toString()), { message: "100 is the maximum age supported." })
    .max(new Date(D().subtract(18, 'year').toString()), { message: "You must be 18 years or older." }),
  roles: zod.string({
    invalid_type_error: "A role is required."
  })
    .min(1, { message: "This is required." }),
  pronoun: zod.string({
    invalid_type_error: "A pronoun (or 'none') is required."
  })
    .min(1, { message: "This is required." })
})

const debug = ref(false)
const working = ref<boolean>(false)
const view = ref<string>("create")

const error = ref<boolean>(false)

const done = ref<boolean>(false)

const formStartTime = ref<number | null>(null)
const lastKeyPressTime = ref<number | null>(null)
const keyPressIntervals = ref<number[]>([])
const mouseMovements = ref<{ x: number, y: number, timestamp: number }[]>([])
const formFocusTime = ref<number>(0)

onMounted(() => {
  trackFormBehavior('form_start')
})

const trackFormBehavior = (event: string, data?: any) => {
  const timestamp = Date.now()

  switch (event) {
    case 'form_start':
      formStartTime.value = timestamp
      break
    case 'key_press':
      if (lastKeyPressTime.value) {
        keyPressIntervals.value.push(timestamp - lastKeyPressTime.value)
      }
      lastKeyPressTime.value = timestamp
      break
    case 'mouse_move':
      mouseMovements.value.push({
        x: data.x,
        y: data.y,
        timestamp
      })
      break
  }
}

async function solvePow(values: any) {
  if (!challenge!.value) return
  // Set loading state and wait for animation
  setIsLoading(true)
  // Add a delay to match the loading animation duration (600ms + some buffer)
  await new Promise(resolve => setTimeout(resolve, 700))

  const timeToSolve = ref(0)
  const nonce = ref<string>('')
  const difficulty = ref(0)
  const attempts = ref(0)

  attempts.value++
  let worker: Worker | null = null

  try {
    worker = new Worker(
      new URL('../../workers/pow.worker.ts', import.meta.url),
      { type: 'module' }
    )

    const result = await new Promise((resolve, reject) => {
      if (!worker) return reject(new Error('Worker not initialized'))

      worker.onmessage = (e) => {
        if (e.data.error) {
          console.error(e.data.error)
          reject(e.data.error)
          return
        }

        if (e.data.success) {
          const result = e.data.data
          timeToSolve.value = result.timeToSolve
          nonce.value = result.nonce
          difficulty.value = result.difficulty
          resolve(result)
        }
      }

      worker.onerror = (error) => {
        console.error('Worker error:', error)
        reject(error)
      }

      worker.postMessage({ challenge: challenge!.value })
    })

    return result
  } catch (error) {
    console.error('Error:', error)
    return null
  } finally {
    if (worker) {
      worker.terminate()
    }

    try {
      const data = {
        code: code!.value,
        pow: nonce.value,
        difficulty: difficulty.value,
        session: sessionId!.value,
        signals: getSignals(),
        ua: navigator.userAgent,
        b: {
          fct: formStartTime.value ? Date.now() - formStartTime.value : null,
          kpi: keyPressIntervals.value,
          mmt: mouseMovements.value,
          akpi: keyPressIntervals.value.length > 0
            ? keyPressIntervals.value.reduce((a, b) => a + b) / keyPressIntervals.value.length
            : null
        },
        user: {
          username: values.username,
          password: values.password,
          dob: values.dob,
          meta: {
            roles: [values.roles],
            pronoun: values.pronoun
          }
        }
      }

      await API(false).post("/register", data)

      done.value = true
      view.value = "done"
    } catch (err) {
      console.error(err)
      error.value = true
    }

    // Add a small delay before removing loading state to ensure smooth transition
    await new Promise(resolve => setTimeout(resolve, 100))
    setIsLoading(false)
  }
}
</script>

<template>
  <section v-if="view === 'create'">
    <h1 class="font-display text-2xl dark:text-white font-bold mb-6">{{ $t("signup") }}</h1>
    <p v-if="challengeHasExpired" class="text-sm mb-8 leading-6 text-red-500 font-bold">Your sign-up session has expired. Please refresh this page to generate a new one.</p>
    <Form
      @on-submit="solvePow"
      :schema="schema"
      :initial-values="initialValues"
      @mousemove="trackFormBehavior('mouse_move', { x: $event.clientX, y: $event.clientY })"
      v-slot="{ fields, errors, tools, steps }"
      :steps="3"
      class="space-y-3">
      <template v-if="steps.currentStep === 1">
        <div>
          <Label field="email">Email</Label>
          <Field type="input" subtype="email" name="email" :step="1" help-text="The email address you verified in the previous step, obfuscated for your privacy." persist locked />
        </div>
        <div>
          <Label field="username">Username</Label>
          <Field @keypress="trackFormBehavior('key_press')" name="username" :step="1" persist help-text="Tips: Be creative and authentic! Avoid all caps or long strings of numbers. Make sure your username doesn't reference any prohibited content." />
        </div>
      </template>
      <template v-if="steps.currentStep === 2">
        <div>
          <Label field="password">Password</Label>
          <Field @keypress="trackFormBehavior('key_press')" type="password" name="password" :step="2" persist />
        </div>
        <div>
          <Label field="dob">Date of Birth</Label>
          <DatePicker name="dob" :step="2" persist help-text="Please enter your actual date of birth, using fake dates could result in your account being disabled or removed. Once you've created your account, you can adjust your privacy settings to hide your age (your actual date of birth is never shared with anyone)." />
        </div>
      </template>
      <template v-if="steps.currentStep === 3">
        <div>
          <Label field="roles">Role</Label>
          <Combo name="roles" :options="RoleOptions" :step="3" help-text="You can add additional roles in your profile settings." persist />
        </div>
        <div>
          <Label field="pronoun">Pronoun</Label>
          <Combo name="pronoun" :options="PronounOptions" :step="3" help-text="Selecting 'none' opts out of displaying a pronoun." persist />
        </div>
      </template>
      <div class="flex gap-4">
        <button type="button" class="flex peer/prev basis-1/2 mt-6 py-4 font-display font-bold text-sm justify-center" @click="tools.previousStep" v-if="steps.steps > 1 && steps.currentStep !== 1">
          Previous
        </button>
        <button class="flex basis-full peer-[]/prev:basis-1/2 mt-6 py-4 font-display font-bold text-sm rounded-md justify-center bg-gold-700/90 hover:bg-gold-700 dark:bg-gold-500/90 hover:dark:bg-gold-500 text-black" type="submit" :disabled="working">
          {{
          steps.steps > 1 && steps.currentStep !== steps.steps ?
          'Next' :
          working ? 'Creating...' : 'Create Account'
          }}
        </button>
      </div>
      <div v-if="debug">
        <pre>
{{ fields }}
        </pre>
        <pre>
{{ errors }}
        </pre>
        <pre>
{{ steps }}
        </pre>
      </div>
      <div v-if="error" class="mt-6 transition-all text-center text-xs rounded-md bg-red-800 px-6 py-4">
        <h2 class="font-bold">
          <span class="text-white">Error</span>
        </h2>
        <p class="text-[#FF9595]">
          <span>There was an error creating your account, please try again.</span>
        </p>
      </div>
    </Form>
  </section>
  <section v-if="done && view === 'done'">
    <h1 class="font-display text-2xl dark:text-white font-bold mb-6">Sign Up</h1>
    <p class="text-sm mb-8 leading-6"><strong>You're all set!</strong> Your Submit account has been created, sign in to get started.</p>
    <router-link to="/signin" class="block cursor-pointer text-center mt-12 font-display font-semibold rounded-md dark:bg-submit-600 dark:hover:bg-submit-500 bg-white py-4 w-full">{{ $t("signin") }}</router-link>
  </section>
</template>
