<script setup lang="ts">
import Checkbox from "@/components/input/Checkbox.vue"
import Label from "@/components/input/Label.vue"
import Field from "@/components/input/Field.vue"
import Form from "@/components/input/Form.vue"
import { useUserStore } from "@/stores/User"
import { ref, watch, computed } from "vue"
import { storeToRefs } from "pinia"
import { validate } from "uuid"
import API from "@/api/api"
import * as zod from "zod"
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from "@headlessui/vue"

const User = useUserStore()
const { circles } = storeToRefs(User)

const props = defineProps<{
  open: boolean
  type: 'media' | 'writing'
  action: 'add' | 'remove' | undefined
  content: any
  collection: any
  toggleModal: () => void
}>()

const emits = defineEmits(['removed', 'added'])

const schema = zod.object({
  collection: zod.string().min(1),
  changeContentToCollectionVisibility: zod.boolean().optional()
})

const initialValues = {
  collection: props.collection ? props.collection._id : 'none',
  changeContentToCollectionVisibility: false
}

const working = ref(false)
const collections = ref<any[] | null>(null)
const fetchedCollections = ref(false)
const formSlot = ref<any>(null)
const collectionTitle = computed(() => {
  const cachedTitle = ref(props.collection ? props.collection.title : '')
  return cachedTitle.value
})
const collectionVisibilityName = computed(() => {
  if (!formSlot.value) return ''
  if (!fetchedCollections.value) return ''
  const collection = formSlot.value?.tools?.getFieldValue('collection')
  if (!collection || collection === 'none') return ''
  if (!collections.value) return ''
  const collectionData = collections.value.find((c) => c._id === collection)
  if (validate(collection.visibility)) {
    const circle = circles.value.find((c: any) => c.code === collection.visibility)
    if (circle) return circle.name
  }
  return collectionData.visibility
})

async function fetchCollections() {
  try {
    const response = await API().get(`/collections?type=${props.type}`)
    collections.value = response.data.data
    fetchedCollections.value = true
  } catch (err: any) {
    console.error(err)
  }
}

const warnings = computed(() => {
  if (!formSlot.value) return { mismatch: false, collectionOnly: false }
  if (!fetchedCollections.value) return { mismatch: false, collectionOnly: false }
  const collection = formSlot.value?.tools?.getFieldValue('collection')
  if (!collection || collection === 'none') return { mismatch: false, collectionOnly: false }
  if (!collections.value) return { mismatch: false, collectionOnly: false }
  const collectionData = collections.value.find((c) => c._id === collection)
  return {
    mismatch: collectionData.visibility !== props.content.visibility,
    collectionOnly: collectionData.collectionOnly
  }
})

const visibilityMismatchDisplay = computed(() => {
  if (!formSlot.value) return false
  if (!fetchedCollections.value) return false
  const collection = formSlot.value?.tools?.getFieldValue('collection')
  if (!collection || collection === 'none') return false
  if (!collections.value) return false
  const collectionData = collections.value.find((c) => c._id === collection)
  const collectionVisibility = collectionData.visibility
  switch (collectionVisibility) {
    case 'public':
      return 'Everyone'
    default:
      if (validate(collectionVisibility)) {
        const circle = circles.value.find((c: any) => c.code === collectionVisibility)
        if (circle) return `the ${circle.name} circle`
      }
      return collectionVisibility
  }
})

const matchCollectionVisibility = computed(() => {
  if (!formSlot.value) return false
  return formSlot.value?.tools?.getFieldValue('changeContentToCollectionVisibility')
})

async function addToCollection(values: any) {
  if (working.value) return
  working.value = true

  try {
    await API().post(`/collections/${values.collection}/add`, {
      content: props.content._id,
      contentType: props.type,
      changeContentToCollectionVisibility: values.changeContentToCollectionVisibility || false
    })
    emits('added', {
      collectionOnly: warnings.value.collectionOnly
    })
  } catch (err) {
    console.error(err)
    working.value = false
  }
}

async function removeFromCollection() {
  if (working.value) return
  working.value = true

  try {
    await API().post(`/collections/${props.collection._id}/remove`, {
      content: props.content._id,
      contentType: props.type
    })
    emits('removed')
    setTimeout(() => {
      initialValues.collection = 'none'
    }, 400)
  } catch (err) {
    console.error(err)
    working.value = false
  }
}

watch(() => props.open, (newVal) => {
  if (newVal) working.value = false
  if (newVal && props.action === 'add' && !fetchedCollections.value) {
    fetchCollections()
  }
})
</script>

<template>
  <TransitionRoot appear :show="open" as="template">
    <Dialog as="div" @close="toggleModal()" class="relative z-[100]">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-25" />
      </TransitionChild>
      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full max-w-md transform overflow-hidden rounded-2xl bg-neutral-125 dark:bg-submit-900 p-6 text-left align-middle shadow-xl transition-all"
            >
              <template v-if="action === 'add'">
                <DialogTitle
                  as="h3"
                  class="text-lg font-bold leading-6 text-black dark:text-white mb-4"
                >
                  Add to Collection
                </DialogTitle>
                <div class="flex flex-col mt-2 space-y-4">
                  <p class="text-sm">Choose a collection to add this {{ type === 'media' ? 'media' : 'writing' }} to.</p>
                  <Form
                    class="space-y-3"
                    :schema="schema"
                    :initial-values="initialValues"
                    @on-submit="addToCollection"
                    v-slot="slot"
                  >
                    <template v-if="formSlot = slot">
                      <Label field="collection">Collection</Label>
                      <Field name="collection" type="select" small alt>
                        <optgroup label="No Collection">
                          <option value="none">None</option>
                        </optgroup>
                        <optgroup label="Your Collections" v-if="collections && collections.length > 0">
                          <option :value="obj._id" v-for="obj in collections" :key="obj._id">{{obj.title}}</option>
                        </optgroup>
                      </Field>
                      <Checkbox name="changeContentToCollectionVisibility" v-if="warnings.mismatch">
                        Update the audience of this {{ type }} to the collection's audience ({{ visibilityMismatchDisplay }}).
                      </Checkbox>
                      <p class="text-xs dark:text-yellow-500 text-yellow-600" v-if="warnings.collectionOnly"><strong>CHANGE:</strong> All content in this collection is only viewable from the collection itself, this {{ type }} will not show up in your general {{ type === 'media' ? 'media' : 'writings' }} collection anymore.</p>
                      <p class="text-xs text-red-500" v-if="warnings.mismatch && !matchCollectionVisibility"><strong>WARNING:</strong> This {{ type }} is visible to {{ content.visibility === 'public' ? 'everyone' : content.visibility }}, but the collection it's being added to is only visible to {{ visibilityMismatchDisplay }}. If you don't update the media's audience to match the collection, the collection will become visible to everyone. This won't affect the audience of content already in the collection.</p>
                      <p class="text-xs text-red-500" v-if="warnings.mismatch && matchCollectionVisibility"><strong>WARNING:</strong> This {{ type }} is visible to {{ content.visibility === 'public' ? 'everyone' : content.visibility }}. It's audience will be updated to match the collection's audience: {{ visibilityMismatchDisplay }}. Any reactions or comments currently on the content that are from users who are not in the new audience will be removed.</p>
                      <div class="flex justify-end">
                        <button
                          type="button"
                          @click="toggleModal()"
                          class="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-gray-500 dark:text-gray-400 hover:text-black hover:bg-white dark:hover:bg-submit-500 dark:hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 mr-2"
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          class="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-gray-500 dark:text-gray-400 hover:text-black hover:bg-white dark:hover:bg-submit-500 dark:hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                          :disabled="working"
                        >
                          {{ working ? "Processing..." : "Add" }}
                        </button>
                      </div>
                    </template>
                  </Form>
                </div>
              </template>
              <template v-if="action === 'remove'">
                <DialogTitle
                  as="h3"
                  class="text-lg font-bold leading-6 text-black dark:text-white mb-4"
                >
                  Remove from Collection
                </DialogTitle>
                <div class="flex flex-col mt-2 space-y-4">
                  <p class="text-sm">Are you sure you want to remove this content from {{ collectionTitle }}? Removing content from a collection will not change its audience but it will be visible in your general {{ type === 'media' ? 'media' : 'writings' }} if it isn't already.</p>
                  <div class="flex self-end">
                    <button
                      type="button"
                      @click="toggleModal()"
                      class="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-gray-500 dark:text-gray-400 hover:text-black hover:bg-white dark:hover:bg-submit-500 dark:hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 mr-2"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      @click="removeFromCollection"
                      class="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-gray-500 dark:text-gray-400 hover:text-black hover:bg-white dark:hover:bg-submit-500 dark:hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      :disabled="working"
                    >
                      {{ working ? "Processing..." : 'Remove' }}
                    </button>
                  </div>
                </div>
              </template>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
