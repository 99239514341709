<script setup lang="ts">
import { computed, ref, provide, nextTick } from "vue"
import { useRoute } from "vue-router"
import ThemeSwitcher from './ThemeSwitcher.vue'
import LocaleSwitcher from './LocaleSwitcher.vue'

const route = useRoute()
const routeName = computed(() => route.name)

const isLoading = ref(false)
const showLoadingText = ref(false)

const setIsLoading = async (value: boolean) => {
  isLoading.value = value
  document.body.style.overflow = value ? 'hidden' : 'auto' // Disable scrolling when loading

  if (value) {
    // Reset text visibility
    showLoadingText.value = false

    // Wait for next DOM update, then delay text fade-in
    await nextTick()
    setTimeout(() => {
      showLoadingText.value = true
    }, 600)
  } else {
    showLoadingText.value = false
  }
}

provide("setIsLoading", setIsLoading)
</script>

<template>
  <main
    class="flex flex-row w-full min-h-screen md:px-0 gap-0 md:gap-10 md:pr-3 transition-all duration-500 ease-in-out"
    :class="isLoading ? 'overflow-x-hidden px-0 md:pr-0' : 'px-2 sm:px-5'"
  >
    <!-- Logo and Loading Element -->
    <div
      class="relative md:flex bg-white dark:bg-submit-925 h-screen flex-col items-center justify-center transition-all duration-700 ease-in-out"
      :class="isLoading ? 'w-[100vw]' : 'w-0 md:w-[50vw]'"
    >
      <router-link
        to="/"
        class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 transition-transform duration-700 ease-in-out"
        :class="isLoading ? '' : '-translate-x-[200%] md:translate-x-[-50%]'"
      >
        <svg
          class="dark:fill-gold-500 fill-gold-700 transition-all duration-500 ease-in-out"
          width="128"
          viewBox="0 0 118 143"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M2.82812 110.309L90.5094 22.6273C94.4146 18.7221 100.746 18.7221 104.652 22.6273L114.551 32.5268L26.8698 120.208C22.9645 124.113 16.6329 124.113 12.7276 120.208L2.82812 110.309Z" />
          <path d="M7.07107 74.9533C3.16583 71.048 3.16582 64.7164 7.07107 60.8111L60.8112 7.07102C64.7164 3.16577 71.0481 3.16577 74.9533 7.07101L77.7817 9.89944C81.687 13.8047 81.687 20.1363 77.7817 24.0416L16.9706 84.8528L7.07107 74.9533Z" />
          <path d="M39.5979 132.936C35.6927 129.031 35.6927 122.699 39.5979 118.794L100.409 57.9827L110.309 67.8822C114.214 71.7875 114.214 78.1191 110.309 82.0244L56.5685 135.764C52.6632 139.67 46.3316 139.67 42.4264 135.764L39.5979 132.936Z" />
        </svg>
      </router-link>

      <!-- Loading message appears below the logo without affecting its position -->
      <div
        v-if="showLoadingText"
        class="absolute text-sm md:mt-56 text-center opacity-0 transition-opacity duration-700 ease-in-out animate-pulse"
        :class="{ 'opacity-100 mx-auto w-full top-1/2 mt-[92px] md:top-auto': showLoadingText }"
      >
        <div>Loading, please wait...</div>
        <small>(This may take up to 10 seconds)</small>
    </div>
    </div>

    <!-- Form Content  -->
    <div
      class="relative flex flex-1 sm:max-w-sm min-h-screen items-center transition-opacity duration-500"
      :class="{ 'opacity-100': !isLoading, 'opacity-0 hidden': isLoading }"
    >
      <main class="w-full">
        <a href="/" class="mt-8 sm:mt-0 block md:hidden">
          <svg class="dark:fill-gold-500 fill-gold-700 mb-16" width="64" viewBox="0 0 118 143" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.82812 110.309L90.5094 22.6273C94.4146 18.7221 100.746 18.7221 104.652 22.6273L114.551 32.5268L26.8698 120.208C22.9645 124.113 16.6329 124.113 12.7276 120.208L2.82812 110.309Z" />
            <path d="M7.07107 74.9533C3.16583 71.048 3.16582 64.7164 7.07107 60.8111L60.8112 7.07102C64.7164 3.16577 71.0481 3.16577 74.9533 7.07101L77.7817 9.89944C81.687 13.8047 81.687 20.1363 77.7817 24.0416L16.9706 84.8528L7.07107 74.9533Z" />
            <path d="M39.5979 132.936C35.6927 129.031 35.6927 122.699 39.5979 118.794L100.409 57.9827L110.309 67.8822C114.214 71.7875 114.214 78.1191 110.309 82.0244L56.5685 135.764C52.6632 139.67 46.3316 139.67 42.4264 135.764L39.5979 132.936Z" />
          </svg>
        </a>
        <slot />
        <ul class="mt-20 flex flex-wrap md:flex-row gap-x-6 gap-y-2 text-xs">
          <li class="hidden md:inline">
            <router-link to="/" class="text-gold-700 dark:text-gold-500 underline hover:decoration-2">{{ $t("gobackhome") }}</router-link>
          </li>
          <template v-if="routeName === 'SignIn'">
            <li>
              <router-link to="/reset" class="text-gold-700 dark:text-gold-500 underline hover:decoration-2">{{ $t("forgotpassword") }}</router-link>
            </li>
            <li>
              <router-link to="/signup" class="text-gold-700 dark:text-gold-500 underline hover:decoration-2">{{ $t("signup") }}</router-link>
            </li>
          </template>
          <template v-if="routeName === 'SignUp'">
            <li>
              <router-link to="/signin" class="text-gold-700 dark:text-gold-500 underline hover:decoration-2">{{ $t("signin") }}</router-link>
            </li>
          </template>
          <template v-if="routeName === 'SignOut'">
            <li>
              <router-link to="/signin" class="text-gold-700 dark:text-gold-500 underline hover:decoration-2">{{ $t("signin") }}</router-link>
            </li>
          </template>
          <template v-if="routeName === 'Reset'">
            <li>
              <router-link to="/signin" class="text-gold-700 dark:text-gold-500 underline hover:decoration-2">{{ $t("signin") }}</router-link>
            </li>
          </template>
        </ul>
        <ul class="items-center mt-2 dark:text-indigo-250 text-xs space-y-2 sm:pb-8">
          <li>{{ $t("readour") }} <a class="underline hover:decoration-2 dark:hover:decoration-gold-500" href="https://docs.submit.gg" target="_blank">{{ $t("termsandguidelines") }}</a></li>
          <li class="flex gap-4">
            <ThemeSwitcher />
            <LocaleSwitcher />
          </li>
        </ul>
      </main>
    </div>
  </main>
</template>
