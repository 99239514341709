<script setup lang="ts">
import { generateUniqueSessionId, getSignals } from "@/composables/Sessions.js"
import { useRoute } from "vue-router"
import { ref, onMounted } from "vue"
import API from "@/api/api"

const route = useRoute()

const working = ref(true)
const error = ref(false)
const verified = ref(false)
const code = ref<string | undefined>(undefined)

onMounted(async () => {
  code.value = route.query.code as string
  if (!code.value) {
    error.value = true
    working.value = false
    return
  }
  try {
    await API(false).post("/verify", {
      code: code.value,
      session: await generateUniqueSessionId(),
      signals: getSignals()
    })
    verified.value = true
    working.value = false
  } catch (err) {
    error.value = true
    working.value = false
  }
})
</script>

<template>
  <section>
    <h1 class="font-display text-2xl dark:text-white font-bold mb-6">{{ $t('verification') }}</h1>
    <template v-if="!working && !error && verified">
      <p class="text-sm mb-8 leading-6">Thank you for verifying your email address. You can now continue to create your account.</p>
      <router-link :to="`/signup?code=${code}`" class="block text-center font-display font-bold rounded-md bg-gold-700/90 hover:bg-gold-700 dark:bg-gold-500/90 hover:dark:bg-gold-500 text-black py-4 w-full">
        Continue
      </router-link>
    </template>
    <template v-if="!working && error">
      <p class="text-sm leading-6">Verification code not found or expired. If it's been more than 24 hours since you started the sign up process, you'll need to sign up again.</p>
    </template>
    <template v-if="working && !error">
      <p class="text-sm leading-6 animate-pulse">Verifying&hellip;</p>
    </template>
  </section>
</template>
