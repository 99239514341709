<script setup lang="ts">
import API from '@/api/api'
import { ref, onMounted, computed, TransitionGroup } from 'vue'

interface Props {
  pollId: string
}

const props = defineProps<Props>()

const initialLoadComplete = ref(false)
const poll = ref<any>(null)
const error = ref(false)
const showResults = ref(false)
const revealResults = ref(false)
const hasRevealed = ref(false)

onMounted(async () => {
  await loadPoll()
})

async function loadPoll() {
  try {
    const res = await API().get(`/polls/${props.pollId}`)
    poll.value = res.data.data
    if (poll.value?.voted) {
      showResults.value = true
      revealResults.value = true
    }
  } catch (err) {
    console.error(err)
    error.value = true
  } finally {
    initialLoadComplete.value = true
  }
}

function calculatePercentage(votes: number): number {
  if (!poll.value || poll.value.totalVotes === 0) return 0
  return Math.round((votes / poll.value.totalVotes) * 100)
}


async function handleVote(optionId: number) {
  if (poll.value && !poll.value.canVote) return
  const session = localStorage.getItem('SBMT_DID')
  try {
    const res = await API().post('/polls/vote', {
      poll: props.pollId,
      option: optionId,
      revealed: hasRevealed.value,
      session: session ? session.split(':')[0] : undefined
    })
    poll.value.votedFor = optionId
    poll.value.voted = true
    poll.value.canVote = false
    poll.value.totalVotes++
    poll.value.options.find((o: any) => o.id === optionId).votes++

    // Step 1: Show bar animations first
    showResults.value = true

    // Step 2: Delay sorting until bars finish animating
    setTimeout(() => {
      revealResults.value = true
    }, 800) // Matches animation duration
  } catch (err: any) {
    console.error(err)
  }
}

async function toggleResults() {
  if (showResults.value && revealResults.value) {
    showResults.value = false
    setTimeout(() => {
      revealResults.value = false
    }, 800)
    return
  }
  hasRevealed.value = true
  showResults.value = true
  setTimeout(() => {
    revealResults.value = true
  }, 800)
}

const sortedOptions = computed(() => {
  if (!poll.value) return []

  // If results are hidden, sort by ID
  if (!revealResults.value) {
    return [...poll.value.options].sort((a, b) => a.id - b.id)
  }

  // If results are shown, sort by votes
  return [...poll.value.options].sort((a, b) => b.votes - a.votes)
})

function onBeforeEnter(el: Element) {
  (el as HTMLElement).style.opacity = '0'
  ;(el as HTMLElement).style.transform = 'translateY(30px)'
}

function onEnter(el: Element, done: () => void) {
  const delay = parseInt((el as HTMLElement).dataset.index ?? '0') * 150
  setTimeout(() => {
    (el as HTMLElement).style.opacity = '1'
    ;(el as HTMLElement).style.transform = 'translateY(0)'
    ;(el as HTMLElement).style.transition = 'all 500ms ease'
    done()
  }, delay)
}

function onBeforeLeave(el: Element) {
  const bounds = (el as HTMLElement).getBoundingClientRect()
  ;(el as HTMLElement).style.height = `${bounds.height}px`
  ;(el as HTMLElement).style.display = 'block'
}

function onLeave(el: Element, done: () => void) {
  ;(el as HTMLElement).style.opacity = '0'
  ;(el as HTMLElement).style.transition = 'all 500ms ease'
  done()
}
</script>

<template>
  <section class="Poll">
    <div v-if="initialLoadComplete && !error">
      <h3 class="font-semibold dark:text-gray-100 mb-2" v-if="poll.title">
        {{ poll.title }}
      </h3>

      <TransitionGroup
        tag="ul"
        class="space-y-3"
        :css="false"
        @before-enter="onBeforeEnter"
        @enter="onEnter"
        @leave="onLeave"
        @before-leave="onBeforeLeave"
        move-class="transition-transform duration-500 ease-in-out"
      >
        <li
          v-for="option in sortedOptions"
          :key="option.id"
          class="relative"
          :data-index="option.id"
        >
          <div
            class="relative z-10 p-3 rounded-md border dark:border-indigo-325/30 dark:bg-submit-900/50 transition-all"
            :class="[
              poll.canVote ? 'hover:dark:border-gray-600 cursor-pointer' : ''
            ]"
            @click="poll.canVote && handleVote(option.id)"
          >
            <div class="flex justify-between items-center w-full mb-2">
              <span class="font-medium dark:text-gray-200">{{ option.text }}</span>
              <span
                class="text-sm dark:text-gray-400 transition-opacity duration-500"
                :class="showResults ? 'opacity-100' : 'opacity-0'"
              >
                {{ calculatePercentage(option.votes) }}%
              </span>
            </div>

            <div class="space-y-1">
              <div class="h-1 w-full bg-gray-200 dark:bg-submit-500 rounded-full overflow-hidden">
                <div
                  class="h-full origin-left transition-all duration-700 ease-out"
                  :class="[
                    showResults ? 'dark:bg-gold-500' : 'dark:bg-submit-800',
                    poll.votedFor === option.id ? 'bg-gold-500' : 'bg-gold-400'
                  ]"
                  :style="{
                    width: showResults ? `${calculatePercentage(option.votes)}%` : '0%',
                    transitionDelay: `${option.id * 100}ms`
                  }"
                ></div>
              </div>

              <div
                class="text-xs dark:text-gray-400 transition-opacity duration-500"
                :class="showResults ? 'opacity-100' : 'opacity-0'"
              >
                {{ option.votes.toLocaleString() }} vote{{ option.votes !== 1 ? 's' : '' }} {{ Number(poll.votedFor) === Number(option.id) ? '(You voted for this)' : '' }}
              </div>
            </div>
          </div>
        </li>
      </TransitionGroup>
      <footer class="mt-2 text-xs flex gap-4">
        <div>{{ poll.voted ? 'You have voted - ' : '' }}{{ poll.totalVotes }} vote{{ poll.totalVotes !== 1 ? 's' : '' }} cast</div>
        <button
          v-if="!poll.voted && poll.totalVotes > 0"
          @click="toggleResults"
          class="dark:text-gray-400 hover:dark:text-white transition-colors"
        >
          {{ showResults ? 'Hide Results' : 'Show Results' }}
        </button>
      </footer>
    </div>
    <div v-if="initialLoadComplete && error">Error loading poll</div>
    <div v-if="!initialLoadComplete">Loading...</div>
  </section>
</template>
