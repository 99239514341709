<script setup lang="ts">
import FieldsetSelfSubmit from "@/components/input/FieldsetSelfSubmit.vue"
import Label from "@/components/input/Label.vue"
import { ref, onMounted, computed } from "vue"
import { useUserStore } from "@/stores/User"
import { storeToRefs } from "pinia"
import API from "@/api/api"

const UserStore = useUserStore()
const { id, featurePreviews, backer } = storeToRefs(UserStore)

const baseRoute = `/users/${id.value}/features`
const featuresLoading = ref(true)

const featureData = ref([])

const features = computed(() => {
  // return an object with two keys, each with an array of features. split the features by audience
  const features: { everyone: any[], backers: any[] } = {
    everyone: [],
    backers: []
  }
  featureData.value.forEach((feature: any) => {
    switch (feature.audience) {
      case "everyone":
        features.everyone.push({
          label: feature.name,
          value: feature.key
        })
        break
      case "backer":
        features.backers.push({
          label: feature.name,
          value: feature.key
        })
        break
    }
  })
  return features
})

const enabledFeatures = computed(() => {
  const enabled: { everyone: string[], backers: string[] } = {
    everyone: [],
    backers: []
  }
  featureData.value.forEach((feature: any) => {
    if (featurePreviews.value.includes(feature.key)) {
      switch (feature.audience) {
        case "everyone":
          enabled.everyone.push(feature.key)
          break
        case "backer":
          enabled.backers.push(feature.key)
          break
      }
    }
  })
  return enabled
})

onMounted(async () => {
  await getFeatures()
  featuresLoading.value = false
})

async function getFeatures() {
  try {
    const response = await API().get(`/platform/features?audience=all`)
    featureData.value = response.data.data
  } catch (err) {
    console.error(err)
  }
}

async function updateFeaturePreviews(feature: string, audience: string) {
  switch (audience) {
    case "everyone":
      if (enabledFeatures.value.everyone.includes(feature)) {
        UserStore.removeFeaturePreview(feature)
      } else {
        UserStore.addFeaturePreview(feature)
      }
      break
    case "backer":
      if (enabledFeatures.value.backers.includes(feature)) {
        UserStore.removeFeaturePreview(feature)
      } else {
        UserStore.addFeaturePreview(feature)
      }
      break
  }
}
</script>

<template>
  <main class="space-y-24">
    <section class="space-y-6">
      <div class="space-y-6">
        <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Preferences</h2>
        <p>Manage your preferences for various Submit features and functionality.</p>
      </div>
    </section>
    <section class="space-y-6">
      <div class="space-y-6">
        <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Feature Previews</h2>
        <p>If you'd like to preview new features before they're released, you can enable them here. Depending on the feature, we typically release previews to <router-link to="/backer" class="underline">backers</router-link> first, and then to the general community after a period of time.</p>
        <p class="text-xs text-gray-500 dark:text-gray-400"><strong>Note:</strong> Previews can and probably will be unstable and may not work as expected. You can always disable them at any time.</p>
        <div v-if="!featuresLoading">
          <Label field="preferences.publicPreview">Community Previews</Label>
          <FieldsetSelfSubmit
            name="publicFeatures"
            :options="features.everyone"
            :api-endpoint="baseRoute"
            :initial-values="enabledFeatures.everyone"
            @update="updateFeaturePreviews($event, 'everyone')"
          >
            <template #legend>
              <h3 class="text-xs font-semibold dark:text-gray-400">Select features to enable</h3>
            </template>
          </FieldsetSelfSubmit>
        </div>
        <div v-if="!featuresLoading">
          <Label field="preferences.backerPreview">Backer Previews</Label>
          <FieldsetSelfSubmit
            name="backerFeatures"
            :options="features.backers"
            :api-endpoint="baseRoute"
            :initial-values="enabledFeatures.backers"
            @update="updateFeaturePreviews($event, 'backers')"
            :disabled="!backer"
          >
            <template #legend>
              <h3 class="text-xs font-semibold dark:text-gray-400" v-if="backer">Select features to enable</h3>
              <h3 class="text-xs font-semibold dark:text-gray-400" v-else>You must be a backer to enable these features</h3>
            </template>
          </FieldsetSelfSubmit>
        </div>
        <div v-if="featuresLoading">
          Loading...
        </div>
      </div>
    </section>
  </main>
</template>
