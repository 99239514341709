<script setup lang="ts">
import { ref } from "vue"
import API from "@/api/api"

interface Option {
  label: string
  value: string
}

interface Props {
  name: string
  options?: Option[]
  helpText?: string
  disabled?: boolean
  apiEndpoint: string
  method?: string
  initialValues?: string[]

}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  method: 'POST',
  initialValues: () => []
})

const emit = defineEmits(['update'])

const selectedValues = ref<string[]>(props.initialValues)
const success = ref(false)
const error = ref<string | null>(null)

const sendData = async (value: string) => {
  try {
    const dataObj = JSON.stringify({
      feature: value
    })

    await API().request({
      headers: { 'Content-Type': 'application/json' },
      url: props.apiEndpoint,
      method: props.method,
      data: dataObj,
      withCredentials: true
    })

    success.value = true
    error.value = null
    emit('update', value)

    setTimeout(() => {
      success.value = false
    }, 5000)
  } catch (err: any) {
    success.value = false
    error.value = err.message

    setTimeout(() => {
      error.value = null
    }, 5000)
  }
}

const handleChange = async (event: Event, value: string) => {
  const target = event.target as HTMLInputElement
  if (target) {
    if (target.checked) {
      selectedValues.value = [...selectedValues.value, value]
    } else {
      selectedValues.value = selectedValues.value.filter(v => v !== value)
    }
    await sendData(value)
  }
}
</script>

<template>
  <fieldset>
    <legend class="text-sm font-bold">
      <slot name="legend">Legend</slot>
    </legend>
    <p v-if="helpText">{{ helpText }}</p>
    <div class="mt-4 grid grid-cols-2 sm:grid-cols-4 gap-4">
      <div v-for="(item, i) in options" :key="item.value" class="flex items-start col-span-2" v-if="options && options.length > 0">
        <div class="flex items-center h-5">
          <input
            :id="`${name}${i}`"
            :name="name"
            :value="item.value"
            type="checkbox"
            :checked="selectedValues.includes(item.value)"
            @change="(e) => handleChange(e, item.value)"
            :disabled="disabled"
            class="h-4 w-4 mr-2 rounded-sm border dark:border-gray-600 dark:bg-submit-500 checked:text-gold-700 dark:checked:text-gold-500 focus:outline-indigo-325/30"
          >
        </div>
        <div class="ml-3 text-sm">
          <label :for="`${name}${i}`">{{item.label}}</label>
        </div>
      </div>
      <div class="col-span-full text-sm" v-else>
        <p>There are no features available.</p>
      </div>
    </div>
    <div class="mt-2 flex items-center gap-x-2">
      <div v-if="error" class="text-sm text-red-500 flex items-center gap-x-2">
        <svg class="fill-red-500 w-4 flex-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm97.9-320l-17 17-47 47 47 47 17 17L320 353.9l-17-17-47-47-47 47-17 17L158.1 320l17-17 47-47-47-47-17-17L192 158.1l17 17 47 47 47-47 17-17L353.9 192z"/>
        </svg>
        {{ error }}
      </div>
      <div v-if="success" class="text-sm text-green-500 flex items-center gap-x-2">
        <svg class="fill-green-500 w-4 flex-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337l-17 17-17-17-64-64-17-17L160 222.1l17 17 47 47L335 175l17-17L385.9 192l-17 17z"/>
        </svg>
        Saved
      </div>
    </div>
  </fieldset>
</template>
