<script setup lang="ts">
import NewCircle from "@/components/actions/NewCircle.vue"
import CircleItem from "@/components/circles/CircleItem.vue"
import ViewCircle from "@/components/circles/ViewCircle.vue"
import { useUserStore } from "@/stores/User"
import { storeToRefs } from "pinia"
import { ref, onMounted, provide, computed } from "vue"
import API from "@/api/api"

const UserStore = useUserStore()
const { id, backer } = storeToRefs(UserStore)

const initialLoadComplete = ref(false)
const closeFriends = ref<any[]>([])
const customCircles = ref<any[]>([])
const working = ref(false)
const error = ref(false)
const errorDetails = ref<any>(undefined)

const modalOpen = ref(false)
provide("modalOpen", modalOpen)
const toggleModal = () => {
  modalOpen.value = !modalOpen.value
}
provide("toggleModal", toggleModal)

const canHaveXL = computed(() => {
  // check custom circles for an XL circle
  const hasXL = customCircles.value.some((circle: any) => circle.type === "xl")
  return backer.value && !hasXL
})

onMounted(async () => {
  await getCircles()
  if (closeFriends.value.length === 0) {
    createCloseFriendsCircle()
  }
  initialLoadComplete.value = true
})

async function getCircles() {
  const circleData = await API().get(`/circles`)
  closeFriends.value = circleData.data.data.closeFriends
  customCircles.value = circleData.data.data.otherCircles
}

function handleCircleCreated(circle: any) {
  customCircles.value.push(circle)
}

function handleCircleDeleted(circle: string) {
  customCircles.value = customCircles.value.filter((c: any) => c.code !== circle)
}

async function createCloseFriendsCircle() {
  if (working.value) return
  working.value = true
  error.value = false
  errorDetails.value = undefined

  try {
    const response = await API().post("/circles", {
      name: "Close Friends",
      type: "regular"
    })
    working.value = false
    closeFriends.value.push({
      _id: response.data.data._id,
      code: response.data.data.code,
      name: "Close Friends",
      type: "regular",
      count: 0
    })
    UserStore.addCircle({
      _id: response.data.data._id,
      code: response.data.data.code,
      name: "Close Friends",
      count: 0
    })
  } catch (err: any) {
    if (err.response && err.response.status === 400) {
      const response = err.response
      if (response.data && response.data.message && response.data.code) {
        errorDetails.value = response.data
      }
    } else {
      console.error(err)
    }
    error.value = true
    working.value = false
  }
}

function handleRemovedUserFromCircle(data: any) {
  if (data.code === closeFriends.value[0].code) {
    closeFriends.value[0].count--
  }
  if (data.code === customCircles.value[0].code) {
    customCircles.value[0].count--
  }
}
</script>

<template>
  <main class="space-y-24">
    <section class="space-y-6">
      <div class="space-y-6">
        <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Circles</h2>
        <p>Circles allow you to create groups of followers with whom you can share content selectively. They can also serve as content filters for your feed, showing you posts only from those in your circle (provided you're following them).</p>
        <p>Circles are designed to foster more intimate connections by narrowing your engagement to those who matter most to you. To maintain this focus, each circle is limited to a maximum of 64 users.</p>
      </div>
      <div class="space-y-6" v-if="initialLoadComplete">
        <h3 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-xl">Close Friends</h3>
        <p v-if="closeFriends.length === 0">You haven't created a close friends circle yet.</p>
        <div v-if="closeFriends.length > 0" class="space-y-3">
          <p v-if="closeFriends[0].count === 0">You haven't added any users to your close friends circle yet.</p>
          <p v-if="closeFriends[0].count > 0">You have {{ closeFriends[0].count }} user{{ closeFriends[0].count > 1 ? "s" : "" }} in your close friends circle.</p>
          <ViewCircle :circle="closeFriends[0]" label="View Close Friends Circle" button-class="text-sm bg-white dark:bg-submit-500 rounded-md px-4 py-1.5 font-semibold" @remove-from-circle="handleRemovedUserFromCircle" />
        </div>
      </div>
      <div class="space-y-6" v-if="initialLoadComplete">
        <h3 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-xl">Custom Circles</h3>
        <p v-if="backer">As a backer, you can create up to nineteen (19) custom circles. One of those custom cirlces may also be an XL circle, which has an increased limit of 256 users.</p>
        <p v-else>In addition to the close friends circle, you can create two (2) custom circles. If you'd like to create more, please consider becoming a backer!</p>
        <div class="flex justify-between items-center">
          <p class="text-sm">{{ customCircles.length }}/{{ backer ? 19 : 2 }} custom circles.</p>
          <button type="button" @click="toggleModal" class="text-sm bg-white dark:bg-submit-500 rounded-md px-4 py-1.5 font-semibold" v-if="customCircles.length < (backer ? 19 : 2)">Create Custom Circle</button>
          <NewCircle :canHaveXL="canHaveXL" @created="handleCircleCreated" />
        </div>
        <ul class="grid grid-cols-2 gap-4">
          <li class="text-sm col-span-full" v-if="customCircles.length === 0">You have no custom circles, click the button above to create one!</li>
          <CircleItem v-for="circle in customCircles" :key="circle._id" :circle="circle" @deleted="handleCircleDeleted" @removed-user-from-circle="handleRemovedUserFromCircle" />
        </ul>
      </div>
      <div class="space-y-6" v-if="!initialLoadComplete">
        Loading...
      </div>
    </section>
  </main>
</template>
