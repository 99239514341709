<script setup lang="ts">
import { computed, inject, ref, type Ref } from "vue"
import Poll from "@/components/polls/Poll.vue"
import { format, parseISO } from "date-fns"
import { useRouter } from "vue-router"

const Router = useRouter()

interface Props {
  item: any
  edit?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  edit: false
})

const lastProcessedText = ref('')
const processedContentCache = ref('')

const sameActionAuthor = inject('sameActionAuthor') as Ref<boolean>
const originalArray = computed(() => {
  if (props.item.activityType === "media") {
    return [...props.item.content.reverse()]
  } else {
    return [...props.item.actions || []]
  }
})

const mediaCount = computed(() => mediaArray.value.length)
const originalCount = computed(() => originalArray.value.length)

const mediaArray = computed(() => {
  if (!props.item) return []

  // Handle both hydrated and unhydrated media items
  const isMedia = props.item.activityType === "media"
  const content = props.item.content || []

  if (isMedia && Array.isArray(content)) {
    return [...content]
      .reverse()
      .slice(0, 8)
  }
  return []
})

const actionsArray = computed(() => {
  const array = []
  if (props.item.activityType === "action") {
    let limit = 3
    if (props.item.contentType === 'media') limit = 15
    if (originalArray.value.length > limit) {
      for (let i = 0; i < limit - 1; i++) {
        if (originalArray.value[i]) {
          array.push(originalArray.value[i])
        }
      }
    } else {
      return originalArray.value
    }
  }
  return array
})

const contentText = ref<string>(props.item.content ? props.item.content.text : "")
const origText = ref<String>(props.item.content ? props.item.content.text : "")

const processedContent = computed(() => {
  if (!contentText.value) return ""

  // Cache the result to avoid reprocessing if contentText hasn't changed
  if (contentText.value === lastProcessedText.value) {
    return processedContentCache.value
  }

  // Process the content
  const tempDiv = document.createElement('div')
  tempDiv.innerHTML = contentText.value

  // Find all mention spans
  const mentions = tempDiv.querySelectorAll('span[data-type="mention"]')

  // Replace each mention span with a special anchor tag
  mentions.forEach(mention => {
    const username = mention.getAttribute('data-label')
    if (username) {
      const anchor = document.createElement('a')
      anchor.setAttribute('href', `/${username}`)
      anchor.setAttribute('class', 'mention')
      anchor.setAttribute('onclick', 'event.preventDefault()')
      anchor.setAttribute('data-username', username)
      anchor.textContent = `@${username}`
      mention.parentNode?.replaceChild(anchor, mention)
    }
  })

  // Cache the result
  lastProcessedText.value = contentText.value
  processedContentCache.value = tempDiv.innerHTML

  return processedContentCache.value
})

// Add click handler function
function handleMentionClick(event: MouseEvent) {
  const target = event.target as HTMLElement
  if (target.classList.contains('mention')) {
    const username = target.getAttribute('data-username')
    if (username) {
      Router.push(`/${username}`)
    }
  }
}

function actionLabel(action: any) {
  if (action.action === "comment") {
    return "Commented"
  }

  if (action.action === "reaction") {
    if (action.actionSubType === "love") {
      return "Loved"
    }
    return "Reacted"
  }
}

const shouldLoadMore = computed(() => {
  return mediaCount.value < originalCount.value
})

const shouldLoadMoreActions = computed(() => {
  return actionsArray.value.length < originalArray.value.length
})

function loadMore() {
  let currentLength = mediaArray.value.length
  let targetLength = currentLength + 8
  for (let i = currentLength; i < targetLength; i++) {
    if (originalArray.value[i]) {
      mediaArray.value.push(originalArray.value[i])
    }
  }
}

function loadMoreActions() {
  let currentLength = actionsArray.value.length
  let targetLength = currentLength + 8
  for (let i = currentLength; i < targetLength; i++) {
    if (originalArray.value[i]) {
      actionsArray.value.push(originalArray.value[i])
    }
  }
}

function getEmojiForType(type: string) {
  return type === "like" ? "👍" : type === "dislike" ? "👎" : type === "love" ? "❤️" : type === "hot" ? "🥵" : type === "laugh" ? "😂" : type === "cry" ? "😢" : type === "angry" ? "😠" : type === "celebrate" ? "🥳" : ""
}

function getReactionCount(reactions: any) {
  let count = 0
  for (const key in reactions) {
    count += reactions[key]
  }
  return count
}

function adjustToUSA(location: string) {
  if (location.includes("United States")) {
    return location.replace("United States", "USA")
  }
  return location
}

function getEventCategory(category: string) {
  switch (category) {
    case "submit-event":
      return "Submit Event"
    case "educational":
      return "Educational"
    case "social":
      return "Social"
    case "bdsm":
      return "BDSM Party"
    case "kink":
      return "Kink Party"
    case "sex":
      return "Sex Party"
    case "conference":
      return "Conference or Festival"
  }
  return category
}

function getEventType(type: string) {
  switch (type) {
    case "in-person":
      return "In-Person"
    case "virtual":
    case "virtual-limited":
    case "virtual-global":
      return "Virtual"
  }
  return type
}
</script>

<template>
  <!-- Action List: V1 -->
  <ul class="ActionList" v-if="item.activityType === 'action' && item.version === 1">
    <li class="text-sm dark:text-gray-400" v-for="action in actionsArray">
      <figure v-if="action.action !== 'follow'" class="flex flex-none items-center justify-center text-sm h-8 w-8 text-gray-800 dark:text-gray-500 bg-gray-200 dark:bg-submit-900 font-bold" :style="action.actionThumbnail ? `background-image: url('${action.actionThumbnail}')` : ''">
        <svg class="h-3.5 dark:fill-gray-400" v-if="action.actionTakenOnType === 'writing'" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M368.4 18.3c21.9-21.9 57.3-21.9 79.2 0l46.1 46.1c21.9 21.9 21.9 57.3 0 79.2l-71 71L412.2 225 371.6 374.1c-4.8 17.8-18.1 32-35.5 38.1L72 505c-18.5 6.5-39.1 1.8-52.9-12S.5 458.5 7 440L99.8 175.9c6.1-17.4 20.3-30.6 38.1-35.5L287 99.8l10.4-10.4 71-71zM296.9 146.8L150.5 186.7c-2.5 .7-4.6 2.6-5.4 5.1L62.5 426.9 164.7 324.7c-3-6.3-4.7-13.3-4.7-20.7c0-26.5 21.5-48 48-48s48 21.5 48 48s-21.5 48-48 48c-7.4 0-14.4-1.7-20.7-4.7L85.1 449.5l235.1-82.6c2.5-.9 4.4-2.9 5.1-5.4l39.9-146.4-68.3-68.3z"/></svg>
        <svg class="h-3.5 dark:fill-gray-400" v-if="action.actionTakenOnType === 'status'" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M0 64C0 28.7 28.7 0 64 0H448c35.3 0 64 28.7 64 64V352c0 35.3-28.7 64-64 64H309.3L185.6 508.8c-4.8 3.6-11.3 4.2-16.8 1.5s-8.8-8.2-8.8-14.3V416H64c-35.3 0-64-28.7-64-64V64zm340.8 59c-14.7-14.7-38.6-14.7-53.3 0L272.6 138l53.3 53.3 14.9-14.9c14.7-14.7 14.7-38.6 0-53.3zM178.1 232.5c-4.1 4.1-7 9.2-8.4 14.9L160.5 284c-1.4 5.5 .2 11.2 4.2 15.2s9.7 5.6 15.2 4.2l36.6-9.2c5.6-1.4 10.8-4.3 14.9-8.4l71.9-71.9L250 160.6l-71.9 71.9z"/></svg>
      </figure>
      <figure v-if="action.action === 'follow'" class="flex flex-none items-center justify-center text-sm h-8 w-8 text-gray-800 dark:text-gray-500 bg-gray-200 dark:bg-submit-900 font-bold" :style="action.actionTakenOnAuthor.pfp ? `background-image: url('${action.actionTakenOnAuthor.pfp}')` : ''">
        <span v-if="!action.actionTakenOnAuthor.pfp">{{ action.actionTakenOnAuthor.firstLetter }}</span>
      </figure>
      <p v-if="item.author._id !== action.actionTakenOnAuthor._id && action.action !== 'follow'"><a :href="`/${action.actionTakenOnAuthor.username}/${action.actionTakenOnType}/${action.actionTakenOnId}${action.action === 'comment' ? '#'+action.actionRefId : ''}`">{{ actionLabel(action) }} {{ action.action === 'reaction' ? '' : 'on ' }}{{ action.actionTakenOnType !== 'media' ?  ' a ' : ''}}{{ action.actionTakenOnType }} from {{ action.actionTakenOnAuthor.username }}{{ action.count > 1 ? ` (x${action.count})` : '' }}</a></p>
      <p v-if="item.author._id === action.actionTakenOnAuthor._id && action.action !== 'follow'"><a :href="`/${action.actionTakenOnAuthor.username}/${action.actionTakenOnType}/${action.actionTakenOnId}${action.action === 'comment' ? '#'+action.actionRefId : ''}`">{{ actionLabel(action) }} on their own {{ action.actionTakenOnType }}{{ action.count > 1 ? ` (x${action.count})` : '' }}</a></p>
      <p v-if="action.action === 'follow'"><a class="underline" :href="`/${action.actionTakenOnAuthor.username}`">{{ action.actionTakenOnAuthor.username }}</a></p>
    </li>
    <li class="text-sm dark:text-gray-400 hover:cursor-pointer" @click="loadMoreActions" v-if="shouldLoadMoreActions">
      <figure class="flex flex-none items-center justify-center text-sm h-8 w-8 text-gray-800 dark:text-gray-500 bg-gray-200 dark:bg-submit-900 font-bold">
        <svg class="h-3.5 dark:fill-gray-400" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M256 80V48H192V80 224H48 16v64H48 192V432v32h64V432 288H400h32V224H400 256V80z"/></svg>
      </figure>
      <span>Load more</span>
    </li>
  </ul>
  <!-- Action List: V2 -->
  <ul
    :class="[
      item.contentType === 'media' && item.activitySubType === 'reaction' ? 'MediaList' : '',
      item.contentType === 'media' && item.activitySubType === 'comment' ? 'MediaCommentList' : '',
      item.contentType === 'writing' ? 'WritingList' : '',
      item.contentType === 'status' ? 'StatusList' : '',
      item.contentType === 'post' ? 'PostList' : '',
      'ActionList'
    ]"
    v-if="item.activityType === 'action' && item.version === 2"
  >
    <li v-for="action in actionsArray" v-if="item.activitySubType === 'follow'">
      <template v-if="action.actionTakenOnType === 'user'">
        <router-link :to="`/${action.actionTakenOnAuthor.username}`">
          <figure class="flex flex-none items-center justify-center text-sm h-10 w-10 sm:h-12 sm:w-12 text-gray-800 dark:text-gray-500 bg-white dark:bg-submit-900 font-bold bg-cover capitalize rounded-full" :style="action.actionTakenOnAuthor.pfp ? `background-image: url('${action.actionTakenOnAuthor.pfp}')` : ''">
            <span v-if="!action.actionTakenOnAuthor.pfp">{{ action.actionTakenOnAuthor.firstLetter }}</span>
          </figure>
        </router-link>
        <router-link class="grid" :to="`/${action.actionTakenOnAuthor.username}`">
          <div>{{ action.actionTakenOnAuthor.username }}</div>
          <div class="text-sm dark:text-gray-400">{{ action.actionTakenOnAuthor.meta.metaLabel }}</div>
        </router-link>
      </template>
      <template v-if="action.actionTakenOnType === 'organization'">
        <router-link :to="`/${action.actionTakenOnAuthor.handle}`">
          <figure class="flex flex-none items-center justify-center text-sm h-10 w-10 sm:h-12 sm:w-12 text-gray-800 dark:text-gray-500 bg-white dark:bg-submit-900 font-bold bg-cover rounded-full capitalize" :style="action.actionTakenOnAuthor.pfp ? `background-image: url('${action.actionTakenOnAuthor.pfp}')` : ''">
            <span v-if="!action.actionTakenOnAuthor.pfp">{{ action.actionTakenOnAuthor.firstLetter }}</span>
          </figure>
        </router-link>
        <router-link class="grid" :to="`/${action.actionTakenOnAuthor.handle}`">
          <div>{{ action.actionTakenOnAuthor.name }}</div>
          <div class="text-sm dark:text-gray-400">{{ action.actionTakenOnAuthor.type === 'socialnetwork' ? 'Social Network' : 'Unknown Type' }}</div>
        </router-link>
      </template>
    </li>
    <li v-for="action in actionsArray" v-if="item.contentType === 'status' && item.activitySubType === 'reaction'">
      <router-link :to="`/${action.actionTakenOnAuthor.username}/${action.actionTakenOnType}/${action.actionTakenOnId}`">
        <figure class="flex relative flex-none items-center justify-center text-sm h-10 w-10 sm:h-12 sm:w-12 text-gray-800 dark:text-gray-500 bg-white dark:bg-submit-900 font-bold bg-cover rounded-md">
          <svg class="h-4 dark:fill-gray-400" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M168.2 384.9c-15-5.4-31.7-3.1-44.6 6.4c-8.2 6-22.3 14.8-39.4 22.7c5.6-14.7 9.9-31.3 11.3-49.4c1-12.9-3.3-25.7-11.8-35.5C60.4 302.8 48 272 48 240c0-79.5 83.3-160 208-160s208 80.5 208 160s-83.3 160-208 160c-31.6 0-61.3-5.5-87.8-15.1zM26.3 423.8c-1.6 2.7-3.3 5.4-5.1 8.1l-.3 .5c-1.6 2.3-3.2 4.6-4.8 6.9c-3.5 4.7-7.3 9.3-11.3 13.5c-4.6 4.6-5.9 11.4-3.4 17.4c2.5 6 8.3 9.9 14.8 9.9c5.1 0 10.2-.3 15.3-.8l.7-.1c4.4-.5 8.8-1.1 13.2-1.9c.8-.1 1.6-.3 2.4-.5c17.8-3.5 34.9-9.5 50.1-16.1c22.9-10 42.4-21.9 54.3-30.6c31.8 11.5 67 17.9 104.1 17.9c141.4 0 256-93.1 256-208S397.4 32 256 32S0 125.1 0 240c0 45.1 17.7 86.8 47.7 120.9c-1.9 24.5-11.4 46.3-21.4 62.9zM152 176c-13.3 0-24 10.7-24 24s10.7 24 24 24H360c13.3 0 24-10.7 24-24s-10.7-24-24-24H152zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24H264c13.3 0 24-10.7 24-24s-10.7-24-24-24H152z"/></svg>
          <div class="absolute -bottom-1.5 -right-1.5 text-lg">
            {{ getEmojiForType(action.actionSubType) }}
          </div>
        </figure>
      </router-link>
      <router-link class="grid" :to="`/${action.actionTakenOnAuthor.username}/${action.actionTakenOnType}/${action.actionTakenOnId}`">
        <div class="relative text-sm sm:text-base whitespace-nowrap overflow-hidden overflow-ellipsis mr-4">{{ action.actionContent ? action.actionContent : 'Preview not available.' }}</div>
        <ul class="flex gap-x-4 text-xs dark:text-gray-400">
          <li v-if="!sameActionAuthor">{{ action.actionTakenOnAuthor.username }}</li>
          <li>{{ getReactionCount(action.actionTakenOnContent.reactionsCount) }} reaction{{ getReactionCount(action.actionTakenOnContent.reactionsCount) === 1 ? '' : 's' }}</li>
          <li>{{ action.actionTakenOnContent.commentCount }} comment{{ action.actionTakenOnContent.commentCount === 1 ? '' : 's' }}</li>
        </ul>
      </router-link>
    </li>
    <li v-for="action in actionsArray" v-if="item.contentType === 'status' && item.activitySubType === 'comment'">
      <router-link :to="`/${action.actionTakenOnAuthor.username}/${action.actionTakenOnType}/${action.actionTakenOnId}${action.actionRefId ? '#'+action.actionRefId : ''}`">
        <figure class="flex flex-none items-center justify-center text-sm h-10 w-10 sm:h-12 sm:w-12 text-gray-800 dark:text-gray-500 bg-white dark:bg-submit-900 font-bold bg-cover rounded-md">
          <svg class="h-4 dark:fill-gray-400" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M168.2 384.9c-15-5.4-31.7-3.1-44.6 6.4c-8.2 6-22.3 14.8-39.4 22.7c5.6-14.7 9.9-31.3 11.3-49.4c1-12.9-3.3-25.7-11.8-35.5C60.4 302.8 48 272 48 240c0-79.5 83.3-160 208-160s208 80.5 208 160s-83.3 160-208 160c-31.6 0-61.3-5.5-87.8-15.1zM26.3 423.8c-1.6 2.7-3.3 5.4-5.1 8.1l-.3 .5c-1.6 2.3-3.2 4.6-4.8 6.9c-3.5 4.7-7.3 9.3-11.3 13.5c-4.6 4.6-5.9 11.4-3.4 17.4c2.5 6 8.3 9.9 14.8 9.9c5.1 0 10.2-.3 15.3-.8l.7-.1c4.4-.5 8.8-1.1 13.2-1.9c.8-.1 1.6-.3 2.4-.5c17.8-3.5 34.9-9.5 50.1-16.1c22.9-10 42.4-21.9 54.3-30.6c31.8 11.5 67 17.9 104.1 17.9c141.4 0 256-93.1 256-208S397.4 32 256 32S0 125.1 0 240c0 45.1 17.7 86.8 47.7 120.9c-1.9 24.5-11.4 46.3-21.4 62.9zM152 176c-13.3 0-24 10.7-24 24s10.7 24 24 24H360c13.3 0 24-10.7 24-24s-10.7-24-24-24H152zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24H264c13.3 0 24-10.7 24-24s-10.7-24-24-24H152z"/></svg>
        </figure>
      </router-link>
      <router-link class="grid" :to="`/${action.actionTakenOnAuthor.username}/${action.actionTakenOnType}/${action.actionTakenOnId}${action.actionRefId ? '#'+action.actionRefId : ''}`">
        <div :title="action.actionContent" class="relative text-sm sm:text-base whitespace-nowrap overflow-hidden overflow-ellipsis mr-4">{{ action.actionContent }}</div>
        <ul class="flex gap-x-4 text-xs dark:text-gray-400">
          <li v-if="!sameActionAuthor">{{ action.actionTakenOnAuthor.username }}</li>
          <li>{{ getReactionCount(action.actionTakenOnContent.reactionsCount) }} reaction{{ getReactionCount(action.actionTakenOnContent.reactionsCount) === 1 ? '' : 's' }}</li>
          <li>{{ action.actionTakenOnContent.commentCount }} comment{{ action.actionTakenOnContent.commentCount === 1 ? '' : 's' }}</li>
        </ul>
      </router-link>
    </li>
    <li v-for="action in actionsArray" v-if="item.contentType === 'media' && item.activitySubType === 'comment'">
      <router-link class="flex flex-none" :to="`/${action.actionTakenOnAuthor.username}/${action.actionTakenOnType}/${action.actionTakenOnId}${action.actionRefId ? '#'+action.actionRefId : ''}`">
        <img class="pointer-events-none" :src="action.actionThumbnail" @error="" />
      </router-link>
      <router-link class="grid" :to="`/${action.actionTakenOnAuthor.username}/${action.actionTakenOnType}/${action.actionTakenOnId}${action.actionRefId ? '#'+action.actionRefId : ''}`">
        <div :title="action.actionContent" class="relative text-sm sm:text-base whitespace-nowrap overflow-hidden overflow-ellipsis mr-4">{{ action.actionContent }}</div>
        <ul class="flex gap-x-4 text-xs dark:text-gray-400">
          <li v-if="!sameActionAuthor">{{ action.actionTakenOnAuthor.username }}</li>
          <li>{{ getReactionCount(action.actionTakenOnContent.reactionsCount) }} reaction{{ getReactionCount(action.actionTakenOnContent.reactionsCount) === 1 ? '' : 's' }}</li>
          <li>{{ action.actionTakenOnContent.commentCount }} comment{{ action.actionTakenOnContent.commentCount === 1 ? '' : 's' }}</li>
        </ul>
      </router-link>
    </li>
    <li v-for="action in actionsArray" v-if="item.contentType === 'media' && item.activitySubType === 'reaction'">
      <router-link class="flex flex-none relative group" :to="`/${action.actionTakenOnAuthor.username}/${action.actionTakenOnType}/${action.actionTakenOnId}`">
        <img class="pointer-events-none flex-none" :src="action.actionThumbnail" />
        <div class="hidden group-hover:block absolute -bottom-1.5 -right-1.5 text-lg">
          {{ getEmojiForType(action.actionSubType) }}
        </div>
      </router-link>
    </li>
    <li v-for="action in actionsArray" v-if="item.contentType === 'writing' && item.activitySubType === 'reaction'">
      <router-link :to="`/${action.actionTakenOnAuthor.username}/${action.actionTakenOnType}/${action.actionTakenOnContent.slug}`">
        <figure class="flex relative flex-none items-center justify-center text-sm h-10 w-10 sm:h-12 sm:w-12 text-gray-800 dark:text-gray-500 bg-white dark:bg-submit-900 font-bold rounded-md" :style="action.actionThumbnail ? `background-image: url('${action.actionThumbnail}')` : ''">
          <svg class="h-4 dark:fill-gray-400" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M368.4 18.3c21.9-21.9 57.3-21.9 79.2 0l46.1 46.1c21.9 21.9 21.9 57.3 0 79.2l-71 71L412.2 225 371.6 374.1c-4.8 17.8-18.1 32-35.5 38.1L72 505c-18.5 6.5-39.1 1.8-52.9-12S.5 458.5 7 440L99.8 175.9c6.1-17.4 20.3-30.6 38.1-35.5L287 99.8l10.4-10.4 71-71zM296.9 146.8L150.5 186.7c-2.5 .7-4.6 2.6-5.4 5.1L62.5 426.9 164.7 324.7c-3-6.3-4.7-13.3-4.7-20.7c0-26.5 21.5-48 48-48s48 21.5 48 48s-21.5 48-48 48c-7.4 0-14.4-1.7-20.7-4.7L85.1 449.5l235.1-82.6c2.5-.9 4.4-2.9 5.1-5.4l39.9-146.4-68.3-68.3z"/></svg>
          <div class="absolute -bottom-1.5 -right-1.5 text-base">
            {{ getEmojiForType(action.actionSubType) }}
          </div>
        </figure>
      </router-link>
      <router-link class="break-words text-sm sm:text-base" :to="`/${action.actionTakenOnAuthor.username}/${action.actionTakenOnType}/${action.actionTakenOnContent.slug}`">
        {{ action.actionTakenOnContent.title }}
        <ul class="flex gap-x-4 text-xs dark:text-gray-400">
          <li v-if="!sameActionAuthor">{{ action.actionTakenOnAuthor.username }}</li>
          <li>{{ getReactionCount(action.actionTakenOnContent.reactionsCount) }} reaction{{ getReactionCount(action.actionTakenOnContent.reactionsCount) === 1 ? '' : 's' }}</li>
          <li>{{ action.actionTakenOnContent.commentCount }} comment{{ action.actionTakenOnContent.commentCount === 1 ? '' : 's' }}</li>
        </ul>
      </router-link>
    </li>
    <li v-for="action in actionsArray" v-if="item.contentType === 'writing' && item.activitySubType === 'comment'">
      <router-link :to="`/${action.actionTakenOnAuthor.username}/${action.actionTakenOnType}/${action.actionTakenOnContent.slug}${action.actionRefId ? '#'+action.actionRefId : ''}`">
        <figure class="flex flex-none items-center justify-center text-sm h-10 w-10 sm:h-12 sm:w-12 text-gray-800 dark:text-gray-500 bg-white dark:bg-submit-900 font-bold rounded-md" :style="action.actionThumbnail ? `background-image: url('${action.actionThumbnail}')` : ''">
          <svg class="h-4 dark:fill-gray-400" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M368.4 18.3c21.9-21.9 57.3-21.9 79.2 0l46.1 46.1c21.9 21.9 21.9 57.3 0 79.2l-71 71L412.2 225 371.6 374.1c-4.8 17.8-18.1 32-35.5 38.1L72 505c-18.5 6.5-39.1 1.8-52.9-12S.5 458.5 7 440L99.8 175.9c6.1-17.4 20.3-30.6 38.1-35.5L287 99.8l10.4-10.4 71-71zM296.9 146.8L150.5 186.7c-2.5 .7-4.6 2.6-5.4 5.1L62.5 426.9 164.7 324.7c-3-6.3-4.7-13.3-4.7-20.7c0-26.5 21.5-48 48-48s48 21.5 48 48s-21.5 48-48 48c-7.4 0-14.4-1.7-20.7-4.7L85.1 449.5l235.1-82.6c2.5-.9 4.4-2.9 5.1-5.4l39.9-146.4-68.3-68.3z"/></svg>
        </figure>
      </router-link>
      <router-link class="grid" :to="`/${action.actionTakenOnAuthor.username}/${action.actionTakenOnType}/${action.actionTakenOnContent.slug}${action.actionRefId ? '#'+action.actionRefId : ''}`">
        <div :title="action.actionContent" class="relative text-sm sm:text-base whitespace-nowrap overflow-hidden overflow-ellipsis mr-4">{{ action.actionContent }}</div>
        <ul class="flex gap-x-4 text-xs dark:text-gray-400">
          <li v-if="!sameActionAuthor">{{ action.actionTakenOnAuthor.username }}</li>
          <li>{{ getReactionCount(action.actionTakenOnContent.reactionsCount) }} reaction{{ getReactionCount(action.actionTakenOnContent.reactionsCount) === 1 ? '' : 's' }}</li>
          <li>{{ action.actionTakenOnContent.commentCount }} comment{{ action.actionTakenOnContent.commentCount === 1 ? '' : 's' }}</li>
        </ul>
      </router-link>
    </li>
    <li v-for="action in actionsArray" v-if="item.contentType === 'post' && item.activitySubType === 'reaction'">
      <router-link :to="`/groups/${action.actionTakenOnContent.groupSlug}/${action.actionTakenOnContent.short}`">
        <figure class="flex relative flex-none items-center justify-center text-sm h-10 w-10 sm:h-12 sm:w-12 text-gray-800 dark:text-gray-500 bg-white dark:bg-submit-900 font-bold rounded-md" :style="action.actionThumbnail ? `background-image: url('${action.actionThumbnail}')` : ''">
          <svg class="dark:fill-gray-400 h-4 w-4" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M0 64C0 28.7 28.7 0 64 0H448c35.3 0 64 28.7 64 64V352c0 35.3-28.7 64-64 64H309.3L185.6 508.8c-4.8 3.6-11.3 4.2-16.8 1.5s-8.8-8.2-8.8-14.3V416H64c-35.3 0-64-28.7-64-64V64zm152 80c-13.3 0-24 10.7-24 24s10.7 24 24 24H360c13.3 0 24-10.7 24-24s-10.7-24-24-24H152zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24H264c13.3 0 24-10.7 24-24s-10.7-24-24-24H152z"/></svg>
          <div class="absolute -bottom-1.5 -right-1.5 text-lg">
            {{ getEmojiForType(action.actionSubType) }}
          </div>
        </figure>
      </router-link>
      <router-link class="grid break-words text-sm sm:text-base" :to="`/groups/${action.actionTakenOnContent.groupSlug}/${action.actionTakenOnContent.short}`">
        {{ action.actionTakenOnContent.title }}
        <ul class="flex gap-x-4 text-xs dark:text-gray-400">
          <li>{{ action.actionTakenOnContent.groupName }}</li>
          <li>{{ getReactionCount(action.actionTakenOnContent.reactionsCount) }} reaction{{ getReactionCount(action.actionTakenOnContent.reactionsCount) === 1 ? '' : 's' }}</li>
          <li>{{ action.actionTakenOnContent.commentCount }} comment{{ action.actionTakenOnContent.commentCount === 1 ? '' : 's' }}</li>
        </ul>
      </router-link>
    </li>
    <li v-for="action in actionsArray" v-if="item.contentType === 'starterpack'">
      <router-link :to="`/starter-packs/${action.actionTakenOnContent.short}`">
        <figure class="flex relative flex-none items-center justify-center text-sm h-10 w-10 sm:h-12 sm:w-12 text-gray-800 dark:text-gray-500 bg-white dark:bg-submit-900 font-bold rounded-md">
          <svg class="dark:fill-gray-400 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M51.8 160.4c-3.7 2.1-4.9 6.8-2.8 10.5l167 289.3c2.1 3.7 6.8 4.9 10.5 2.8L419.4 351.6c3.7-2.1 4.9-6.8 2.8-10.5L255.2 51.8c-2.1-3.7-6.8-4.9-10.5-2.8L51.8 160.4zM7.5 194.9c-15.4-26.6-6.3-60.7 20.4-76.1L220.7 7.5c26.6-15.4 60.7-6.3 76.1 20.4l167 289.3c15.4 26.6 6.2 60.7-20.4 76.1L250.5 504.5c-26.6 15.4-60.7 6.2-76.1-20.4L7.5 194.9zm451.9 226c41.9-24.2 56.3-77.8 32.1-119.8L354.7 64.2c1.7-.2 3.5-.2 5.3-.2l224 0c30.9 0 56 25.1 56 56l0 336c0 30.9-25.1 56-56 56l-224 0c-13.7 0-26.2-4.9-35.9-13l135.3-78.1z"/></svg>
          <div class="absolute -bottom-1.5 -right-1.5 text-lg" v-if="item.activitySubType === 'reaction'">
            {{ getEmojiForType(action.actionSubType) }}
          </div>
        </figure>
      </router-link>
      <router-link class="grid break-words text-sm sm:text-base" :to="`/starter-packs/${action.actionTakenOnContent.short}`">
        {{ action.actionTakenOnContent.name }}
        <ul class="flex gap-x-4 text-xs dark:text-gray-400">
          <li>{{ action.actionTakenOnContent.userCount }} User{{ action.actionTakenOnContent.userCount === 1 ? '' : 's' }}</li>
          <li>{{ action.actionTakenOnContent.groupCount }} Group{{ action.actionTakenOnContent.groupCount === 1 ? '' : 's' }}</li>
        </ul>
      </router-link>
    </li>
    <li v-for="action in actionsArray" v-if="item.contentType === 'event' && item.activitySubType === 'reaction'">
      <router-link :to="`/e/${action.actionTakenOnContent.short}`">
        <figure class="flex relative flex-none items-center justify-center text-sm h-10 w-10 sm:h-12 sm:w-12 text-gray-800 dark:text-gray-500 bg-white dark:bg-submit-900 font-bold rounded-md" :style="action.actionThumbnail ? `background-image: url('${action.actionThumbnail}')` : ''">
          <svg class="dark:fill-gray-400 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M128 0c17.7 0 32 14.3 32 32l0 32 128 0 0-32c0-17.7 14.3-32 32-32s32 14.3 32 32l0 32 48 0c26.5 0 48 21.5 48 48l0 48L0 160l0-48C0 85.5 21.5 64 48 64l48 0 0-32c0-17.7 14.3-32 32-32zM0 192l448 0 0 272c0 26.5-21.5 48-48 48L48 512c-26.5 0-48-21.5-48-48L0 192zm64 80l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm128 0l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0zM64 400l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0zm112 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16z"/></svg>
          <div class="absolute -bottom-1.5 -right-1.5 text-lg">
            {{ getEmojiForType(action.actionSubType) }}
          </div>
        </figure>
      </router-link>
      <router-link class="grid break-words text-sm sm:text-base" :to="`/e/${action.actionTakenOnContent.short}`">
        {{ action.actionTakenOnContent.name }}
        <ul class="flex gap-x-4 text-xs dark:text-gray-400">
          <li>{{ getEventType(action.actionTakenOnContent.type) }}</li>
          <li :class="action.actionTakenOnContent.category === 'submit-event' ? 'text-gold-700 dark:text-gold-500' : ''">{{ getEventCategory(action.actionTakenOnContent.category) }}</li>
          <li>
            <span :class="action.actionTakenOnContent.status === 'cancelled' ? 'line-through' : ''">{{ format(parseISO(new Date(action.actionTakenOnContent.actualStartDateTime).toISOString()), "LLL d, yyyy") }}</span> <span v-if="action.actionTakenOnContent.status === 'cancelled'"> (Cancelled)</span>
          </li>
        </ul>
      </router-link>
    </li>
    <li v-for="action in actionsArray" v-if="item.contentType === 'event' && item.activitySubType === 'rsvp' || item.activitySubType === 'considering'">
      <router-link :to="`/e/${action.actionTakenOnContent.short}`">
        <figure class="flex relative flex-none items-center justify-center text-sm h-10 w-10 sm:h-12 sm:w-12 text-gray-800 dark:text-gray-500 bg-white dark:bg-submit-900 font-bold rounded-md" :style="action.actionThumbnail ? `background-image: url('${action.actionThumbnail}')` : ''">
          <svg class="dark:fill-gray-400 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M128 0c17.7 0 32 14.3 32 32l0 32 128 0 0-32c0-17.7 14.3-32 32-32s32 14.3 32 32l0 32 48 0c26.5 0 48 21.5 48 48l0 48L0 160l0-48C0 85.5 21.5 64 48 64l48 0 0-32c0-17.7 14.3-32 32-32zM0 192l448 0 0 272c0 26.5-21.5 48-48 48L48 512c-26.5 0-48-21.5-48-48L0 192zm64 80l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm128 0l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0zM64 400l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0zm112 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16z"/></svg>
        </figure>
      </router-link>
      <router-link class="grid break-words text-sm sm:text-base" :to="`/e/${action.actionTakenOnContent.short}`">
        {{ action.actionTakenOnContent.name }}
        <ul class="flex gap-x-4 text-xs dark:text-gray-400">
          <li>{{ getEventType(action.actionTakenOnContent.type) }}</li>
          <li :class="action.actionTakenOnContent.category === 'submit-event' ? 'text-gold-700 dark:text-gold-500' : ''">{{ getEventCategory(action.actionTakenOnContent.category) }}</li>
          <li>
            <span :class="action.actionTakenOnContent.status === 'cancelled' ? 'line-through' : ''">{{ format(parseISO(new Date(action.actionTakenOnContent.actualStartDateTime).toISOString()), "LLL d, yyyy") }}</span> <span v-if="action.actionTakenOnContent.status === 'cancelled'"> (Cancelled)</span>
          </li>
        </ul>
      </router-link>
    </li>
    <li v-for="action in actionsArray" v-if="item.contentType === 'post' && item.activitySubType === 'comment'">
      <router-link :to="`/groups/${action.actionTakenOnContent.groupSlug}/${action.actionTakenOnContent.short}${action.actionRefId ? '#'+action.actionRefId : ''}`">
        <figure class="flex flex-none items-center justify-center text-sm h-10 w-10 sm:h-12 sm:w-12 text-gray-800 dark:text-gray-500 bg-white dark:bg-submit-900 font-bold" :style="action.actionThumbnail ? `background-image: url('${action.actionThumbnail}')` : ''">
          <svg class="dark:fill-gray-400 h-4 w-4" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M0 64C0 28.7 28.7 0 64 0H448c35.3 0 64 28.7 64 64V352c0 35.3-28.7 64-64 64H309.3L185.6 508.8c-4.8 3.6-11.3 4.2-16.8 1.5s-8.8-8.2-8.8-14.3V416H64c-35.3 0-64-28.7-64-64V64zm152 80c-13.3 0-24 10.7-24 24s10.7 24 24 24H360c13.3 0 24-10.7 24-24s-10.7-24-24-24H152zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24H264c13.3 0 24-10.7 24-24s-10.7-24-24-24H152z"/></svg>
        </figure>
      </router-link>
      <router-link class="grid break-words text-sm sm:text-base" :to="`/groups/${action.actionTakenOnContent.groupSlug}/${action.actionTakenOnContent.short}${action.actionRefId ? '#'+action.actionRefId : ''}`">
        <div :title="action.actionContent" class="relative whitespace-nowrap overflow-hidden overflow-ellipsis mr-4">{{ action.actionContent }}</div>
        <ul class="flex gap-x-4 text-xs dark:text-gray-400">
          <li>{{ action.actionTakenOnContent.title }} in {{ action.actionTakenOnContent.groupName }}</li>
        </ul>
      </router-link>
    </li>
    <li v-for="action in actionsArray" v-if="item.contentType === 'event' && (item.activitySubType === 'comment')">
      <router-link :to="`/e/${action.actionTakenOnContent.short}`">
        <figure class="flex relative flex-none items-center justify-center text-sm h-10 w-10 sm:h-12 sm:w-12 text-gray-800 dark:text-gray-500 bg-white dark:bg-submit-900 font-bold rounded-md" :style="action.actionThumbnail ? `background-image: url('${action.actionThumbnail}')` : ''">
          <svg class="dark:fill-gray-400 h-4 w-4" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M0 64C0 28.7 28.7 0 64 0H448c35.3 0 64 28.7 64 64V352c0 35.3-28.7 64-64 64H309.3L185.6 508.8c-4.8 3.6-11.3 4.2-16.8 1.5s-8.8-8.2-8.8-14.3V416H64c-35.3 0-64-28.7-64-64V64zm152 80c-13.3 0-24 10.7-24 24s10.7 24 24 24H360c13.3 0 24-10.7 24-24s-10.7-24-24-24H152zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24H264c13.3 0 24-10.7 24-24s-10.7-24-24-24H152z"/></svg>
        </figure>
      </router-link>
      <router-link class="grid break-words text-sm sm:text-base" :to="`/e/${action.actionTakenOnContent.short}`">
        <div :title="action.actionContent" class="relative text-sm sm:text-base whitespace-nowrap overflow-hidden overflow-ellipsis mr-4">{{ action.actionContent }}</div>
        <ul class="flex gap-x-4 text-xs dark:text-gray-400">
          <li>{{ action.actionTakenOnContent.name }}</li>
          <li>
            <span :class="action.actionTakenOnContent.status === 'cancelled' ? 'line-through' : ''">{{ format(parseISO(new Date(action.actionTakenOnContent.actualStartDateTime).toISOString()), "LLL d, yyyy") }}</span> <span v-if="action.actionTakenOnContent.status === 'cancelled'"> (Cancelled)</span>
          </li>
        </ul>
      </router-link>
    </li>
    <li v-for="action in actionsArray" v-if="item.contentType === 'announcement' && item.activitySubType === 'reaction'">
      <router-link :to="`/announcements/${action.actionTakenOnContent.slug}`">
        <figure class="flex flex-none items-center justify-center text-sm h-10 w-10 sm:h-12 sm:w-12 text-gray-800 dark:text-gray-500 bg-white dark:bg-submit-900 font-bold rounded-md" :style="action.actionThumbnail ? `background-image: url('${action.actionThumbnail}')` : ''">
          <svg class="dark:fill-gray-400 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path class="fa-secondary" opacity=".4" d="M192 128l0 32 0 32 0 96 0 32 0 32 8.7 0c67.9 0 133 27 181 75l43.6 43.6c9.2 9.2 22.9 11.9 34.9 6.9s19.8-16.6 19.8-29.6l0-147.6 0-120.7L480 32c0-12.9-7.8-24.6-19.8-29.6C456.3 .8 452.1 0 448 0c-8.3 0-16.5 3.3-22.6 9.4L381.7 53c-48 48-113.1 75-181 75c-2.9 0-5.8 0-8.7 0z"/><path class="fa-primary" d="M0 192c0-35.3 28.7-64 64-64l128 0 0 192 0 32 0 128c0 17.7-14.3 32-32 32l-64 0c-17.7 0-32-14.3-32-32l0-128c-35.3 0-64-28.7-64-64l0-96zm512 48c0 27.9-13.4 51.6-32 60.4l0-120.7c18.6 8.8 32 32.5 32 60.4z"/></svg>
        </figure>
      </router-link>
      <router-link class="break-words text-sm sm:text-base" :to="`/announcements/${action.actionTakenOnContent.slug}`">
        {{ action.actionTakenOnContent.title }}
        <ul class="flex gap-x-4 text-xs dark:text-gray-400">
          <li>{{ action.actionTakenOnContent.title }}</li>
          <li>{{ getReactionCount(action.actionTakenOnContent.reactionsCount) }} reaction{{ getReactionCount(action.actionTakenOnContent.reactionsCount) === 1 ? '' : 's' }}</li>
          <li>{{ action.actionTakenOnContent.commentCount }} comment{{ action.actionTakenOnContent.commentCount === 1 ? '' : 's' }}</li>
        </ul>
      </router-link>
    </li>
    <li v-for="action in actionsArray" v-if="item.contentType === 'announcement' && item.activitySubType === 'comment'">
      <router-link :to="`/announcements/${action.actionTakenOnContent.slug}${action.actionRefId ? '#'+action.actionRefId : ''}`">
        <figure class="flex flex-none items-center justify-center text-sm h-10 w-10 sm:h-12 sm:w-12 text-gray-800 dark:text-gray-500 bg-white dark:bg-submit-900 font-bold rounded-md" :style="action.actionThumbnail ? `background-image: url('${action.actionThumbnail}')` : ''">
          <svg class="dark:fill-gray-400 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path class="fa-secondary" opacity=".4" d="M192 128l0 32 0 32 0 96 0 32 0 32 8.7 0c67.9 0 133 27 181 75l43.6 43.6c9.2 9.2 22.9 11.9 34.9 6.9s19.8-16.6 19.8-29.6l0-147.6 0-120.7L480 32c0-12.9-7.8-24.6-19.8-29.6C456.3 .8 452.1 0 448 0c-8.3 0-16.5 3.3-22.6 9.4L381.7 53c-48 48-113.1 75-181 75c-2.9 0-5.8 0-8.7 0z"/><path class="fa-primary" d="M0 192c0-35.3 28.7-64 64-64l128 0 0 192 0 32 0 128c0 17.7-14.3 32-32 32l-64 0c-17.7 0-32-14.3-32-32l0-128c-35.3 0-64-28.7-64-64l0-96zm512 48c0 27.9-13.4 51.6-32 60.4l0-120.7c18.6 8.8 32 32.5 32 60.4z"/></svg>
        </figure>
      </router-link>
      <router-link class="grid" :to="`/announcements/${action.actionTakenOnContent.slug}${action.actionRefId ? '#'+action.actionRefId : ''}`">
        <div :title="action.actionContent" class="relative text-sm sm:text-base whitespace-nowrap overflow-hidden overflow-ellipsis mr-4">{{ action.actionContent }}</div>
        <ul class="flex gap-x-4 text-xs dark:text-gray-400">
          <li>{{ action.actionTakenOnContent.title }}</li>
          <li>{{ getReactionCount(action.actionTakenOnContent.reactionsCount) }} reaction{{ getReactionCount(action.actionTakenOnContent.reactionsCount) === 1 ? '' : 's' }}</li>
          <li>{{ action.actionTakenOnContent.commentCount }} comment{{ action.actionTakenOnContent.commentCount === 1 ? '' : 's' }}</li>
        </ul>
      </router-link>
    </li>
    <li
      class="hover:cursor-pointer"
      v-if="shouldLoadMoreActions"
      @click="loadMoreActions"
    >
      <div
        v-if="item.contentType === 'media' && item.activitySubType === 'reaction'"
        :class="[
          item.contentType === 'media' ? 'flex flex-none items-center justify-center' : '',
          'text-[8px] w-[3.5rem] h-[3.5rem] font-bold text-center dark:text-gray-400'
        ]"
      >
        SHOW<br/>MORE
      </div>
      <div class="dark:text-gray-500 text-sm" v-else>
        Show More
      </div>
    </li>
  </ul>
  <!-- Event -->
  <section v-if="item.activityType === 'event'">
    <router-link :to="`/e/${item.content.short}`" class="dark:text-gold-500 text-gold-700 text-xl font-semibold font-display">
      {{ item.content.name }}
    </router-link>
    <div class="text-sm" v-if="item.content.location && item.content.location.place_name_en">
      {{ adjustToUSA(item.content.location.place_name_en) }}
    </div>
    <ul class="grid gap-2 mt-2 text-xs dark:text-gray-300 text-gray-700">
      <li class="flex gap-2 items-center">
        <svg class="w-3.5 fill-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M512 384c35.3 0 64-28.7 64-64l0-160c0-35.3-28.7-64-64-64L394.5 96c-17 0-33.3-6.7-45.3-18.7L322.7 50.7c-12-12-28.3-18.7-45.3-18.7L160 32c-35.3 0-64 28.7-64 64l0 224c0 35.3 28.7 64 64 64l352 0zM48 120c0-13.3-10.7-24-24-24S0 106.7 0 120L0 344c0 75.1 60.9 136 136 136l320 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-320 0c-48.6 0-88-39.4-88-88l0-224z"/></svg>
        <span :class="item.content.category === 'submit-event' ? 'dark:text-gold-500 text-gold-700' : ''">{{ getEventCategory(item.content.category) }}</span>
      </li>
      <li class="flex gap-2 items-center">
        <svg class="w-3.5 fill-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M128 0c17.7 0 32 14.3 32 32l0 32 128 0 0-32c0-17.7 14.3-32 32-32s32 14.3 32 32l0 32 48 0c26.5 0 48 21.5 48 48l0 48L0 160l0-48C0 85.5 21.5 64 48 64l48 0 0-32c0-17.7 14.3-32 32-32zM0 192l448 0 0 272c0 26.5-21.5 48-48 48L48 512c-26.5 0-48-21.5-48-48L0 192zm64 80l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm128 0l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0zM64 400l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0zm112 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16z"/></svg>
        <div v-if="!item.content.multiDay">
          <span :class="item.content.status === 'cancelled' ? 'line-through' : ''">{{ format(parseISO(new Date(item.content.actualStartDateTime).toISOString()), "LLL d, yyyy") }}</span> <span v-if="item.content.status === 'cancelled'"> (Cancelled)</span>
        </div>
        <div v-if="item.content.multiDay">
          <span :class="item.content.status === 'cancelled' ? 'line-through' : ''">{{ format(parseISO(new Date(item.content.actualStartDateTime).toISOString()), "LLL d, yyyy") }}</span>
          <span :class="item.content.status === 'cancelled' ? 'line-through' : ''"> to </span>
          <span :class="item.content.status === 'cancelled' ? 'line-through' : ''">{{ format(parseISO(new Date(item.content.actualEndDateTime).toISOString()), "LLL d, yyyy") }}</span> <span v-if="item.content.status === 'cancelled'"> (Cancelled)</span>
        </div>
      </li>
      <li class="flex gap-2 items-center">
        <svg class="w-3.5 fill-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M64 64C28.7 64 0 92.7 0 128l0 64c0 8.8 7.4 15.7 15.7 18.6C34.5 217.1 48 235 48 256s-13.5 38.9-32.3 45.4C7.4 304.3 0 311.2 0 320l0 64c0 35.3 28.7 64 64 64l448 0c35.3 0 64-28.7 64-64l0-64c0-8.8-7.4-15.7-15.7-18.6C541.5 294.9 528 277 528 256s13.5-38.9 32.3-45.4c8.3-2.9 15.7-9.8 15.7-18.6l0-64c0-35.3-28.7-64-64-64L64 64zm64 112l0 160c0 8.8 7.2 16 16 16l288 0c8.8 0 16-7.2 16-16l0-160c0-8.8-7.2-16-16-16l-288 0c-8.8 0-16 7.2-16 16zM96 160c0-17.7 14.3-32 32-32l320 0c17.7 0 32 14.3 32 32l0 192c0 17.7-14.3 32-32 32l-320 0c-17.7 0-32-14.3-32-32l0-192z"/></svg>
        <div>{{ item.content.rsvpCount }} Attendee{{ item.content.rsvpCount === 1 ? '' : 's' }}</div>
      </li>
      <li class="flex gap-2 items-center" v-if="item.content.tags.length > 0">
        <svg class="w-3.5 fill-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M0 80L0 229.5c0 17 6.7 33.3 18.7 45.3l176 176c25 25 65.5 25 90.5 0L418.7 317.3c25-25 25-65.5 0-90.5l-176-176c-12-12-28.3-18.7-45.3-18.7L48 32C21.5 32 0 53.5 0 80zm112 32a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/></svg>
        <div>{{ item.content.tags.map((tag: any) => tag).join(", ") }}</div>
      </li>
    </ul>
  </section>
  <!-- Status Update: Media -->
  <figure
    class="Photo Status V3 flex justify-center items-center dark:bg-black/25 bg-neutral-200"
    v-if="item.activityType === 'status' && item.content.type === 'image'"
    :style="{
      aspectRatio: `${item.content.mediaWidth} / ${item.content.mediaHeight}`,
      height: `${item.content.mediaHeight}px`,
      maxHeight: `36rem`
    }"
  >
    <router-link :to="`/${item.author.username}/status/${item.content._id}`">
      <img loading="lazy" class="pointer-events-none" :width="item.content.mediaWidth" :height="item.content.mediaHeight" :src="item.content.mediaUrl" @error="" />
    </router-link>
  </figure>
  <!-- Status Update: Text -->
  <section
    class="CommonBody"
    v-if="item.activityType === 'status' && contentText !== '<p></p>' && !edit"
    v-html="processedContent"
    @click="handleMentionClick"
  >
  </section>
  <!-- Status Update: Poll -->
  <Poll :pollId="item.content.poll" v-if="item.activityType === 'status' && item.content.poll" />
  <!-- Writing -->
  <section class="Writing" v-if="item.activityType === 'writing'">
    <h2 class="text-xl font-semibold dark:text-gold-500 text-gold-700">
      <router-link :to="`/${item.author.username}/writing/${item.content.slug}`">{{ item.content.title }}</router-link>
    </h2>
    <p v-if="item.content.description">{{ item.content.description }}</p>
  </section>
  <!-- Announcement -->
  <section class="Announcement" v-if="item.activityType === 'announcement'">
    <router-link :to="`/announcements/${item.content.slug}`">
      <h2 class="text-xl font-semibold dark:text-gold-500 text-gold-700">{{ item.content.title }}</h2>
      <p v-if="item.content.summary">{{ item.content.summary }}</p>
    </router-link>
  </section>
  <!-- Post -->
  <section class="Post" v-if="item.activityType === 'post'">
    <h2 class="bg-gold-500/30 inline-block px-4 p-0.5 rounded-xl font-semibold">
      <router-link :to="`/groups/${item.content.groupSlug}/${item.content.short}`">{{ item.content.title }}</router-link>
    </h2>
    <p v-if="item.content.raw">{{ item.content.raw }}<span v-if="item.content.hasMore">&hellip;</span></p>
  </section>
  <!-- Media -->
  <figure
    v-if="item.activityType === 'media'"
    class="overflow-hidden"
    :class="{
      'Photo dark:bg-black/25 bg-neutral-200': item.activityType === 'media' && mediaArray.length <= 4,
      'Single': mediaArray.length === 1,
      'Double V3': mediaArray.length === 2,
      'Triple V3': mediaArray.length === 3,
      'Quad V3': mediaArray.length === 4,
      'Thumbs': mediaArray.length > 4
    }"
  >
    <div
      :class="{
        'grid-cols-1 flex justify-center h-full items-center w-full': mediaArray.length === 1,
        'grid-cols-2 grid': mediaArray.length === 2 || mediaArray.length === 3 || mediaArray.length === 4,
        'grid-rows-2 grid': mediaArray.length === 4,
      }"
      :style="{
        height: mediaArray.length === 1 ? `${mediaArray[0].height}px` : 'auto',
        aspectRatio: mediaArray.length === 1 ? `${mediaArray[0].width} / ${mediaArray[0].height}` : undefined,
        maxHeight: mediaArray.length === 1 ? `36rem` : undefined
      }"
      v-if="mediaArray.length <= 4"
    >
      <router-link
        v-for="(media, index) in mediaArray"
        :key="media._id"
        :to="`/${item.author.username}/media/${media._id}`"
        v-if="mediaArray.length <= 4"
        :class="{
          'row-span-2': mediaArray.length === 3 && index === 0
        }"
      >
        <img
          loading="lazy"
          class="pointer-events-none w-full h-full object-cover"
          :width="media.width"
          :height="media.height"
          :src="media.src"
          @error=""
        />
      </router-link>
    </div>

    <!-- For more than 4 images -->
    <ul class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 h-full contain-content" v-else>
      <li v-for="media in mediaArray" :key="media._id" class="h-32 max-h-32">
        <router-link :to="`/${item.author.username}/media/${media._id}`">
          <img
            class="aspect-[4/3] object-cover h-full max-h-32 rounded-lg pointer-events-none"
            loading="lazy"
            :width="media.width"
            :height="media.height"
            :src="media.src"
            @error=""
          />
        </router-link>
      </li>
      <li
        v-if="shouldLoadMore"
        class="col-span-2 md:col-span-3 lg:col-span-4 text-center text-sm bg-neutral-125 dark:bg-submit-950 py-2 hover:cursor-pointer rounded-md"
        @click="loadMore"
      >
        Load More
      </li>
    </ul>
  </figure>
</template>
