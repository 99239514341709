<script setup lang="ts">
import { usePlatformStore } from "@/stores/Platform"
import { useUserStore } from "@/stores/User"
import { useRoute } from "vue-router"
import { storeToRefs } from "pinia"
import { computed } from "vue"
import {
  Menu,
  MenuButton,
  MenuItems,
  MenuItem
} from "@headlessui/vue"

const route = useRoute()
const Platform = usePlatformStore()
const UserStore = useUserStore()
const { flags, roles } = storeToRefs(UserStore)

const canBlock = computed(() => {
  return flags.value.includes("blocking")
})
const canReport = computed(() => {
  return flags.value.includes("reporting")
})
const isAdmin = computed(() => {
  return roles.value.includes("admin")
})

interface Props {
  profileData: any,
  profileContext: string,
}

const routes = [
  { display: "Profile", name: "Profile.Profile", viewbox: `0 0 448 512`, path: `M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z` },
  { display: "Activity", name: "Profile.Activity", viewbox: `0 0 640 512`, path: `M319.1 0c14.8-.4 27.9 9.3 31.8 23.6l74 271.2 17.7-35.4c10.8-21.7 33-35.4 57.2-35.4H608c17.7 0 32 14.3 32 32s-14.3 32-32 32H499.8L444.6 398.3c-5.9 11.9-18.6 18.8-31.8 17.5s-24.2-10.6-27.7-23.4L323.7 167.3 255.3 486.7c-3.1 14.4-15.5 24.8-30.2 25.3s-27.8-9.1-31.8-23.2L135.9 288H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H135.9c28.6 0 53.7 18.9 61.5 46.4L219.6 348 288.7 25.3C291.8 10.9 304.4 .4 319.1 0z` },
  { display: "Media", name: "Profile.Media", route: "Profile.Media.Photos", viewbox: `0 0 640 512`, path: `M256 0H576c35.3 0 64 28.7 64 64V288c0 35.3-28.7 64-64 64H256c-35.3 0-64-28.7-64-64V64c0-35.3 28.7-64 64-64zM476 106.7C471.5 100 464 96 456 96s-15.5 4-20 10.7l-56 84L362.7 169c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h80 48H552c8.9 0 17-4.9 21.2-12.7s3.7-17.3-1.2-24.6l-96-144zM336 96a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zM64 128h96V384v32c0 17.7 14.3 32 32 32H320c17.7 0 32-14.3 32-32V384H512v64c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V192c0-35.3 28.7-64 64-64zm8 64c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16H88c8.8 0 16-7.2 16-16V208c0-8.8-7.2-16-16-16H72zm0 104c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16H88c8.8 0 16-7.2 16-16V312c0-8.8-7.2-16-16-16H72zm0 104c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16H88c8.8 0 16-7.2 16-16V416c0-8.8-7.2-16-16-16H72zm336 16v16c0 8.8 7.2 16 16 16h16c8.8 0 16-7.2 16-16V416c0-8.8-7.2-16-16-16H424c-8.8 0-16 7.2-16 16z` },
  { display: "Writing", name: "Profile.Writing", route: "Profile.Writing.Writings", viewbox: `0 0 512 512`, path: `M368.4 18.3c21.9-21.9 57.3-21.9 79.2 0l46.1 46.1c21.9 21.9 21.9 57.3 0 79.2l-71 71L412.2 225 371.6 374.1c-4.8 17.8-18.1 32-35.5 38.1L72 505c-18.5 6.5-39.1 1.8-52.9-12S.5 458.5 7 440L99.8 175.9c6.1-17.4 20.3-30.6 38.1-35.5L287 99.8l10.4-10.4 71-71zM296.9 146.8L150.5 186.7c-2.5 .7-4.6 2.6-5.4 5.1L62.5 426.9 164.7 324.7c-3-6.3-4.7-13.3-4.7-20.7c0-26.5 21.5-48 48-48s48 21.5 48 48s-21.5 48-48 48c-7.4 0-14.4-1.7-20.7-4.7L85.1 449.5l235.1-82.6c2.5-.9 4.4-2.9 5.1-5.4l39.9-146.4-68.3-68.3z` },
  { display: "Events", name: "Profile.Events", route: "Profile.Events.Attending", viewbox: `0 0 448 512`, path: `M128 0c17.7 0 32 14.3 32 32l0 32 128 0 0-32c0-17.7 14.3-32 32-32s32 14.3 32 32l0 32 48 0c26.5 0 48 21.5 48 48l0 48L0 160l0-48C0 85.5 21.5 64 48 64l48 0 0-32c0-17.7 14.3-32 32-32zM0 192l448 0 0 272c0 26.5-21.5 48-48 48L48 512c-26.5 0-48-21.5-48-48L0 192zm64 80l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm128 0l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0zM64 400l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0zm112 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16z` }
]

const orgRoutes = [
  { display: "Profile", name: "Profile.Profile", viewbox: `0 0 448 512`, path: `M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z` }
]

const followRoutes = [
  { display: "Followers", name: "Profile.Followers", viewbox: `0 0 576 512`, path: `M80 96A48 48 0 1 0 80 0a48 48 0 1 0 0 96zM64 128c-35.3 0-64 28.7-64 64V320c0 17.7 14.3 32 32 32c9.8 0 18.5-4.4 24.4-11.2L80.4 485.3c2.9 17.4 19.4 29.2 36.8 26.3s29.2-19.4 26.3-36.8L123.1 352h15.7l30 134.9c3.8 17.3 20.9 28.1 38.2 24.3s28.1-20.9 24.3-38.2l-57.3-258 116.3 53.8c.5 .3 1.1 .5 1.6 .7c8.6 3.6 18 3.1 25.9-.7c3.4-1.6 6.6-3.9 9.3-6.7c3.1-3.2 5.5-7 7.1-11.4c.1-.3 .2-.7 .3-1l2.5-7.5c5.7-17.1 18.3-30.9 34.7-38.2l8-3.5c1-.4 1.9-.8 2.9-1.2l-16.9 63.5c-5.6 21.1-.1 43.6 14.7 59.7l70.7 77.1 22 88.1c4.3 17.1 21.7 27.6 38.8 23.3s27.6-21.7 23.3-38.8l-23-92.1c-1.9-7.8-5.8-14.9-11.2-20.8l-49.5-54 19.3-65.5 9.6 23c4.4 10.6 12.5 19.3 22.8 24.5l26.7 13.3c15.8 7.9 35 1.5 42.9-14.3s1.5-35-14.3-42.9L537 232.7l-15.3-36.8C504.5 154.8 464.3 128 419.7 128c-22.8 0-45.3 4.8-66.1 14l-8 3.5c-24.4 10.9-44.6 29-58.1 51.6L157.3 136.9C144.7 131 130.9 128 117 128H64zM464 96a48 48 0 1 0 0-96 48 48 0 1 0 0 96zM349.7 335.6l-25 62.4-59.4 59.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L372.3 441c4.6-4.6 8.2-10.1 10.6-16.1l14.5-36.2-40.7-44.4c-2.5-2.7-4.8-5.6-7-8.6z` },
  { display: "Following", name: "Profile.Following", viewbox: `0 0 512 512`, path: `M128 128A64 64 0 1 0 128 0a64 64 0 1 0 0 128zm-22.4 32c-41.6 0-76.3 31.9-79.7 73.4l-4.1 49.3c-2.5 29.8 15.7 56.1 42.2 65.6V464c0 26.5 21.5 48 48 48h32c26.5 0 48-21.5 48-48V348.3c26.5-9.5 44.7-35.8 42.2-65.6l-4.1-49.3C226.7 191.9 192 160 150.4 160H105.6zM384 128A64 64 0 1 0 384 0a64 64 0 1 0 0 128zm-15.7 32c-28.6 0-53.7 18.9-61.5 46.4L267.7 343.2c-5.8 20.4 9.5 40.8 30.8 40.8H320v80c0 26.5 21.5 48 48 48h32c26.5 0 48-21.5 48-48V384h21.6c21.3 0 36.6-20.3 30.8-40.8L461.3 206.4c-7.8-27.5-33-46.4-61.5-46.4H368.3z` }
]

const orgFollowRoutes = [
  { display: "Followers", name: "Profile.Followers", viewbox: `0 0 576 512`, path: `M80 96A48 48 0 1 0 80 0a48 48 0 1 0 0 96zM64 128c-35.3 0-64 28.7-64 64V320c0 17.7 14.3 32 32 32c9.8 0 18.5-4.4 24.4-11.2L80.4 485.3c2.9 17.4 19.4 29.2 36.8 26.3s29.2-19.4 26.3-36.8L123.1 352h15.7l30 134.9c3.8 17.3 20.9 28.1 38.2 24.3s28.1-20.9 24.3-38.2l-57.3-258 116.3 53.8c.5 .3 1.1 .5 1.6 .7c8.6 3.6 18 3.1 25.9-.7c3.4-1.6 6.6-3.9 9.3-6.7c3.1-3.2 5.5-7 7.1-11.4c.1-.3 .2-.7 .3-1l2.5-7.5c5.7-17.1 18.3-30.9 34.7-38.2l8-3.5c1-.4 1.9-.8 2.9-1.2l-16.9 63.5c-5.6 21.1-.1 43.6 14.7 59.7l70.7 77.1 22 88.1c4.3 17.1 21.7 27.6 38.8 23.3s27.6-21.7 23.3-38.8l-23-92.1c-1.9-7.8-5.8-14.9-11.2-20.8l-49.5-54 19.3-65.5 9.6 23c4.4 10.6 12.5 19.3 22.8 24.5l26.7 13.3c15.8 7.9 35 1.5 42.9-14.3s1.5-35-14.3-42.9L537 232.7l-15.3-36.8C504.5 154.8 464.3 128 419.7 128c-22.8 0-45.3 4.8-66.1 14l-8 3.5c-24.4 10.9-44.6 29-58.1 51.6L157.3 136.9C144.7 131 130.9 128 117 128H64zM464 96a48 48 0 1 0 0-96 48 48 0 1 0 0 96zM349.7 335.6l-25 62.4-59.4 59.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L372.3 441c4.6-4.6 8.2-10.1 10.6-16.1l14.5-36.2-40.7-44.4c-2.5-2.7-4.8-5.6-7-8.6z` }
]

const achievementsRoutes = [
  { display: "Achievements", name: "Profile.Achievements", viewbox: `0 0 576 512`, path: `M400 0L176 0c-26.5 0-48.1 21.8-47.1 48.2c.2 5.3 .4 10.6 .7 15.8L24 64C10.7 64 0 74.7 0 88c0 92.6 33.5 157 78.5 200.7c44.3 43.1 98.3 64.8 138.1 75.8c23.4 6.5 39.4 26 39.4 45.6c0 20.9-17 37.9-37.9 37.9L192 448c-17.7 0-32 14.3-32 32s14.3 32 32 32l192 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-26.1 0C337 448 320 431 320 410.1c0-19.6 15.9-39.2 39.4-45.6c39.9-11 93.9-32.7 138.2-75.8C542.5 245 576 180.6 576 88c0-13.3-10.7-24-24-24L446.4 64c.3-5.2 .5-10.4 .7-15.8C448.1 21.8 426.5 0 400 0zM48.9 112l84.4 0c9.1 90.1 29.2 150.3 51.9 190.6c-24.9-11-50.8-26.5-73.2-48.3c-32-31.1-58-76-63-142.3zM464.1 254.3c-22.4 21.8-48.3 37.3-73.2 48.3c22.7-40.3 42.8-100.5 51.9-190.6l84.4 0c-5.1 66.3-31.1 111.2-63 142.3z` }
]

const props = defineProps<Props>()

const activeRoute = computed(() => {
  return route.name as string
})

const routesToUse = computed(() => {
  if (activeRoute.value === "Profile.Followers") {
    if (props.profileContext === "organization") {
      return orgFollowRoutes as any
    } else {
      return followRoutes as any
    }
  } else if (activeRoute.value === "Profile.Following") {
    if (props.profileContext === "organization") {
      return orgFollowRoutes as any
    } else {
      return followRoutes
    }
  } else if (activeRoute.value === "Profile.Achievements") {
    return achievementsRoutes as any
  } else {
    if (props.profileContext === "organization") {
      return orgRoutes as any
    } else {
      return routes as any
    }
  }
})

function openReportDialog() {
  Platform.toggleReportOpen()
  Platform.setReportData({
    userId: props.profileData._id,
    contentId: props.profileData._id,
    contentType: props.profileContext === 'user' ? 'user' : 'organization',
  })
}

function openBlockDialog() {
  Platform.toggleBlockOpen()
  Platform.setBlockData({
    userId: props.profileData._id,
    username: props.profileContext === 'user' ? props.profileData.username : props.profileData.handle
  })
}

function openModerationDialog() {
  Platform.setModerationData({
    context: "user",
    userId: props.profileData._id,
    username: props.profileContext === 'user' ? props.profileData.username : props.profileData.handle,
    pfp: props.profileData.pfp ? props.profileData.pfp : false,
    deactivation: props.profileData.deactivationQueue ? props.profileData.deactivationQueue : false
  })
  Platform.toggleModerationOpen()
}
</script>

<template>
  <section class="px-2 mt-8 md:mt-10">
    <nav class="container mx-auto">
      <ul class="flex w-full text-xs xs:text-sm text-gray-500 dark:text-gray-400">
        <li
          v-if="activeRoute === 'Profile.Followers' || activeRoute === 'Profile.Following' || activeRoute === 'Profile.Achievements'"
          class="px-2 xs:px-4 pb-2 border-b border-b-gray-700"
        >
          <router-link
            :to="{ name: 'Profile.Profile', params: { username: profileData.username } }"
            class="flex items-center gap-2 group"
          >
            <svg class="h-2.5 xs:h-3 fill-gray-500 dark:fill-gray-400 dark:group-hover:fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"/></svg>
            <span class="dark:group-hover:text-white">Return to Profile</span>
          </router-link>
        </li>
        <li
          v-for="route in routesToUse"
          :key="route.name"
          class="flex flex-1 xs:flex-initial justify-center xs:justify-start px-2 xs:px-4 pb-2 border-b border-b-gray-700"
          :class="activeRoute.includes(route.name) ? 'border-b-gold-700 dark:border-b-gold-500' : ''"
        >
          <router-link
            :to="{ name: route.route ? route.route : route.name, params: { username: profileData.username } }"
            class="flex items-center gap-2 group"
          >
            <svg
              class="h-4 xs:h-3 fill-gray-500 dark:fill-gray-400 dark:group-hover:fill-white"
              :class="activeRoute.includes(route.name) ? 'fill-black dark:fill-white' : ''"
              xmlns="http://www.w3.org/2000/svg"
              :viewBox="route.viewbox"
            >
              <path :d="route.path" />
            </svg>
            <span class="hidden sm:inline dark:group-hover:text-white" :class="activeRoute.includes(route.name) ? 'dark:text-white font-semibold' : ''">{{ route.display }}</span>
          </router-link>
        </li>
        <li class="hidden xs:block flex-grow px-2 pb-2 border-b border-b-gray-700"></li>
        <Menu as="li" class="relative border-b border-b-gray-700 group" v-if="!profileData.self">
          <MenuButton class="px-2 xs:px-4 pb-2 pt-0.5 xs:pt-1">
            <svg class="h-4 xs:h-3.5 fill-gray-500 dark:fill-gray-400 dark:group-hover:fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512"><path d="M56 472a56 56 0 1 1 0-112 56 56 0 1 1 0 112zm0-160a56 56 0 1 1 0-112 56 56 0 1 1 0 112zM0 96a56 56 0 1 1 112 0A56 56 0 1 1 0 96z"/></svg>
          </MenuButton>
          <transition
            enter-active-class="transition duration-100 ease-out"
            enter-from-class="transform scale-95 opacity-0"
            enter-to-class="transform scale-100 opacity-100"
            leave-active-class="transition duration-75 ease-in"
            leave-from-class="transform scale-100 opacity-100"
            leave-to-class="transform scale-95 opacity-0"
          >
            <MenuItems class="origin-top-right divide-y divide-gray-600/50 absolute top-9 right-0 w-48 rounded-md shadow-lg bg-submit-600 ring-1 ring-transparent ring-opacity-5 focus:outline-none z-50">
              <div class="p-1">
                <MenuItem v-slot="{ active }" :disabled="canBlock ? false : true">
                  <button
                    type="button"
                    :class="[
                      active ? 'hover:bg-gold-500/10' : '',
                      'flex items-center rounded-md w-full px-4 py-2 text-sm text-gray-300'
                    ]"
                    @click="openBlockDialog"
                  >
                  <svg class="w-3 mr-3 fill-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M367.2 412.5L99.5 144.8C77.1 176.1 64 214.5 64 256c0 106 86 192 192 192c41.5 0 79.9-13.1 111.2-35.5zm45.3-45.3C434.9 335.9 448 297.5 448 256c0-106-86-192-192-192c-41.5 0-79.9 13.1-111.2 35.5L412.5 367.2zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z"/></svg>
                    Block
                  </button>
                </MenuItem>
                <MenuItem v-slot="{ active }" :disabled="canReport ? false : true">
                  <button
                    type="button"
                    :class="[
                      active ? 'bg-yellow-400/10' : '',
                      'flex items-center rounded-md w-full px-4 py-2 text-sm text-gray-300'
                    ]"
                    @click="openReportDialog"
                  >
                  <svg class="w-3 mr-3 fill-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/></svg>
                    Report
                  </button>
                </MenuItem>
                <MenuItem v-slot="{ active }" v-if="!profileData.self && isAdmin">
                  <button
                    type="button"
                    :class="[
                      active ? 'bg-yellow-400/10' : '',
                      'flex items-center rounded-md w-full px-4 py-2 text-sm text-gray-300'
                    ]"
                    @click="openModerationDialog"
                  >
                  <svg class="w-3 mr-3 fill-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M230.1 .8l152 40c8.6 2.3 15.3 9.1 17.3 17.8s-1 17.8-7.8 23.6L368 102.5v8.4c0 10.7-5.3 20.8-15.1 25.2c-24.1 10.8-68.6 24-128.9 24s-104.8-13.2-128.9-24C85.3 131.7 80 121.6 80 110.9v-8.4L56.4 82.2c-6.8-5.8-9.8-14.9-7.8-23.6s8.7-15.6 17.3-17.8l152-40c4-1.1 8.2-1.1 12.2 0zM227 48.6c-1.9-.8-4-.8-5.9 0L189 61.4c-3 1.2-5 4.2-5 7.4c0 17.2 7 46.1 36.9 58.6c2 .8 4.2 .8 6.2 0C257 114.9 264 86 264 68.8c0-3.3-2-6.2-5-7.4L227 48.6zM98.1 168.8c39.1 15 81.5 23.2 125.9 23.2s86.8-8.2 125.9-23.2c1.4 7.5 2.1 15.3 2.1 23.2c0 70.7-57.3 128-128 128s-128-57.3-128-128c0-7.9 .7-15.7 2.1-23.2zM134.4 352c2.8 0 5.5 .9 7.7 2.6l72.3 54.2c5.7 4.3 13.5 4.3 19.2 0l72.3-54.2c2.2-1.7 4.9-2.6 7.7-2.6C387.8 352 448 412.2 448 486.4c0 14.1-11.5 25.6-25.6 25.6H25.6C11.5 512 0 500.5 0 486.4C0 412.2 60.2 352 134.4 352zM352 408c-3.5 0-6.5 2.2-7.6 5.5L339 430.2l-17.4 0c-3.5 0-6.6 2.2-7.6 5.5s.1 6.9 2.9 9L331 454.8l-5.4 16.6c-1.1 3.3 .1 6.9 2.9 9s6.6 2 9.4 0L352 470.1l14.1 10.3c2.8 2 6.6 2.1 9.4 0s4-5.7 2.9-9L373 454.8l14.1-10.2c2.8-2 4-5.7 2.9-9s-4.2-5.5-7.6-5.5l-17.4 0-5.4-16.6c-1.1-3.3-4.1-5.5-7.6-5.5z"/></svg>
                    Moderation
                  </button>
                </MenuItem>
              </div>
            </MenuItems>
          </transition>
        </Menu>
        <li v-if="profileData.self && activeRoute === 'Profile.Profile'" class="hidden xs:block px-4 pb-2 border-b border-b-gray-700 whitespace-nowrap group">
          <router-link class="flex items-center gap-2 dark:group-hover:text-white" to="/settings/profile/details">
            <svg class="block h-3 fill-gray-500 dark:fill-gray-400 dark:group-hover:fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H322.8c-3.1-8.8-3.7-18.4-1.4-27.8l15-60.1c2.8-11.3 8.6-21.5 16.8-29.7l40.3-40.3c-32.1-31-75.7-50.1-123.9-50.1H178.3zm435.5-68.3c-15.6-15.6-40.9-15.6-56.6 0l-29.4 29.4 71 71 29.4-29.4c15.6-15.6 15.6-40.9 0-56.6l-14.4-14.4zM375.9 417c-4.1 4.1-7 9.2-8.4 14.9l-15 60.1c-1.4 5.5 .2 11.2 4.2 15.2s9.7 5.6 15.2 4.2l60.1-15c5.6-1.4 10.8-4.3 14.9-8.4L576.1 358.7l-71-71L375.9 417z"/></svg>
            <span>Edit</span>
          </router-link>
        </li>
      </ul>
    </nav>
  </section>
</template>
