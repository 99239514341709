<script setup lang="ts">
import { ref, inject, onMounted, onUnmounted, nextTick } from "vue"
import AddOrRemoveToCircle from "@/components/actions/AddOrRemoveToCircle.vue"
import Follow from "@/components/actions/Follow.vue"
import { useUserStore } from "@/stores/User"
import { storeToRefs } from "pinia"
import API from "@/api/api"
import {
  Menu,
  MenuButton,
  MenuItems,
  MenuItem
} from "@headlessui/vue"

interface Props {
  type: string
}

const UserStore = useUserStore()
const { id } = storeToRefs(UserStore)

const props = defineProps<Props>()
const profileData: any = inject("profileData")
const handleAddFollower = inject("handleAddFollower") as () => void
const handleRemoveFollower = inject("handleRemoveFollower") as () => void
const handleUnfollow = inject("handleUnfollow") as () => void

const initialLoadComplete = ref(false)
const working = ref(false)
const error = ref(false)

const followList = ref<any[]>([])
const pageInfo = ref<any>({
  endCusor: undefined
})
const context = ref<any>({
  selfList: false,
  listType: ''
})

onMounted(async () => {
  await fetchFollowList()
  initialLoadComplete.value = true
  await nextTick()
  if (followList.value.length > 0) {
    let target = document.querySelector('#loadMore')
    observer.observe(target as any)
  }
})

onUnmounted(() => {
  if (followList.value.length > 0) {
    observer.disconnect()
  }
})

async function fetchFollowList() {
  if (working.value) return
  working.value = true

  let path = `/users/${profileData.value._id}/${props.type}`

  if (pageInfo.value.endCursor) {
    path += `?${new URLSearchParams({ cursor: pageInfo.value.endCursor })}`
  }

  try {
    const response = await API().get(path)
    followList.value.push(...response.data.data)
    pageInfo.value = response.data.pageInfo
    context.value = response.data.context
    working.value = false
  } catch (err) {
    console.error(err)
    error.value = true
  }
}

function shouldDisplayFollowsMe(followsMe: boolean) {
  if (context.value.selfList && context.value.listType === 'followers') {
    return false
  }
  if (followsMe) {
    return true
  }
  return false
}

function shouldDisplayFollowMenu() {
  if (context.value.selfList && context.value.listType === 'followers') {
    return true
  }
  return false
}

async function removeFollower(follower: string) {
  if (working.value) return
  working.value = true

  try {
    await API().get(`/users/${follower}/remove`)
    removeFromList(follower)
    handleUnfollow()
  } catch (err) {
    console.error(err)
  } finally {
    working.value = false
  }
}

function removeFromList(user: string) {
  followList.value = followList.value.filter((follow) => follow.user !== user)
}

function followed(user: string) {
  if (profileData.value.self) {
    handleAddFollower()
  }
  return
}

function unfollow(user: string) {
  if (profileData.value.self) {
    handleRemoveFollower()
    if (props.type === 'following') {
      removeFromList(user)
    }
  }
  return
}

let options = {
  root: null, // observing changes with respect to the document's viewport
  rootMargin: '0px', // margin around the root
  threshold: 0.1 // triggers the observer callback when 10% of the target is visible
}

let observer = new IntersectionObserver((entries, observer) => {
  entries.forEach(entry => {
    // when our #loadMoreIndicator element comes into view
    if (entry.isIntersecting) {
      // load more content
      if (
        initialLoadComplete.value &&
        !working.value &&
        pageInfo.value.hasNextPage
      ) {
        fetchFollowList()
      }
    }
  })
}, options)
</script>

<template>
  <div class="container mx-auto Page">
    <ul class="grid grid-cols-1 md:grid-cols-2 gap-6 col-span-12">
      <li class="flex gap-y-2 gap-x-8 xl:gap-x-4 flex-col sm:flex-row flex-wrap sm:justify-between sm:items-center p-4 bg-white dark:bg-submit-925 rounded-md" v-for="follow in followList" :key="follow._id" v-if="initialLoadComplete && followList.length > 0">
        <div class="flex items-center">
          <a :href="`/${follow.followType === 'user' ? follow.username : follow.handle}`">
            <figure class="flex flex-none w-12 h-12 bg-neutral-200 dark:bg-submit-500 rounded-full mr-2 items-center text-2xl justify-center font-semibold bg-cover capitalize" :style="follow.pfp ? `background-image: url('${follow.pfp}')` : ''">
              {{ follow.pfp ? '' : follow.firstLetter }}
            </figure>
          </a>
          <div>
            <h1 class="flex text-base lg:text-lg font-display font-semibold sm:leading-6 break-all gap-x-2 items-baseline">
              <a :href="`/${follow.followType === 'user' ? follow.username : follow.handle}`">{{ follow.followType === 'user' ? follow.username : follow.name }}</a>
              <span title="Verified" class="inline" v-if="follow.verified && follow.followType === 'user'">
                <svg class="h-3.5 fill-[#00C2FF]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 0c36.8 0 68.8 20.7 84.9 51.1C373.8 41 411 49 437 75s34 63.3 23.9 96.1C491.3 187.2 512 219.2 512 256s-20.7 68.8-51.1 84.9C471 373.8 463 411 437 437s-63.3 34-96.1 23.9C324.8 491.3 292.8 512 256 512s-68.8-20.7-84.9-51.1C138.2 471 101 463 75 437s-34-63.3-23.9-96.1C20.7 324.8 0 292.8 0 256s20.7-68.8 51.1-84.9C41 138.2 49 101 75 75s63.3-34 96.1-23.9C187.2 20.7 219.2 0 256 0zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z"/></svg>
              </span>
              <span title="Backer" class="inline" v-if="follow.backer && follow.followType === 'user'">
                <svg
                  :class="[
                    follow.backerBadge === 'default' ? 'dark:fill-gold-500 fill-gold-700' : '',
                    follow.backerBadge === 'green' ? 'fill-green-500' : '',
                    follow.backerBadge === 'pink' ? 'fill-pink-450' : '',
                    follow.backerBadge === 'blue' ? 'fill-blue-500' : '',
                    follow.backerBadge === 'purple' ? 'fill-purple-500' : '',
                    follow.backerBadge === 'orange' ? 'fill-orange-500' : '',
                    follow.backerBadge === 'red' ? 'fill-red-500' : '',
                    'h-3.5'
                  ]"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path d="M160 0c17.7 0 32 14.3 32 32V191.9l0 .1v96c0 35.3 28.7 64 64 64c19.1 0 36.3-8.4 48-21.7c11.7 13.3 28.9 21.7 48 21.7c35.3 0 64-28.7 64-64V224.1l0-.1V96c0-17.7 14.3-32 32-32s32 14.3 32 32V336c0 97.2-78.8 176-176 176H284.8c-59.6 0-116.9-22.9-160-64L12.4 341c-16-15.2-16.6-40.6-1.4-56.6s40.6-16.6 56.6-1.4l60.5 57.6c0-1.5-.1-3.1-.1-4.6V32c0-17.7 14.3-32 32-32zm64 192c0-17.7 14.3-32 32-32s32 14.3 32 32v96c0 17.7-14.3 32-32 32s-32-14.3-32-32V192zm128 0c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32s-32-14.3-32-32V224c0-17.7 14.3-32 32-32z"/>
                </svg>
              </span>
              <span title="Submit Staff" class="inline" v-if="follow.staff && follow.followType === 'user'">
                <svg class="dark:fill-gold-500 fill-gold-700" height="14" viewBox="0 0 118 143" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.82812 110.309L90.5094 22.6273C94.4146 18.7221 100.746 18.7221 104.652 22.6273L114.551 32.5268L26.8698 120.208C22.9645 124.113 16.6329 124.113 12.7276 120.208L2.82812 110.309Z" />
                  <path d="M7.07107 74.9533C3.16583 71.048 3.16582 64.7164 7.07107 60.8111L60.8112 7.07102C64.7164 3.16577 71.0481 3.16577 74.9533 7.07101L77.7817 9.89944C81.687 13.8047 81.687 20.1363 77.7817 24.0416L16.9706 84.8528L7.07107 74.9533Z" />
                  <path d="M39.5979 132.936C35.6927 129.031 35.6927 122.699 39.5979 118.794L100.409 57.9827L110.309 67.8822C114.214 71.7875 114.214 78.1191 110.309 82.0244L56.5685 135.764C52.6632 139.67 46.3316 139.67 42.4264 135.764L39.5979 132.936Z" />
                </svg>
              </span>
              <span title="Submit Volunteer" class="inline" v-if="follow.volunteer && follow.followType === 'user'">
                <svg class="dark:fill-gold-500 fill-gold-700" height="14" viewBox="0 0 118 143" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.82812 110.309L90.5094 22.6273C94.4146 18.7221 100.746 18.7221 104.652 22.6273L114.551 32.5268L26.8698 120.208C22.9645 124.113 16.6329 124.113 12.7276 120.208L2.82812 110.309Z" />
                  <path d="M7.07107 74.9533C3.16583 71.048 3.16582 64.7164 7.07107 60.8111L60.8112 7.07102C64.7164 3.16577 71.0481 3.16577 74.9533 7.07101L77.7817 9.89944C81.687 13.8047 81.687 20.1363 77.7817 24.0416L16.9706 84.8528L7.07107 74.9533Z" />
                  <path d="M39.5979 132.936C35.6927 129.031 35.6927 122.699 39.5979 118.794L100.409 57.9827L110.309 67.8822C114.214 71.7875 114.214 78.1191 110.309 82.0244L56.5685 135.764C52.6632 139.67 46.3316 139.67 42.4264 135.764L39.5979 132.936Z" />
                </svg>
              </span>
              <span v-if="shouldDisplayFollowsMe(follow.followType === 'user' ? follow.followsMe : false)" class="inline-flex self-center text-[0.625rem] leading-none bg-neutral-100 dark:bg-submit-500 py-1 px-2 rounded-sm font-semibold">Follows You</span>
              <span v-if="follow.followType === 'organization'" class="inline-flex self-center text-[0.625rem] leading-none bg-neutral-100 dark:bg-submit-500 py-1 px-2 rounded-sm font-semibold">Org</span>
            </h1>
            <h2 class="text-xs">{{ follow.followType === 'user' ? follow.meta.metaLabel : follow.type === 'socialnetwork' ? 'Social Network' : 'Unknown Type' }}</h2>
          </div>
        </div>
        <div class="flex relative gap-2 text-sm">
          <AddOrRemoveToCircle :user="follow.user" v-if="follow.user !== id && follow.followType === 'user'" />
          <Follow @follow="followed" @unfollow="unfollow(follow.user)" :user="follow" />
          <Menu v-if="shouldDisplayFollowMenu()">
            <MenuButton class="rounded-md py-1.5 px-3 bg-neutral-100 dark:bg-submit-600 sm:flex-initial">
              <svg class="h-4 dark:fill-gray-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512"><path d="M56 472a56 56 0 1 1 0-112 56 56 0 1 1 0 112zm0-160a56 56 0 1 1 0-112 56 56 0 1 1 0 112zM0 96a56 56 0 1 1 112 0A56 56 0 1 1 0 96z"/></svg>
            </MenuButton>
            <transition
              enter-active-class="transition duration-100 ease-out"
              enter-from-class="transform scale-95 opacity-0"
              enter-to-class="transform scale-100 opacity-100"
              leave-active-class="transition duration-75 ease-in"
              leave-from-class="transform scale-100 opacity-100"
              leave-to-class="transform scale-95 opacity-0"
            >
              <MenuItems class="origin-top-right divide-y divide-indigo-350 absolute top-10 right-0 w-48 rounded-md shadow-lg bg-neutral-125 dark:bg-submit-950 ring-1 ring-transparent ring-opacity-5 focus:outline-none z-50">
                <div class="p-1">
                  <MenuItem v-slot="{ active }">
                    <button
                      @click="removeFollower(follow.user)"
                      type="button"
                      :class="[
                        active ? 'bg-yellow-400/10' : '',
                        'flex items-center rounded-md w-full px-4 py-2 text-sm dark:text-neutral-300'
                      ]"
                    >
                    <svg class="w-3 mr-3 fill-gold-700 dark:fill-gold-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L353.3 251.6C407.9 237 448 187.2 448 128C448 57.3 390.7 0 320 0C250.2 0 193.5 55.8 192 125.2L38.8 5.1zM290.5 202.4l-35.6-27.9C245.5 161.4 240 145.3 240 128c0-44.2 35.8-80 80-80s80 35.8 80 80s-35.8 80-80 80c-10.4 0-20.4-2-29.5-5.6zM264.3 304.3C170.5 309.4 96 387.2 96 482.3c0 16.4 13.3 29.7 29.7 29.7H514.3c3.9 0 7.6-.7 11-2.1L467.1 464H145.3c8.9-63.3 63.3-112 129-112h50.6l-60.6-47.7z"/></svg>
                      Remove Follower
                    </button>
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </li>
      <li class="md:col-span-2" v-if="initialLoadComplete && followList.length === 0">
        <span v-if="context.listType === 'following'">{{ context.selfList ? 'You are' : profileData.username + ' is' }} not following anyone currently.</span>
        <span v-if="context.listType === 'followers'">{{ context.selfList ? 'You do' : profileData.username + ' does' }} not have any followers currently.</span>
      </li>
      <li class="md:col-span-2" v-if="!initialLoadComplete">
        <span>Loading...</span>
      </li>
      <li class="md:col-span-2" v-if="error">
        <span>{{ profileData.username }} has privacy settings that prevent you from seeing their follow lists.</span>
      </li>
    </ul>
    <div v-if="followList.length > 0" id="loadMore"></div>
  </div>
</template>
