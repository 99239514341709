<script setup lang="ts">
import LoadIndicator from "@/components/misc/LoadIndicator.vue"
import { computed, ref, watch, provide, onMounted, onBeforeUnmount, nextTick } from "vue"
import ItemHeader from "@/components/feed/items/ItemHeader.vue"
import ItemBody from "@/components/feed/items/ItemBody.vue"
import ItemFooter from "@/components/feed/items/ItemFooter.vue"

interface FeedItem {
  _id?: string
  feedItem?: string
  activity?: string
  activityType?: string
  type?: string
  author?: {
    _id: string
    username: string
    pfp?: string
    firstLetter?: string
  }
  content?: any
  actions?: any[]
  hydrated?: boolean
}

const props = defineProps<{
  item: FeedItem
  hydrated: boolean
}>()

const emit = defineEmits<{
  measured: [height: number]
}>()

const resizeObserver = ref<ResizeObserver | null>(null)
const mutationObserver = ref<MutationObserver | null>(null)
const imageListeners = ref(new Set<HTMLImageElement>())

const itemRef = ref<HTMLElement | null>(null)
const isReady = ref(false)

// Add a flag to track updates
const updateCount = ref(0)
const lastUpdateTime = ref(Date.now())

const imageTrackingTimeout = ref<NodeJS.Timeout | null>(null)
const measurePending = ref(false)
const resizeDebounceTimeout = ref<NodeJS.Timeout | null>(null)

const hasInitiallyMeasured = ref(false)
const lastMeasuredHeight = ref(0)

// Modify measureAndEmit to track rapid updates
function measureAndEmit() {
  if (!itemRef.value) return

  // Always measure immediately if we're hydrated for the first time
  if (props.hydrated && !hasInitiallyMeasured.value) {
    immediatelyMeasure()
    hasInitiallyMeasured.value = true
    return
  }

  const now = Date.now()
  if (now - lastUpdateTime.value < 50) {
    updateCount.value++
    if (updateCount.value > 5) {
      console.warn('Potential recursive updates detected')
      return
    }
  } else {
    updateCount.value = 0
  }
  lastUpdateTime.value = now

  // Ensure measurements are taken in the next frame
  if (!measurePending.value) {
    measurePending.value = true
    requestAnimationFrame(() => {
      immediatelyMeasure()
      measurePending.value = false
    })
  }
}

function immediatelyMeasure() {
  if (!itemRef.value) return

  const rect = itemRef.value.getBoundingClientRect()
  const height = Math.ceil(rect.height) // Round up to avoid subpixel differences

  // Only emit if height has actually changed
  const lastHeight = lastMeasuredHeight.value
  if (lastHeight !== height) {
    lastMeasuredHeight.value = height
    emit('measured', height)
  }
}

function setupImageLoadTracking() {
  if (!itemRef.value || !props.hydrated) return

  cleanupImageListeners()

  if (imageTrackingTimeout.value) {
    clearTimeout(imageTrackingTimeout.value)
  }

  // For critical components with images, measure immediately
  if (props.item.activityType === 'media' ||
      (props.item.activityType === 'status' && props.item.content?.type === 'image')) {
    trackImagesImmediately()
  } else {
    // For less critical components, use the debounced approach
    imageTrackingTimeout.value = setTimeout(() => {
      trackImagesImmediately()
      imageTrackingTimeout.value = null
    }, 50) // Reduce debounce time to ensure more responsive measurements
  }
}

function trackImagesImmediately() {
  if (!itemRef.value) return

  const uniqueImages = new Set<HTMLImageElement>()

  // More specific query selectors based on content type
  if (mediaState.value.isMedia) {
    itemRef.value.querySelectorAll('figure img, .Thumbs img').forEach(img =>
      uniqueImages.add(img as HTMLImageElement)
    )
  } else if (props.item.activityType === 'status' && props.item.content?.type === 'image') {
    itemRef.value.querySelectorAll('.Photo.Status img').forEach(img =>
      uniqueImages.add(img as HTMLImageElement)
    )
  } else {
    itemRef.value.querySelectorAll('img').forEach(img =>
      uniqueImages.add(img as HTMLImageElement)
    )
  }

  // Add load handlers to all images
  uniqueImages.forEach(img => {
    if (!imageListeners.value.has(img)) {
      const loadHandler = () => {
        // Measure immediately after image loads
        requestAnimationFrame(immediatelyMeasure)
      }

      img.addEventListener('load', loadHandler)
      img.addEventListener('error', loadHandler)
      imageListeners.value.add(img)

      // If image is already loaded, measure now
      if (img.complete) {
        loadHandler()
      }
    }
  })
}

watch(() => props.hydrated, async (isHydrated) => {
  if (!isHydrated) return

  await nextTick()
  if (mediaState.value.isMedia) {
    await nextTick()
  }

  if (!itemRef.value) return

  // Initial measurement
  measureAndEmit()

  // Setup ResizeObserver for size changes
  resizeObserver.value = new ResizeObserver(() => {
    if (!resizeDebounceTimeout.value) {
      resizeDebounceTimeout.value = setTimeout(() => {
        if (itemRef.value) {
          if (mediaState.value.isMedia) {
            requestAnimationFrame(measureAndEmit)
          } else {
            measureAndEmit()
          }
        }
        resizeDebounceTimeout.value = null
      }, 50) // Debounce resize events
    }
  })
  resizeObserver.value.observe(itemRef.value)

  // Setup MutationObserver with debouncing
  let mutationDebounceTimeout: NodeJS.Timeout | null = null

  mutationObserver.value = new MutationObserver((mutations) => {
    const shouldMeasure = mutations.some(mutation => {
      return mutation.type === 'childList' ||
             mutation.type === 'attributes' &&
             ['style', 'class'].includes(mutation.attributeName || '')
    })

    if (shouldMeasure && !mutationDebounceTimeout) {
      mutationDebounceTimeout = setTimeout(() => {
        setupImageLoadTracking()
        mutationDebounceTimeout = null
      }, 100) // Debounce mutation events
    }
  })

  mutationObserver.value.observe(itemRef.value, {
    childList: true,
    subtree: true,
    attributes: true,
    attributeFilter: ['style', 'class']
  })

  // For thumbnail grids, ensure we track all images
  if (props.item?.activityType === 'media' && mediaArray.value.length > 4) {
    await nextTick() // Wait for thumbnail grid to render
    setupImageLoadTracking()
  }
}, { immediate: true })

const mediaArray = computed(() => {
  if (!props.item) return []

  // Handle both hydrated and unhydrated media items
  const isMedia = props.item.activityType === "media" || props.item.type === "media"
  const content = props.item.content || []

  if (isMedia && Array.isArray(content)) {
    return [...content]
      .reverse()
      .slice(0, 8)
  }
  return []
})
provide("mediaArray", mediaArray)

const mediaState = computed(() => ({
  isMedia: props.item?.activityType === "media" || props.item?.type === "media",
  isHydrated: props.hydrated,
  contentLength: Array.isArray(props.item?.content) ? props.item.content.length : 0
}))

const sameActionAuthor = computed(() => {
  const actions = props.item?.actions
  if (!props.item?.hydrated || !actions?.length) return false
  if (actions[0]?.actionTakenOnType === 'announcement') return false

  const firstAuthorId = actions[0]?.actionTakenOnAuthor?._id
  if (!firstAuthorId) return false

  return actions.every((action: any) => action?.actionTakenOnAuthor?._id === firstAuthorId)
})
provide("sameActionAuthor", sameActionAuthor)

const isAction = computed(() => {
  if (!props.item) return false
  return props.hydrated
    ? props.item.activityType === "action"
    : props.item.type === "action"
})

function cleanupImageListeners() {
  imageListeners.value.forEach(img => {
    img.removeEventListener('load', measureAndEmit)
    img.removeEventListener('error', measureAndEmit)
  })
  imageListeners.value.clear()
}

onMounted(() => {
  if (!itemRef.value) return

  const resizeObserver = new ResizeObserver((entries) => {
    // Only emit measurements once initial hydration is complete
    if (!isReady.value) return

    for (const entry of entries) {
      const height = entry.borderBoxSize[0]?.blockSize ||
                    entry.target.getBoundingClientRect().height
      emit('measured', height)
    }
  })

  resizeObserver.observe(itemRef.value)

  onBeforeUnmount(() => {
    resizeObserver.disconnect()
  })
})

// Cleanup on component unmount
onBeforeUnmount(() => {
  // Clear any pending timeouts
  if (imageTrackingTimeout.value) {
    clearTimeout(imageTrackingTimeout.value)
    imageTrackingTimeout.value = null
  }

  if (resizeDebounceTimeout.value) {
    clearTimeout(resizeDebounceTimeout.value)
    resizeDebounceTimeout.value = null
  }

  // Clean up ResizeObserver
  if (resizeObserver.value) {
    resizeObserver.value.disconnect()
    resizeObserver.value = null
  }

  // Clean up MutationObserver
  if (mutationObserver.value) {
    mutationObserver.value.disconnect()
    mutationObserver.value = null
  }

  // Clean up image listeners
  imageListeners.value.forEach(img => {
    img.removeEventListener('load', measureAndEmit)
    img.removeEventListener('error', measureAndEmit)
  })
  imageListeners.value.clear()
})
</script>

<template>
  <li
    ref="itemRef"
    :class="[
      item.activityType === 'action' ? 'Action first:pt-0' : 'dark:bg-submit-900 bg-white',
      'FeedItem'
    ]"
    :data-item-id="item.feedItem || item._id"
    :data-activity-id="item.activity"
    :data-hydrated="hydrated ? 'true' : 'false'"
    :data-action="isAction ? 'true' : 'false'"
    v-if="hydrated"
  >
    <router-link
      class="PFP w-10 h-10"
      :class="item.activityType === 'action' ? 'dark:bg-submit-900 bg-white' : 'dark:bg-submit-950 bg-gray-200'"
      :to="`/${item.author.username}`"
      :style="item.author.pfp ? `background-image: url('${item.author.pfp}')` : ''"
      v-if="item.activityType !== 'announcement' && item.author"
    >
      <span class="flex items-center justify-center w-full h-full text-2xl font-semibold text-gray-800 dark:text-gray-500" v-if="!item.author.pfp">{{ item.author.firstLetter }}</span>
    </router-link>
    <div v-else
      class="PFP w-10 h-10 dark:bg-submit-950 bg-gray-200 flex items-center justify-center"
    >
      <svg class="dark:fill-gold-500 fill-gold-700 h-6 w-6 origin-center" width="24" height="28" viewBox="-20 -20 158 183" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.82812 110.309L90.5094 22.6273C94.4146 18.7221 100.746 18.7221 104.652 22.6273L114.551 32.5268L26.8698 120.208C22.9645 124.113 16.6329 124.113 12.7276 120.208L2.82812 110.309Z"></path>
        <path d="M7.07107 74.9533C3.16583 71.048 3.16582 64.7164 7.07107 60.8111L60.8112 7.07102C64.7164 3.16577 71.0481 3.16577 74.9533 7.07101L77.7817 9.89944C81.687 13.8047 81.687 20.1363 77.7817 24.0416L16.9706 84.8528L7.07107 74.9533Z"></path>
        <path d="M39.5979 132.936C35.6927 129.031 35.6927 122.699 39.5979 118.794L100.409 57.9827L110.309 67.8822C114.214 71.7875 114.214 78.1191 110.309 82.0244L56.5685 135.764C52.6632 139.67 46.3316 139.67 42.4264 135.764L39.5979 132.936Z"></path>
      </svg>
    </div>
    <ItemHeader :item="item" v-if="hydrated" />
    <ItemBody :item="item" v-if="hydrated" />
    <ItemFooter :item="item" v-if="hydrated" />
  </li>
  <li
    ref="itemRef"
    class="FeedItem dark:bg-submit-900 bg-white"
    :data-item-id="item.feedItem || item._id"
    :data-activity-id="item.activity"
    :data-hydrated="hydrated ? 'true' : 'false'"
    :data-action="isAction ? 'true' : 'false'"
    v-else
  >
    <div class="col-span-2 flex justify-center items-center">
      <LoadIndicator />
    </div>
  </li>
</template>

<style scoped>
li[data-action="true"] + li[data-action="true"] {
  @apply border-t dark:border-submit-500 rounded-none pt-6;
}
</style>
